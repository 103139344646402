/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import { ErrorMessage, Field, Formik, Form } from "formik";
import { RenderValidationError } from "@components/errors/validation";
import { DatePicker, message, notification } from 'antd';
import Modal from "@elements/modal/modal";
import * as pigApi from '@api/pig';
import { DATE_FORMAT } from '@constants/config';
import { IDetailPig } from '@type/pig';
import { EEventTypeName } from '@type/event-type';
import { UtilCollections } from '@utils/collections';
import ScaleLoader from "react-spinners/ScaleLoader";
import openNotification, { NotificationType } from '../common/notification/Notification';

interface IModalEdit {
    modalRenderProps: {
        isOpen: boolean,
        toggleModal: any
    },
    editData: IDetailPig,
    onUpdateList: () => void
}

const PigIdentity: FC<IModalEdit> = ({ modalRenderProps, editData, onUpdateList }: IModalEdit) => {
    const { isOpen, toggleModal } = modalRenderProps;
    const [detail, setDetail] = React.useState<any>();
    const [isValidToEdit, setIsValidToEdit] = React.useState<boolean>(true)

    React.useEffect(() => {
        (async () => {
            const detailPig = await pigApi.detail(editData.id) as any;

            for (let i = 0; i < detailPig.events.length; i++) {
                switch (detailPig.events[i].name) {
                    case EEventTypeName.PIG_IN:
                        detailPig.dateIn = detailPig.events[i].endDate;
                        break;

                    case EEventTypeName.CHANGE_PEN_PREDICTION:
                        detailPig.changePenPrediction = detailPig.events[i].endDate;
                        break;

                    case EEventTypeName.PIG_OUT_PREDICTION:
                        detailPig.pigOutPrediction = detailPig.events[i].endDate;
                        break;
                }
            }

            setDetail(detailPig);
            setIsValidToEdit(UtilCollections.calculateBetweenTwoDate(detailPig.dateIn, UtilCollections.getCurrentDate()) < 7);
        })()
    }, []);

    const doSubmit = async (values: any, actions: any): Promise<void> => {
        const { dateOfBirth, changePenPrediction, pigOutPrediction, dateIn, origin } = values;

        pigApi.updatePig(editData.id, { dateOfBirth, changePenPrediction, pigOutPrediction, dateIn, origin }).then(res => {
            openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Cập nhật thành công.`);
            onUpdateList();
            toggleModal(false)();
        }).catch(err => {
            notification.error({
                message: 'Đã xảy ra lỗi',
            } as any);
        });
    };

    return (
        <Modal title={'Cập nhật'} toggleModal={toggleModal} isShowModal={isOpen}>

            {detail ? (
                <Formik
                    initialValues={detail}
                    onSubmit={doSubmit}
                >
                    {
                        ({
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form className='log-form'>
                                <div className="log-field">
                                    <label htmlFor="email" className="log-label">ID</label>
                                    <div className="control">
                                        <Field
                                            name="id"
                                            className={`log-input`}
                                            type="text"
                                            id="id"
                                            placeholder={editData.id}
                                            disabled={true}
                                        />

                                    </div>
                                </div>

                                <div className="log-field">
                                    <label htmlFor="pen" className="log-label">
                                        Chuồng nuôi
                                    </label>
                                    <div className="control has-icon-right">
                                        <Field
                                            name="pen"
                                            className={`log-input`}
                                            type={'text'}
                                            id="pen"
                                            value={detail?.pen?.name}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="log-field">
                                    <label htmlFor="origin" className="log-label">
                                        Nguồn gốc
                                    </label>
                                    <div className="control has-icon-right">
                                        <Field
                                            name="origin"
                                            className={`log-input`}
                                            type={'text'}
                                            id="origin"
                                            placeholder={'Nhập nguồn gốc của con lợn (nếu có)'}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="origin" />
                                    </div>
                                </div>

                                {isValidToEdit && (
                                    <>
                                        {/* <div className="log-field">
                                            <label htmlFor="dateOfBirth" className="log-label">
                                                Ngày sinh
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="dateOfBirth"
                                                    className={`log-input`}
                                                    id="dateOfBirth"
                                                    placeholder={detail.dateOfBirth}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("dateOfBirth", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="dateOfBirth" />
                                            </div>
                                        </div> */}

                                        <div className="log-field">
                                            <label htmlFor="dateIn" className="log-label">
                                                Ngày nhập chuồng
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="dateIn"
                                                    className={`log-input`}
                                                    id="dateIn"
                                                    placeholder={detail.createdDate}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("dateIn", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="dateIn" />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* <div className="log-field">
                                    <label htmlFor="changePenPrediction" className="log-label">
                                        Ngày chuyển chuồng dự kiến
                                    </label>
                                    <div className="control has-icon-right">
                                        <DatePicker
                                            name="changePenPrediction"
                                            className={`log-input`}
                                            id="changePenPrediction"
                                            placeholder={detail.changePenPrediction}
                                            onChange={(date, dateString) =>
                                                setFieldValue("changePenPrediction", dateString)
                                            }
                                            format={DATE_FORMAT}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="changePenPrediction" />
                                    </div>
                                </div>

                                <div className="log-field">
                                    <label htmlFor="pigOutPrediction" className="log-label">
                                        Ngày xuất chuồng dự kiến
                                    </label>
                                    <div className="control has-icon-right">
                                        <DatePicker
                                            name="pigOutPrediction"
                                            className={`log-input`}
                                            id="pigOutPrediction"
                                            placeholder={detail.pigOutPrediction}
                                            onChange={(date, dateString) =>
                                                setFieldValue("pigOutPrediction", dateString)
                                            }
                                            format={DATE_FORMAT}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="pigOutPrediction" />
                                    </div>
                                </div> */}

                                <div className="log-field">
                                    <span />
                                    <button type="submit" className={`btn log-btn is-primary ${isSubmitting ? 'is-loading' : ''}`} disabled={isSubmitting}>
                                        Lưu
                                    </button>
                                </div>

                            </Form>
                        )
                    }
                </Formik>
            ) : (<ScaleLoader />)}
        </Modal>
    )
};

export default PigIdentity;
