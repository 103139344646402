export default {
    homePage: '/',
    dashboard: '/tong-quan',
    barn: '/quan-ly-chuong',
    pig: '/ca-the',
    pigUnusual: '/ca-the-bat-thuong',
    pigIdentity: '/ca-the/danh-tinh',
    penManagement: '/quan-ly-chuong',
    penEmpty: '/chuong-trong',
    penEmptyManagement: '/quan-ly-chuong-trong',
    eventManagement: '/quan-ly-su-kien',
    event: '/su-kien',
    introduction: '/gioi-thieu',
    products: '/san-pham',
    support: '/ho-tro',
    blog: '/blog',
    selectFarm: '/chon-trang-trai',
    farm: '/trang-trai',
    video: '/video',
    foodSupplier: '/nha-cung-cap',
    food: '/danh-muc-thuc-pham',
    foodOrder: '/quan-ly-nhap-thuc-pham',
    foodConsume: '/xuat-kho',
    sickPig: '/benh-lon',
    sickPigDiagnosis: '/chan-doan-benh-lon',
    tagPig: '/tag-pig',
    reportPig: '/bao-cao',
    reportSickPig: '/bao-cao-lon-benh',
    reportUnusualPig: '/bao-cao-lon-bat-thuong-hanh-vi',
    reportTempPig: '/bao-cao-lon-bat-thuong-nhiet-do',
    reportFoodConsume: '/bao-cao-thuc-an',
    reportFoodInventory: '/bao-cao-thuc-an-nhap-kho',
    reportFoodOrder: '/bao-cao-thuc-an-nhap',
    reportPharmacyConsume: '/bao-cao-thuoc-nhap-kho',
    reportPharmacyOrder: '/bao-cao-thuoc-xuat-kho',
    reportPharmacyInventory: '/bao-cao-vaccine-ton-kho',
    profile: '/profile',
    profile_userList: '/profile/quan-ly-nguoi-dung',
    profile_allFarmList: '/profile/quan-ly-tat-ca-trang-trai',
    profile_farmList: '/profile/quan-ly-trang-trai/:userID',
    profile_sickPigs: '/profile/quan-ly-benh-lon',
    profile_modal: '/profile/mo-hinh',
    vaccine_main: '/vaccine',
    vaccine_supplier: '/vaccine-hang-san-xuat',
    vaccine_plan: '/vaccine-ke-hoach',
    vaccine_list: '/vaccine-danh-sach',
    vaccine_order: '/vaccine-nhap',
    vaccine_consume: '/vaccine-xuat',
    policy: '/chinh-sach',
    gateway_farms: '/moi-truong-chuong',
    gateway_pen: '/moi-truong-chuong/:penID',
};
