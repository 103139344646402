/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routesName from '@routes/route-names';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { RenderValidationError } from "@components/errors/validation";
import { Button, Input, Modal, Spin, message, notification } from 'antd';
import { Form as FormAntd } from 'antd';
import { apiUser } from '@api/user';
import userService from '@services/user';
import farmService from '@services/farm';
import { ILoginUser } from '@components/auth/auth.types';
import './auth.scss'
import eyeShow from '@assets/eyeShow.png';
import eyeHidden from '@assets/eyeHidden.png';

import * as Yup from 'yup';
import { useDispatchRoot } from '../../redux/store';
import axios from 'axios';

const initialLoginInput = {
    email: '',
    password: '',
    remember: true
}

const Login: FC = () => {
    const history = useHistory();

    const [isShowPass, setIsShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleForgotPass, setVisibleForgotPass] = useState(false);
    const dispatch = useDispatchRoot();
    const [loadingForgotPass, setLoadingForgotPass] = useState(false);
    const [emailPasswordChange, setEmailPasswordChange] = useState('');

    const doSubmit = async (values: any, actions: any): Promise<void> => {

        const req = {
            email: 'pigman_01@gmail.com',
            password: '12344321',
            remember: true,
            additionalProp1: {}
        }

        await axios.post('https://sit.api.pigman.com.vn/users/login', req)
            .then((res) => {
                console.log(res.data);
                if (res?.data?.statusCode === "WrongCredentials") {
                    notification.error({
                        message: 'Sai tên đăng nhập hoặc mật khẩu',
                    } as any);
                    return;
                }
                if (res?.data?.statusCode === "UserNotActivated") {
                    notification.error({
                        message: 'Tài khoản chưa được kích hoạt',
                    } as any);
                    return;
                }
                const user = res.data.data as ILoginUser;
                userService.set(user);
                console.log(user)
                if (user.role === 'ADMIN') {
                    farmService.setCurrentFarm(user.defaultFarm);
                    history.push(routesName.profile);
                }
                else {
                    if (user.defaultFarm) {
                        farmService.setCurrentFarm(user.defaultFarm);
                        history.push(routesName.dashboard);
                    } else {
                        history.push(routesName.selectFarm);
                    }
                }

            })
            .catch((err) => {
                notification.error({
                    message: err?.response?.data?.message,
                } as any);
            })

        // catch (err: any) {
        //     console.log(err);

        //     if (typeof err === 'string') {
        //         message.error(err);
        //     } else {
        //         message.error('Đã xảy ra lỗi')
        //     }

        //     if (err.status === 500) {
        //         message.error('Lỗi hệ thống!');
        //     }
        // }
    };
    const validateEmail = (_: any, value: string) => {
        if (!value) {
            return Promise.reject('Vui lòng nhập email');
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            return Promise.reject('Email không đúng định dạng');
        }
        return Promise.resolve();
    };

    const validatePassword = (_: any, value: string | any[]) => {
        if (!value) {
            return Promise.reject('Vui lòng nhập mật khẩu');
        }
        if (value.length < 6) {
            return Promise.reject('Mật khẩu phải từ 6 ký tự');
        }
        return Promise.resolve();
    };
    const schema = Yup.object().shape({
        email: Yup.string().email('Không đúng định dạng email').required('Trường này bắt buộc'),
        password: Yup.string().min(6, 'Tối thiểu 6 kí tự').required('Trường này bắt buộc'),
    });

    const onFinishForgotPass = (values: any) => {
        setLoadingForgotPass(true);
        const req = {
            email: values.EmailUserForgotPass,
            additionalProp1: {}
        }
        setEmailPasswordChange(values.EmailUserForgotPass);
        axios.post(`https://sit.api.pigman.com.vn/users/forgot-password`, req)
            .then(res => {
                setTimeout(() => {

                    console.log(res);
                    console.log(res.data);
                    notification.success({
                        message: 'Vui lòng kiểm tra email để lấy lại mật khẩu',
                    } as any);
                    setLoadingForgotPass(false);
                    setVisibleForgotPass(false);
                    setVisible(true);
                }, 1000);

            })
            .catch(err => {
                notification.error({
                    message: err?.response?.data?.message,
                } as any);
                setLoadingForgotPass(false);
                // message.error('Email không tồn tại');
            }
            )
    }

    const onFinish = (values: any) => {
        console.log('Success:', values);
        const req = {
            email: emailPasswordChange,
            password: values.newPassword,
            confirmPassword: values.confirmPassword,
            OTPCode: values.OTPCode,
            additionalProp1: {}
        }
        axios.post(`https://sit.api.pigman.com.vn/users/reset-password`, req)
            .then(res => {
                console.log(res);
                console.log(res.data);
                notification.success({
                    message: 'Đổi mật khẩu thành công',
                } as any);
                setVisible(false);
                setEmailPasswordChange('');
            })
            .catch(err => {
                console.log(err);
                notification.error({
                    message: 'Mã OTP không đúng',
                } as any);
            })
    }

    return (
        <div className='wrapper-form'>
            <Formik
                initialValues={initialLoginInput}
                onSubmit={doSubmit}
                validationSchema={schema}
            >
                {
                    ({
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form>
                            <div className="auth-field">
                                <div className="control has-icon-right">
                                    <Field
                                        name="email"
                                        className={`auth-input`}
                                        type={'text'}
                                        id="email"
                                        placeholder={'Tên tài khoản'}

                                    />
                                    <ErrorMessage component={RenderValidationError} name="email" />
                                </div>
                            </div>

                            <div className="auth-field">
                                <div className="control has-icon-right">
                                    <div>
                                        <Field
                                            name="password"
                                            className={`auth-input`}
                                            type={isShowPass ? 'text' : 'password'}
                                            id="password"
                                            placeholder={'Mật khẩu'}

                                        />
                                        <img style={{ position: 'absolute', marginTop: '-35px', right: '13%' }} onClick={() => setIsShowPass(!isShowPass)} id='auth-modal-eye' src={isShowPass ? eyeShow : eyeHidden} alt='eye' />
                                    </div>
                                    <ErrorMessage component={RenderValidationError} name="password" />
                                </div>
                            </div>

                            <div className="auth-field">
                                <div style={{ fontStyle: 'italic', textAlign: 'end', cursor: 'pointer' }} onClick={() => setVisibleForgotPass(true)}>Quên mật khẩu</div>
                                <span />
                                <button type="submit" className={`btn auth-btn is-primary ${isSubmitting ? 'is-loading' : ''}`} disabled={isSubmitting}>
                                    Đăng nhập
                                </button>
                            </div>

                        </Form>
                    )
                }
            </Formik>
            <Modal
                visible={visibleForgotPass}
                title="Quên mật khẩu"
                onCancel={() => setVisibleForgotPass(false)}
                footer={null}
                width={400}
            >
                <Spin spinning={loadingForgotPass} delay={500} tip="Đang thực hiện...">
                    <FormAntd
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinishForgotPass}
                        autoComplete="off"
                    >
                        <FormAntd.Item
                            label="Email"
                            name="EmailUserForgotPass"
                            rules={[
                                { validator: validateEmail }
                            ]}
                            className='form-item-email'
                        >
                            <Input placeholder='Nhập email' />
                        </FormAntd.Item>

                        <FormAntd.Item >
                            <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                                <Button >
                                    Quay lại
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Xác nhận
                                </Button>
                            </div>
                        </FormAntd.Item>
                    </FormAntd>
                </Spin>

            </Modal>
            <Modal
                visible={visible}
                title="Quên mật khẩu"
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <FormAntd
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <FormAntd.Item
                        label="Email"
                        name="EmailUser"
                    >
                        <Input placeholder='Nhập email' defaultValue={emailPasswordChange} disabled />
                    </FormAntd.Item>

                    <FormAntd.Item name="newPassword" label="Mật khẩu mới" rules={[
                        { validator: validatePassword }
                    ]}>
                        <Input.Password
                            placeholder="Nhập mật khẩu mới"
                        />
                    </FormAntd.Item>
                    <FormAntd.Item
                        name="confirmPassword"
                        label="Xác nhận mật khẩu mới"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Vui lòng nhập mật khẩu xác nhận' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu xác nhận không trùng với mật khẩu mới!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Nhập mật khẩu xác nhận' />
                    </FormAntd.Item>
                    <FormAntd.Item
                        label="Mã xác nhận"
                        name="OTPCode"
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                    >
                        <Input placeholder='Nhập mã xác nhận' />
                    </FormAntd.Item>

                    <FormAntd.Item>
                        <FormAntd.Item >
                            <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                                <Button >
                                    Quay lại
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Xác nhận
                                </Button>
                            </div>
                        </FormAntd.Item>

                    </FormAntd.Item>
                </FormAntd>
            </Modal>
        </div >
    );
};

export default Login;
