/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { IListPig, IListPigHeadCell, Order } from '@components/pigs/pig.types';

import clsx from 'clsx';
import { withStyles, createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IListPig) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const createInitialData = ({ id, barnId, weightType, species, healthStatus, dateIn }: IListPig): any => {
    return { id, barnId, weightType, species, healthStatus, dateIn };
};

export const initialDataList = [
    createInitialData({
        id: '140521.L01',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L02',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L03',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L04',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Bất thường',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L05',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L06',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L07',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L08',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L09',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L10',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
    createInitialData({
        id: '140521.L11',
        barnId: 'C1.121',
        weightType: 'Dưới 30 Kg',
        species: 'Lai F1 (Y x MC)',
        healthStatus: 'Khỏe mạnh',
        dateIn: '14/05/2021',
    }),
];

export const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = <Key extends keyof any>(
    order: Order,
    orderBy: Key,
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T,>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const headCells: IListPigHeadCell[] = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Pig ID' },
    { id: 'barnId', numeric: false, disablePadding: true, label: 'Tên chuồng' },
    { id: 'weightType', numeric: false, disablePadding: false, label: 'Loại lợn' },
    { id: 'healthStatus', numeric: false, disablePadding: false, label: 'Tình trạng' },
    { id: 'dateIn', numeric: false, disablePadding: false, label: 'Ngày bắt đầu theo dõi' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

export const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className={classes.checkbox}
                    />
                </StyledTableCell> */}
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={false}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

interface EnhancedTableToolbarProps {
    numSelected: number;
    page: number;
    totalPig: number;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, page, totalPig } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Đã chọn {numSelected} con
                </Typography>
            ) : (
                <Typography className={classes.title} id="tableTitle" component="div">
                    {totalPig} con {/*total number pigs goes here */}
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                // <Tooltip title="Filter list">
                //     <IconButton aria-label="filter list">
                //         <SearchIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            )}
        </Toolbar>
    );
};

export const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '24px',
        },
    }),
);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalDetailPig: {
            width: '65%'
        },
        modalContent: {
            backgroundColor: 'white',
            width: '70%',
            minHeight: '80%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        spinnerLoadingAPI: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%',
            overflowY: 'scroll'
        },
    }),
);

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#001cee18',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#001cee18',
            },
        },
    }),
)(TableRow);
