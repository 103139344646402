/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import PageHeader from '../common/page-header/page-header';
import farmService from '@services/farm';
import { apiPen } from '@api/pen';
import { IPenData } from '@type/pen';
import CImageLoading from './CImageLoading';
import StreamAPI from '../../api/camera/streaming.api';
import { Modal } from '@material-ui/core';
import ModalHeader from '../common/modal/modal-header';
import Button from '@material-ui/core/Button';
import PenEmptyCreat from '../farm/pen-empty/pen-empty-create';
import { apiStreamingVideo } from '@api/streaming-video'
import CamDetail from '../farm/pen/cam-detail';
import context, { Context } from '../context/context';
import { ScaleLoader } from 'react-spinners';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '90%',
        },
        videoList: {
            display: 'flex',
            // flexDirection: 'row',
            marginBottom: '10px',
            // justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        videoItem: {
            marginLeft: '10px',
            marginRight: '10px',
            width: '420px'
        },
        videoItemFullScreen: {
            marginLeft: '10px',
            marginRight: '10px'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '72%',
        },
        modalContentAlert: {
            width: '30%',
        },
        btnSave: {
            backgroundColor: '#001AEE',
            color: '#FFFFFF',
            fontSize: '15px',
            textTransform: 'none',

            paddingLeft: '31px',
            paddingRight: '31px',
            marginLeft: '70%',
            marginTop: '1%',
            '&:hover': {
                backgroundColor: "#001AEE",
                color: '#FFFFFF',
            },
        },
        btnDisconnected: {
            backgroundColor: 'gray',
            color: '#FFFFFF',
            fontSize: '15px',
            textTransform: 'none',
            paddingLeft: '31px',
            paddingRight: '31px',
            marginLeft: '70%',
            marginTop: '1%',
        },
        NoCam: {
            backgroundColor: '#6B7280',
            display: 'flex',
            height: '232.88px',
            padding: '3px',
            marginTop: '3px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        ContentNoCam: {
            color: '#FFFFFF',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontStyle: 'normal'
        },
        pensTitle: {
            fontSize: '20px',
            color: '#ABABAB',
            marginLeft: '10px',

        },
        penVideo: {
            width: '100%',
        },
        spinnerLoadingAPI: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
    }),
);

enum TabPaneKey {
    PenCreate = 'penCreate',
    CamSettings = 'camSettings',
    AddPig = 'addPig'
}

const Video: FC = () => {
    const classes = useStyles();
    const [pens, setPens] = useState<Array<IPenData | any>>([]);
    const [penEmpty, setPenEmpty] = useState<Array<IPenData | any>>([]);

    const currentFarm = farmService.getCurrentFarm();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [isFullScreenEmpty, setIsFullScreenEmpty] = useState<boolean>(false);

    const [openCU, setOpenCU] = useState(false);

    const [selectedPen, updateSelectedPen] = useState<IPenData | any>();

    const [lstStreamingRtcCamera, setLstStreamingRtcCamera] = useState<any>();

    const [lstPenEmptyCamera, setLstPenEmptyCamera] = useState<any>();

    const [isConnected, setIsConnected] = useState<boolean>(false);

    const [isAddPig, setIsAddPig] = useState<boolean>(false);
    const context = React.useContext(Context);
    const [loadingCamera, setLoadingCamera] = useState(false);
    useEffect(() => {
        console.log('is add pig change', isAddPig)
        getAllPens()
        getAllPenEmptys()
    }, [isAddPig, context.changeLocation.isChangeLocation])

    const onClickLoadingCamera = (penId: string) => {
        const tmp = pens.map((item: any) => {
            if (item.id === penId) {
                return {
                    ...item,
                    status: true
                }
            }
            return item
        })
        initRtcCamera(tmp);
    }

    const initRtcCamera = (pensItem: IPenData[] | any) => {
        setLstStreamingRtcCamera(
            <div className={classes.videoList}>
                {
                    pensItem && pensItem.map((pen: IPenData | any, index: any) => {
                        return (
                            <>
                                <div className={`cursor-pointer ${classes.videoItem}`} key={index}>
                                    <p>{pen?.name} - {pen?.cameras[0]?.camName}</p>

                                    {
                                        (pen.cameras.length == 0) && (
                                            <div className={classes.penVideo}>
                                                {pen.status === false ?
                                                    <div className={'pen-video ' + (pen.status ? 'status' : '')} >
                                                        <div className='pen-video-text'>
                                                            KHÔNG CÓ KẾT NỐI
                                                        </div>
                                                        <Button className='pen-video-button' onClick={() => {
                                                            // onClickLoadingCamera(pen.id);
                                                        }}>Thử lại</Button>
                                                    </div>
                                                    :
                                                    <div className='pen-video-loading'>
                                                        <div className={classes.spinnerLoadingAPI}>
                                                            <ScaleLoader color={"#001AEE"} loading={pen.status} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        (pen.cameras.length > 0) && (
                                            <CImageLoading
                                                src={StreamAPI.getStream(pen.id)}
                                                className="h-56"
                                                uuid={uuidv4()}
                                                isFullScreen={isFullScreen}
                                                setIsFullScreen={setIsFullScreen}
                                                pen={pen}
                                                updateSelectedPen={updateSelectedPen}
                                                // onClick={() =>
                                                //     handleShowExpandMenu(true, item)
                                                // }
                                                isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                                idCamera={pen.cameras[0]?.id}

                                            />
                                        )
                                    }
                                    {/* <CImageLoading
                                        src={StreamAPI.getStream(pen.id)}
                                        className="h-56"
                                        uuid={uuidv4()}
                                        isFullScreen={isFullScreen}
                                        setIsFullScreen={setIsFullScreen}
                                        pen={pen}
                                        updateSelectedPen={updateSelectedPen}
                                        // onClick={() =>
                                        //     handleShowExpandMenu(true, item)
                                        // }
                                        isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                        idCamera={pen.cameras[0]?.id}

                                    /> */}
                                </div>
                                {/*end*/}
                            </>
                        )
                    })
                }
            </div>
        )
    }

    const initRtcCameraEmpty = (pensItem: IPenData[] | any) => {
        console.log('initRtcCameraEmpty', pensItem);
        setLstPenEmptyCamera(
            <div className={classes.videoList}>
                {
                    pensItem && pensItem.map((pen: IPenData | any, index: any) => {
                        return (
                            <>
                                {/*video*/}

                                {(pen?.cameras.length > 0) ?
                                    <div className={`cursor-pointer ${classes.videoItem}`}>
                                        <p>{pen?.name} - {pen?.cameras[0].camName}</p>
                                        <CImageLoading
                                            src={StreamAPI.getStream(pen.id)}
                                            className="h-56"
                                            uuid={uuidv4()}
                                            isEmptyPen={true}
                                            isFullScreen={isFullScreen}
                                            setIsFullScreen={setIsFullScreenEmpty}
                                            pen={pen}
                                            updateSelectedPen={updateSelectedPen}
                                            // onClick={() =>
                                            //     handleShowExpandMenu(true, item)
                                            // }
                                            isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                            idCamera={pen.cameras[0]?.id}
                                        />
                                        <Button
                                            onClick={() => handleOpenCU(true, pen)}
                                            variant="contained"
                                            className={classes.btnSave}
                                        // disabled={ isConnected ? false : true}
                                        >
                                            Đánh số
                                        </Button>
                                    </div>
                                    : <div className={`cursor-pointer ${classes.videoItem}`}>
                                        <p>{pen?.name}</p>
                                        <div className={classes.NoCam}>
                                            <div className={classes.ContentNoCam}>
                                                CHUỒNG CHƯA CÓ CAMERA
                                            </div>
                                        </div>

                                    </div>
                                }
                                {/*end*/}
                            </>
                        )
                    })
                }
            </div>
        )
    }

    // useEffect(() => {
    //     getAllPens();
    // }, []);


    // useEffect(() => {
    //     getAllPenEmptys();
    // }, []);

    // useEffect(() => {
    //     // setLstStreamingRtcCamera(null)
    //     // setLstPenEmptyCamera(null)
    //     getAllPenEmptys();
    //     getAllPens();
    // }, [currentFarm])

    const [uuId, updateUuid] = useState<string>(uuidv4())

    useEffect(() => {
        console.log('uuid', uuId);

    }, [uuId])


    const terminateStreaming = () => {
        const payload = [uuId]
        console.log(lstPenEmptyCamera);

        pens.map(item => {
            apiStreamingVideo.terminateRTCStreaming(item.id, payload)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        })
        penEmpty.map(item => {
            apiStreamingVideo.terminateRTCStreaming(item.id, payload)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        })
    }

    // const handleOpenCU = (penSelected?: IPenData) => {
    //     setOpenCU(true);
    // };

    const handleCloseCU = () => {
        setIsFullScreen(false);
    };

    const handleCloseCUEmpty = () => {
        setIsFullScreenEmpty(false);
    };

    const getAllPens = () => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm.id,
            name: "",
            area: 0,
            weightTypeId: ""
        }

        apiPen.getAllPen(params)
            .then((res: any) => {
                if (res.items.length > 0) {
                    setPens(res.items);
                    console.log(res.items);
                    //Thêm thuộc tính trạng thái vào mảng pens
                    const tmp = res.items.map((item: any) => {
                        return {
                            ...item,
                            status: false
                        }
                    })
                    initRtcCamera(tmp);
                }

            })
            .catch((err) => {
                console.log(err);
            })
    };

    const getAllPenEmptys = () => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm.id,
            name: "",
            area: 0,
            weightTypeId: ""
        }

        apiPen.getAllPenEmpty(params)
            .then((res: any) => {
                setPenEmpty(res.items);
                initRtcCameraEmpty(res.items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const [penSelected, setPenSelected] = useState<IPenData | any>();
    const [isUpdate, setIsUpdate] = useState(false);

    const handleCloseCUWithoutCancelStream = () => {
        setOpenCU(false);
    }

    const handleOpenCU = (isUpdate: boolean, penSelected?: IPenData) => {
        setOpenCU(true);
        setPenSelected(penSelected);
        setIsUpdate(isUpdate);
    };

    const handleCloseCUAddPig = () => {
        setOpenCU(false);
        getAllPenEmptys()
    };

    useEffect(() => {
        return () => {
            terminateStreaming();
        }
    }, [])

    useEffect(() => {
        if (pens.length > 0) {

        }
    }, [pens])
    return (
        <div className={`${classes.root} main-content`}>
            {/* <PageHeader
                pageTile={`${pens.length} Chuồng nuôi`}
                isButton={false}
            /> */}
            <div className={classes.pensTitle}>{pens.length} Chuồng nuôi</div>

            {/* <div className={classes.videoList}>
                {
                    pens.map((pen, index) => {
                        return (
                            <>
                                <div className={classes.videoItem}>
                                    <VideoItem uuId={uuidv4()} camId={pen.cameras[0]?.id} width='300' height='300' />
                                </div>
                            </>
                        )
                    })
                }
            </div> */}
            {lstStreamingRtcCamera}

            {/* <PageHeader
                pageTile={`${penEmpty.length} Chuồng trống`}
                isButton={false}
            /> */}
            <div className={classes.pensTitle}>{penEmpty.length} Chuồng trống</div>
            {lstPenEmptyCamera}
            <Modal
                open={isFullScreen}
                // onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentCreate}>
                    <ModalHeader title={`${selectedPen?.name}`} closeButton={handleCloseCU} />
                    <div className={`cursor-pointer ${classes.videoItemFullScreen}`}>
                        <CamDetail penSelected={selectedPen} camSelectedData={selectedPen?.cameras[0]} isBackOnClick={false} isEmptyPen={false} />
                        {/* <CImageLoading
                            src={StreamAPI.getStream(selectedPen?.id)}
                            className="h-60"
                            uuid={uuidv4()}
                            // onClick={() =>
                            //     handleShowExpandMenu(true, item)
                            // }
                            isHiddenCamera={selectedPen?.cameras[0]?.isHiddenCamera ? true : false}
                            idCamera={selectedPen?.cameras[0]?.id}
                        /> */}
                    </div>
                </div>
            </Modal>
            <Modal
                open={isFullScreenEmpty}
                // onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentCreate}>
                    <ModalHeader title={`${selectedPen?.name}`} closeButton={handleCloseCUEmpty} />
                    <div className={`cursor-pointer ${classes.videoItemFullScreen}`}>
                        <CImageLoading
                            src={StreamAPI.getStream(selectedPen?.id)}
                            className="h-60"
                            isEmptyPen={true}
                            uuid={uuidv4()}
                            // onClick={() =>
                            //     handleShowExpandMenu(true, item)
                            // }
                            isHiddenCamera={selectedPen?.cameras[0]?.isHiddenCamera ? true : false}
                            idCamera={selectedPen?.cameras[0]?.id}
                        />
                    </div>
                </div>
            </Modal>
            {/* <Modal
                open={openCU}
                onClose={handleCloseCUAddPig}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentCreate}>
                    <ModalHeader title='Cập nhật chuồng' closeButton={handleCloseCUAddPig} />
                    <div style={{ zIndex: 2000 }}>
                        <PenEmptyCreat
                            onClose={handleCloseCU}
                            onRefresh={getAllPenEmptys}
                            penData={penSelected}
                            isUpdate={isUpdate}
                            keyProp={TabPaneKey.AddPig}
                            onCloseWithoutCancelStream={handleCloseCUWithoutCancelStream}
                            setIsAddPigPar={setIsAddPig}
                        />
                    </div>
                </div>
            </Modal> */}
        </div>
    );
}

export default Video;
