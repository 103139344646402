/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line react/prop-types

import * as pigApi from '@api/pig';
import { Context } from '@components/context/context';
import farmServices from '@services/farm';
import { ICommonListResponse, IPagination } from '@type/common';
import { DatePicker, DatePickerProps, Select, notification } from "antd";
import React, { FC, useEffect, useState } from 'react';
import './dashboard.scss';

import { Theme, createStyles, makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import routesName from '@routes/route-names';
import farmService from '@services/farm';
import userServices from '@services/user';
import axios from 'axios';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { BarChart, Bar as BarReCharts, CartesianGrid, Cell, ResponsiveContainer, Tooltip as TooltipReCharts, XAxis, YAxis } from 'recharts';
import { apiPen } from '../../api/pen';
import { CFG_SIZE_PAGINATION } from '../../constants/config';
import { setIsCloseWarning, setShowNotification } from '../../redux/controller/farm.slice';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import routerNames from '../../routers/route-names';
import { IFarmData } from '../../types/farm';
import { UtilPage } from '../../utils/page';
import ModalHeader from '../common/modal/modal-header';
import openNotification, { NotificationType } from '../common/notification/Notification';
import PenDetail from '../farm/pen/pen-detail';
import { Bar } from 'react-chartjs-2';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    id?: string,
    fromAge: number,
    toAge: number,
    symptoms: string[],
    metaData: string,
    name: string,
    lowerBoundTemperature: number,
    upperBoundTemperature: number,
    ageDescription: string,
    warning?: boolean
}
interface LabelType {
    textAnchor: string,
    verticalAnchor: string,
    orientation: string,
    width: number,
    height: number,
    x: number,
    y: number,
    className: string,
    stroke: string,
    fill: string,
    index: number,
    payload: {
        coordinate: number,
        value: string,
        index: number,
        offset: number,
        tickCoord: number,
        isShow: boolean
    },
    visibleTicksCount: number,
    tickFormatter: undefined

}
// const chartColors = [
//     "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF",
//     "#FF9F40", "#8C9EFF", "#FFD700", "#32CD32", "#FF6347",
//     "#00CED1", "#9932CC", "#FF4500", "#20B2AA", "#8B4513",
//     "#2E8B57", "#FF8C00", "#7B68EE", "#FF1493", "#008B8B"
// ];
// const chartColorsType = [
//     {
//         backgroundColor: "#FF6384",
//         id: '64d5f68711496f3cc453f5c5'
//     },
//     {
//         backgroundColor: "#36A2EB",
//         id: '64d5f68f11496f3cc453f5c7'
//     },
//     {
//         backgroundColor: "#FFCE56",
//         id: '64d5f69511496f3cc453f5d1'
//     },
//     {
//         backgroundColor: "#4BC0C0",
//         id: '64d5f6a311496f3cc453f628'
//     },
//     {
//         backgroundColor: "#9966FF",
//         id: '64d5f70711496f3cc453f7af'
//     }
// ]
interface AbnormalPig {
    totalAbnormalPigs?: number
    notEat: number,
    excited: number,
    lieDownLot: number
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
            overflowX: 'hidden',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
            overflow: 'hidden',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            // maxHeight: '700px'
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '70%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        breadcrumb: {
            cursor: 'pointer',
            // marginBottom: '-20px',
        },

    }),
);
const Dashboard: FC = () => {

    const Option = Select.Option;

    const [dataNotUpdate, setDataNotUpdate] = React.useState<ICommonListResponse>({ items: [], total: 0 });
    const [penData, setPenData] = React.useState<any>({ items: [], total: 0 });
    const [penEmptyData, setPenEmptyData] = React.useState<any>({ items: [], total: 0 });
    const [isListUpdated, setIsListUpdated] = React.useState<boolean>(false);
    const [searchInput, setSearchInput] = React.useState<IPagination>({ offset: 0, size: 20 });

    const context = React.useContext(Context);
    const [farm, setFarm] = useState<IFarmData>(farmServices.getCurrentFarm());
    const dispatch = useDispatchRoot()
    const { nameOfPensWarning, isCloseWarning, selectedFarm } = useSelectorRoot(state => state.farm)
    const [numberPenEmpty, setNumberPenEmpty] = useState<number>(0);
    const [numberPen, setNumberPen] = useState<number>(0);
    const history = useHistory();
    const [warningSickPig, setWarningSickPig] = useState<DataItem[]>([]);
    const [warningSickPigName, setWarningSickPigName] = useState<string>('');
    const [lstPenEventName, setLstPenEventName] = useState<string>('');
    const currentFarm = farmService.getCurrentFarm();
    const user = userServices.get();

    const [lstPenEvent, setLstPenEvent] = useState<any[]>([]);
    const [lstPigSickEvent, setLstPigSickEvent] = useState<any[]>([]);
    const [dateFarm, setDateFarm] = useState<any>(new Date().toLocaleDateString('vi-VN'));
    const [totalAbnormalPig, setTotalAbnormalPig] = useState<AbnormalPig>();
    const [totalTypePig, setTotalTypePig] = useState<any>([]);
    const [totalPenPig, setTotalPenPig] = useState<any>([]);
    const [totalFoodPig, setTotalFoodPig] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [dataChart, setDataChart] = useState<any>();
    const [dataChartType, setDataChartType] = useState<any>(null);
    const [dataChartPen, setDataChartPen] = useState<any>([]);
    const [dataChartFood, setDataChartFood] = useState<any>([]);
    const [options, setOptions] = useState<any>();
    const [optionsType, setOptionsType] = useState<any>([]);
    const [optionsPen, setOptionsPen] = useState<any>([]);
    const [optionsFood, setOptionsFood] = useState<any>([]);
    const [plugins, setPlugins] = useState<any>();
    const [pluginsType, setPluginsType] = useState<any>();
    const [dateStartFarm, setDateStartFarm] = useState<any>();
    const [maxValuePen, setMaxValuePen] = useState<any>(1);
    const [maxValueFood, setMaxValueFood] = useState<any>(0);
    const [lstTypeFood, setLstTypeFood] = useState<any>([]);
    const [chartColorsType, setChartColorsType] = useState<any>([]);
    const [chartColorsPen, setChartColorsPen] = useState<any>([]);
    const chartColors = [
        "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF",
        "#FF9F40", "#8C9EFF", "#FFD700", "#32CD32", "#FF6347",
        "#00CED1", "#9932CC", "#FF4500", "#20B2AA", "#8B4513",
        "#2E8B57", "#FF8C00", "#7B68EE", "#FF1493", "#008B8B"
    ];

    const [lstAllFoodByFarm, setLstAllFoodByFarm] = useState<any>([]);
    const [lstPenInFarm, setLstPenInFarm] = useState<any>([]);
    const [legendTypePig, setLegendTypePig] = useState<any>([]);

    const [totalTempPig, setTotalTempPig] = useState<any>(0);
    useEffect(() => {
        console.log('====================================');
        console.log('lstPenInFarm', legendTypePig);
        console.log('====================================');
    }, [legendTypePig]);

    const getAllPens = (isDelete = false) => {
        const params = {
            offset: UtilPage.calculateOffset(1),
            size: CFG_SIZE_PAGINATION,
            farmId: currentFarm.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };

        apiPen
            .getAllPen(params)
            .then((res: any) => {
                setNumberPen(res.total);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (farm) {
            getListUnUpdateIdentity();
            getAllPens();
            getNumberOfPenEmpty();
            getAllPenEmptys();
            getAllPenInFarm();
            getAllTotalTempPig();
            console.log('farm', farm);

        }
    }, [farm])

    useEffect(() => {
        selectedFarm && setFarm(selectedFarm);
        dispatch(setIsCloseWarning(false));
    }, [selectedFarm])

    useEffect(() => {
        if (warningSickPig.length > 0) {
            let tmp = ''
            warningSickPig.map((item: any, index) => {
                tmp += item.name + (index === warningSickPig.length - 1 ? '' : ', ')
            })
            setWarningSickPigName(tmp)
        }
    }, [warningSickPig])

    useEffect(() => {
        if (lstPenEvent.length > 0) {
            let tmp = ''
            lstPenEvent.map((item: any, index) => {
                tmp += item.name + (index === lstPenEvent.length - 1 ? '' : ', ')
            })
            setLstPenEventName(tmp)
        }
    }, [lstPenEvent])

    const getAllTotalTempPig = async () => {
        if (currentFarm.id) {
            await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/total/farm/${currentFarm.id}`)
                .then(res => {
                    const data = res.data.data.total;
                    setTotalTempPig(data);

                })
        }
    }

    const getListUnUpdateIdentity = async () => {
        try {
            if (farm) {
                const listUnIdentity: any = await pigApi.listPigByFarmId({ farmId: farm.id, ...searchInput, isIdentityConfirm: false });
                setDataNotUpdate(listUnIdentity);
            }
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Lỗi',
            } as any);
        }
    }

    // const getListUpdatedIdentity = async () => {
    //     try {
    //         const listPigs: any = await pigApi.listPigByFarmId({ farmId: farm.id, ...searchInput, isIdentityConfirm: true });

    //         setData(listPigs);
    //     } catch (err) {
    //         console.log(err);
    //         message.error('Đã xảy ra lỗi');
    //     }
    // }

    // const getNumberOfPen = async () => {
    //     try {
    //         const allPens: any = farm && await apiPen.getAllPensByFarmId({ farmId: farm.id, offset: 0, size: 150 });
    //         // console.log(allPens.items)
    //         setNumberPen(allPens.items.length)
    //         // allPens.items.map((item: any) => {
    //         //     console.log(item.analysisWarning);
    //         //     item.analysisWarning === null ? setNumberPenEmpty(numberPenEmpty => numberPenEmpty + 1) : setNumberPen(numberPen => numberPen + 1)
    //         // })

    //         setPenData(allPens);
    //     } catch (err) {
    //         console.log(err);

    //         message.error('Xảy ra lỗi khi gọi danh sách chuồng');
    //     }
    // }

    const getNumberOfPenEmpty = async () => {
        try {
            const allPenEmptys: any = farm && await apiPen.getAllPenEmpty({ farmId: farm.id });
            setPenEmptyData(allPenEmptys);
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Xảy ra lỗi khi gọi danh sách chuồng',
            } as any);
        }
    }

    const onUpdateList = () => {
        setIsListUpdated(!isListUpdated);
    }

    const onCloseWarning = () => {
        dispatch(setIsCloseWarning(true))
    }
    useEffect(() => {
        if (!isCloseWarning && nameOfPensWarning.length > 0) {
            let lstPenName = '';
            nameOfPensWarning.map(item => {
                lstPenName += item + ','
            })
            lstPenName = lstPenName.substring(0, lstPenName.length - 1)
            openNotification(NotificationType.Warning, 'topRight', `Cảnh báo hệ thống`, `Có ${nameOfPensWarning.length} chuồng nuôi đã nhập lợn nhưng chưa được hệ thống theo dõi. Bao gồm: ${lstPenName}.`)
        }
    }, [isCloseWarning, nameOfPensWarning])


    const navigatePig = () => {
        history.push(routesName.pig)
    }

    const navigatePen = () => {
        history.push(routerNames.barn)
    }
    const navigateEmptyPen = () => {
        history.push(routesName.penEmpty)
    }
    const loadDataSickPig = async () => {
        axios.get(`https://sit.api.pigman.com.vn/disease?size=1000`)
            .then(res => {
                console.log(res.data.data.items);

                const tmp = []
                for (let i = 0; i < res.data.data.items.length; i++) {
                    if (res.data.data.items[i].warning) {
                        res.data.data.items[i].symptoms = res.data.data.items[i].symptoms.map((item: { name: any; }) => item.name).join(', ')
                        console.log(res.data.data.items[i].symptoms);

                        tmp.push(res.data.data.items[i])
                    }
                }
                setWarningSickPig(tmp);
            })
    }

    useEffect(() => {
        console.log(currentFarm);

        loadDataSickPig();
        getAllPenEmptys();

    }, [currentFarm?.id])

    const getAllPenEmptys = (isDelete = false) => {
        console.log(currentFarm.id);

        const params = {
            offset: 0,
            size: 100,
            farmId: currentFarm.id,
        };

        apiPen
            .getAllPenEmpty(params)
            .then((res: any) => {
                console.log(res);

                const numberPage = res.total;
                setNumberPenEmpty(numberPage);

                isDelete && setNumberPenEmpty(numberPage);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (currentFarm.id) {
            const req = {
                offset: 0,
                size: 100,
                farmId: currentFarm?.id,
            }

            axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${currentFarm?.id}?offset=0&size=1000`)
                .then(res => {
                    console.log(res.data.data.items);
                    const currentDate = new Date(); // Ngày hiện tại

                    const data = res.data.data.items;
                    const tmp: any[] = []
                    data.map((item: any) => {
                        const date = new Date(item.last_update_date);
                        if (currentDate.getDate() === date.getDate()) {
                            tmp.push(item)
                        }
                    })

                    setLstPenEvent(tmp);
                })
        }

    }, [currentFarm?.id]);

    const goToSick = () => {
        history.push(routesName.sickPig)
    }

    const goToPen = () => {
        history.push(routesName.penManagement)
    }

    useEffect(() => {
        if (currentFarm?.id) {
            getFarmByOwnerId();
            getAllPigByFarmId();
            getALLTypeByFarmId();
            getAllPenByFarmId();
            getAllTypeOfFood();
            getAllFoodInFarm();

            const interval = setInterval(() => {
                const date = new Date();
                const minutes = date.getMinutes();

                if (minutes % 5 === 0) {
                    getFarmByOwnerId();
                    getAllPigByFarmId();
                    getALLTypeByFarmId();
                    getAllPenByFarmId();
                    getAllTypeOfFood();
                    getAllFoodInFarm();
                }
            }, 60 * 1000);
            return () => clearInterval(interval);
        }
    }, [currentFarm?.id]);

    const getAllFoodInFarm = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/food/farm/${currentFarm.id}`)
            .then(res => {
                setLstAllFoodByFarm(res?.data?.data);
            })
    }



    useEffect(() => {
        if (lstTypeFood) {
            // getAllFoodByFarmId();
        }
    }, [lstTypeFood]);

    const getFarmByOwnerId = async () => {
        const req = {
            offset: 0,
            size: 100,
            ownerId: user.id
        }
        await axios.post(`https://sit.api.pigman.com.vn/farms/onwerId`, req)
            .then(res => {
                console.log(res.data.data.items);
                const data = res.data.data.items;
                const tmp = data.filter((item: any) => item.id === currentFarm.id)
                setDateStartFarm(moment(tmp[0].createdAt).format('DD/MM/YYYY'))
            })
    }

    const getAllPigByFarmId = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/getAllPigs/${currentFarm.id}`)
            .then(async res => {
                console.log(res.data.data);
                const data = res.data.data;
                setData(data);

                await axios.get(`https://sit.api.pigman.com.vn/reportBehaviour/countBehaviour/countBehaviour?farmId=${currentFarm.id}`)
                    .then(res => {
                        console.log(res.data);
                        const data = res.data.data;

                        if (data.notEat || data.excited || data.lieDownLot) {
                            setTotalAbnormalPig(data)
                        }
                        else {
                            setTotalAbnormalPig({
                                notEat: 0,
                                excited: 0,
                                lieDownLot: 0
                            })

                        }
                    })
                // if (data.totalAbnormalPigs === 0) {
                //     setTotalAbnormalPig({
                //         totalAbnormalEatPigs: 0,
                //         totalAbnormalLiePigs: 0,
                //         totalAbnormalPlayPigs: 0,
                //         totalAbnormalPigs: 0
                //     })
                // }
                // else {
                //     const tmp: AbnormalPig = {
                //         totalAbnormalEatPigs: data.totalAbnormalEatPigs,
                //         totalAbnormalLiePigs: data.totalAbnormalLiePigs,
                //         totalAbnormalPlayPigs: data.totalAbnormalPlayPigs,
                //         totalAbnormalPigs: data.totalAbnormalEatPigs + data.totalAbnormalLiePigs + data.totalAbnormalPlayPigs
                //     }
                //     setTotalAbnormalPig(tmp)
                // }

            })
    }

    useEffect(() => {
        console.log(totalAbnormalPig);

        if (totalAbnormalPig) {

            const plugins = [{
                beforeDraw: function (chart: any) {
                    if (chart.config.options.elements.center) {
                        // Get ctx from string
                        const ctx = chart.ctx;

                        // Get options from the center object in options
                        const centerConfig = chart.config.options.elements.center;
                        const fontStyle = centerConfig.fontStyle || 'Arial';
                        const txt = centerConfig.text;
                        const color = centerConfig.color || '#000';
                        const maxFontSize = centerConfig.maxFontSize || 20;
                        const sidePadding = centerConfig.sidePadding || 20;
                        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                        // Start with a base font of 30px
                        ctx.font = "20px " + fontStyle;

                        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                        const stringWidth = ctx.measureText(txt).width;
                        const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                        // Find out how much the font can grow in width.
                        const widthRatio = elementWidth / stringWidth;
                        const newFontSize = Math.floor(30 * widthRatio);
                        const elementHeight = (chart.innerRadius * 2);

                        // Pick a new font size so it will not be larger than the height of label.
                        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                        let minFontSize = centerConfig.minFontSize;
                        const lineHeight = centerConfig.lineHeight || 25;
                        let wrapText = false;

                        if (minFontSize === undefined) {
                            minFontSize = 20;
                        }

                        if (minFontSize && fontSizeToUse < minFontSize) {
                            fontSizeToUse = minFontSize;
                            wrapText = true;
                        }

                        // Set font settings to draw it correctly.
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                        ctx.font = fontSizeToUse + "px " + fontStyle;
                        ctx.fillStyle = color;

                        if (!wrapText) {
                            ctx.fillText(txt, centerX, centerY);
                            //return;
                        }

                        /*
                        const words = txt.split(' ');
                        let line = '';
                        const lines = [];
            
                        // Break words up into multiple lines if necessary
                        for (let n = 0; n < words.length; n++) {
                          const testLine = line + words[n] + ' ';
                          const metrics = ctx.measureText(testLine);
                          const testWidth = metrics.width;
                          if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                          } else {
                            line = testLine;
                          }
                        }
            
                        // Move the center up depending on line height and number of lines
                        centerY -= (lines.length / 2) * lineHeight;
            
                        for (let n = 0; n < lines.length; n++) {
                          ctx.fillText(lines[n], centerX, centerY);
                          centerY += lineHeight;
                        }
                        //Draw text in center
                        ctx.fillText(line, centerX, centerY);
                        */
                    }

                    if (chart.config.options.elements.subCenter) {
                        // Get ctx from string
                        const ctx = chart.ctx;

                        // Get options from the center object in options
                        const centerConfig = chart.config.options.elements.subCenter;
                        const fontStyle = centerConfig.fontStyle || 'Arial';
                        const txt = centerConfig.text;
                        const color = centerConfig.color || '#000';
                        const maxFontSize = centerConfig.maxFontSize || 14;
                        const sidePadding = centerConfig.sidePadding || 20;
                        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                        // Start with a base font of 30px
                        ctx.font = "14px " + fontStyle;

                        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                        const stringWidth = ctx.measureText(txt).width;
                        const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                        // Find out how much the font can grow in width.
                        const widthRatio = elementWidth / stringWidth;
                        const newFontSize = Math.floor(30 * widthRatio);
                        const elementHeight = (chart.innerRadius * 2);

                        // Pick a new font size so it will not be larger than the height of label.
                        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                        let minFontSize = centerConfig.minFontSize;
                        const lineHeight = centerConfig.lineHeight || 25;
                        let wrapText = false;

                        if (minFontSize === undefined) {
                            minFontSize = 20;
                        }

                        if (minFontSize && fontSizeToUse < minFontSize) {
                            fontSizeToUse = minFontSize;
                            wrapText = true;
                        }

                        // Set font settings to draw it correctly.
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                        ctx.font = fontSizeToUse + "px " + fontStyle;
                        ctx.fillStyle = color;

                        if (!wrapText) {
                            ctx.fillText(txt, centerX, centerY + 20);
                            return;
                        }
                    }
                },
            }];

            setPlugins(plugins)
            const dataChart = {
                labels: ['Bỏ ăn', 'Kích động', 'Nằm nhiều'],
                datasets: [
                    {
                        data: [totalAbnormalPig.notEat ? totalAbnormalPig.notEat : 0,
                        totalAbnormalPig.excited ? totalAbnormalPig.excited : 0,
                        totalAbnormalPig.lieDownLot ? totalAbnormalPig.lieDownLot : 0
                        ],
                        // data: [30, 60, 100],
                        backgroundColor: chartColors.slice(0, 3), // Use the first 3 colors
                        hoverBackgroundColor: chartColors.slice(0, 3), // Use the first 3 colors
                        radius: [70, 100],

                    },
                ],
            };

            setDataChart(dataChart)

            let total = 0;
            if (totalAbnormalPig.notEat) {
                total += totalAbnormalPig.notEat;
            }
            if (totalAbnormalPig.excited) {
                total += totalAbnormalPig.excited;
            }
            if (totalAbnormalPig.lieDownLot) {
                total += totalAbnormalPig.lieDownLot;
            }
            const options = ({
                plugins: {
                    legend: {
                        display: false,
                        position: 'right', // Position the legend on the left side
                        labels: {
                            padding: 10,
                            generateLabels: function (context: any) {
                                const data = context.data.datasets[0].data;
                                const labels = context.data.labels;
                                const total = data.reduce((sum: any, value: any) => sum + value, 0);
                                const legendItems = labels.map((label: any, index: any) => {
                                    const percentage = ((data[index] / total) * 100).toFixed(2); // Calculate percentage
                                    return {
                                        text: `${label}: ${data[index]} con`,
                                        fillStyle: data[index] > 0 ? context.data.datasets[0].backgroundColor[index] : 'transparent', // Optional: hide items with value 0
                                        hidden: isNaN(data[index]) || data[index] === 0, // Optional: hide items with NaN or value 0
                                        index: index,
                                        marginRight: 10,
                                    };
                                });
                                return legendItems;
                            },
                            font: {
                                size: 9
                            }
                        },
                        onClick: null, // Prevent hiding/showing data on click

                    },
                },
                cutout: '80%',
                elements: {
                    center: {
                        text: `${(total)} con`,
                        // text: `Tổng ${100} con`,
                        color: '#505D6F', // Default is #000000
                        fontStyle: 'Arial', // Default is Arial
                        sidePadding: 20, // Default is 20 (as a percentage)
                        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    },
                }
            });

            setOptions(options)
        }
    }, [totalAbnormalPig])

    const getALLTypeByFarmId = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/totalPigType/getTotalPigTypes/${currentFarm.id}`)
            .then(res => {
                console.log(res.data.data);
                const data = res.data.data;
                const tmp: any = [];
                data.map((item: any, index: any) => {
                    tmp.push({
                        backgroundColor: chartColors[index],
                        id: item.id,
                        name: item.name,
                    })
                })
                console.log(tmp);
                setChartColorsType(tmp);
                setTotalTypePig(res.data.data);
            })
    }

    useEffect(() => {
        if (totalTypePig) {
            console.log(totalTypePig);

            const plugins = [{
                beforeDraw: function (chart: any) {
                    if (chart.config.options.elements.center) {
                        // Get ctx from string
                        const ctx = chart.ctx;

                        // Get options from the center object in options
                        const centerConfig = chart.config.options.elements.center;
                        const fontStyle = centerConfig.fontStyle || 'Arial';
                        const txt = centerConfig.text;
                        const color = centerConfig.color || '#000';
                        const maxFontSize = centerConfig.maxFontSize || 20;
                        const sidePadding = centerConfig.sidePadding || 20;
                        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                        // Start with a base font of 30px
                        ctx.font = "20px " + fontStyle;

                        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                        const stringWidth = ctx.measureText(txt).width;
                        const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                        // Find out how much the font can grow in width.
                        const widthRatio = elementWidth / stringWidth;
                        const newFontSize = Math.floor(30 * widthRatio);
                        const elementHeight = (chart.innerRadius * 2);

                        // Pick a new font size so it will not be larger than the height of label.
                        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                        let minFontSize = centerConfig.minFontSize;
                        const lineHeight = centerConfig.lineHeight || 25;
                        let wrapText = false;

                        if (minFontSize === undefined) {
                            minFontSize = 20;
                        }

                        if (minFontSize && fontSizeToUse < minFontSize) {
                            fontSizeToUse = minFontSize;
                            wrapText = true;
                        }

                        // Set font settings to draw it correctly.
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                        ctx.font = fontSizeToUse + "px " + fontStyle;
                        ctx.fillStyle = color;

                        if (!wrapText) {
                            ctx.fillText(txt, centerX, centerY);
                            //return;
                        }

                        /*
                        const words = txt.split(' ');
                        let line = '';
                        const lines = [];
            
                        // Break words up into multiple lines if necessary
                        for (let n = 0; n < words.length; n++) {
                          const testLine = line + words[n] + ' ';
                          const metrics = ctx.measureText(testLine);
                          const testWidth = metrics.width;
                          if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                          } else {
                            line = testLine;
                          }
                        }
            
                        // Move the center up depending on line height and number of lines
                        centerY -= (lines.length / 2) * lineHeight;
            
                        for (let n = 0; n < lines.length; n++) {
                          ctx.fillText(lines[n], centerX, centerY);
                          centerY += lineHeight;
                        }
                        //Draw text in center
                        ctx.fillText(line, centerX, centerY);
                        */
                    }

                    if (chart.config.options.elements.subCenter) {
                        // Get ctx from string
                        const ctx = chart.ctx;

                        // Get options from the center object in options
                        const centerConfig = chart.config.options.elements.subCenter;
                        const fontStyle = centerConfig.fontStyle || 'Arial';
                        const txt = centerConfig.text;
                        const color = centerConfig.color || '#000';
                        const maxFontSize = centerConfig.maxFontSize || 14;
                        const sidePadding = centerConfig.sidePadding || 20;
                        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                        // Start with a base font of 30px
                        ctx.font = "14px " + fontStyle;

                        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                        const stringWidth = ctx.measureText(txt).width;
                        const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                        // Find out how much the font can grow in width.
                        const widthRatio = elementWidth / stringWidth;
                        const newFontSize = Math.floor(30 * widthRatio);
                        const elementHeight = (chart.innerRadius * 2);

                        // Pick a new font size so it will not be larger than the height of label.
                        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                        let minFontSize = centerConfig.minFontSize;
                        const lineHeight = centerConfig.lineHeight || 25;
                        let wrapText = false;

                        if (minFontSize === undefined) {
                            minFontSize = 20;
                        }

                        if (minFontSize && fontSizeToUse < minFontSize) {
                            fontSizeToUse = minFontSize;
                            wrapText = true;
                        }

                        // Set font settings to draw it correctly.
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                        ctx.font = fontSizeToUse + "px " + fontStyle;
                        ctx.fillStyle = color;

                        if (!wrapText) {
                            ctx.fillText(txt, centerX, centerY + 20);
                            return;
                        }
                    }
                },
            }];

            const legendCallback = (legendItems: any, data: any) => {
                return legendItems.map((label: any) => {
                    const dataset = data.datasets[label.datasetIndex];
                    // Check if all data points in the dataset are 0
                    if (dataset.data.every((value: any) => value === 0)) {
                        // Return an empty string for the label text
                        return { ...label, text: '' };
                    }
                    return label;
                });
            };

            setPluginsType(plugins)
            const nameType = totalTypePig.map((item: any) => item.name)
            const total = totalTypePig.map((item: any) => item.total)
            const dataChart = {
                labels: nameType,
                datasets: [
                    {
                        data: total,
                        fill: true,
                        lineTension: 0.0,
                        // data: [10, 20, 30, 40, 50],
                        backgroundColor: chartColors.slice(0, totalTypePig.length), // Use the first 3 colors
                        hoverBackgroundColor: chartColors.slice(0, totalTypePig.length), // Use the first 3 colors
                        radius: [70, 100],
                    },
                ],
            };

            setDataChartType(dataChart)
            const numberType = totalTypePig.filter((item: any) => item.total > 0)

            const options = ({
                legend: {
                    display: false,
                    // labels: {
                    //     generateLabels: legendCallback,
                    // },
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'right', // Position the legend on the left side
                        labels: {
                            padding: 10,
                            generateLabels: function (context: any) {
                                const data = context.data.datasets[0].data;
                                const labels = context.data.labels;
                                const total = data.reduce((sum: any, value: any) => sum + value, 0);
                                const legendItems = labels.map((label: any, index: any) => {
                                    const percentage = ((data[index] / total) * 100).toFixed(2); // Calculate percentage
                                    return {
                                        text: `${label}: ${data[index]} con`,
                                        fillStyle: data[index] > 0 ? context.data.datasets[0].backgroundColor[index] : 'transparent', // Optional: hide items with value 0
                                        hidden: isNaN(data[index]) || data[index] === 0, // Optional: hide items with NaN or value 0
                                        index: index,
                                        paddingLeft: 10,
                                    };
                                });
                                return legendItems;
                            },
                            font: {
                                size: 9
                            }
                        },
                        onClick: null, // Prevent hiding/showing data on click
                    },
                },

                cutout: '80%',
                // cutoutPercentage: 70,
                elements: {
                    center: {
                        // text: `Tổng ${totalAbnormalPig.totalAbnormalPigs} con`,
                        text: `${numberType.length} loại lợn`,
                        color: '#505D6F', // Default is #000000
                        fontStyle: 'Arial', // Default is Arial
                        sidePadding: 30, // Default is 20 (as a percentage)
                        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    },
                }
            });

            setOptionsType(options)
        }
    }, [totalTypePig])

    const getAllPenByFarmId = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/all/byPen?farmId=${currentFarm.id}`)
            .then(res => {
                console.log(res.data.data);
                const data = res.data.data;
                setTotalPenPig(data)
            })
    }

    const getAllPenInFarm = async () => {
        const req = {
            offset: 0,
            size: 100,
            farmId: currentFarm.id,
        }
        await axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
            .then(res => {
                // console.log(res.data.data.items);
                setLstPenInFarm(res.data.data.items);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (lstPenInFarm) {

            console.log(lstPenInFarm);

            const tmp: any[] = [];
            let maxDataValue = 0;
            lstPenInFarm.map((item: any) => {
                if (item.totalPigs) {
                    tmp.push({
                        name: item.name,
                        time: moment(item.last_update_date).format('DD/MM/YYYY'),
                        'Số con': item.totalPigs,
                        id: item.id,
                        idType: item.pigType.id
                    })
                }
                else {
                    tmp.push({
                        name: item.name,
                        time: moment(item.last_update_date).format('DD/MM/YYYY'),
                        'Số con': 'Không có dữ liệu',
                        id: item.id,
                        idType: item.pigType.id

                    })
                }
                if (item.totalPigs > maxDataValue) {
                    maxDataValue = item.totalPigs
                }

            })

            console.log(maxDataValue);

            if (maxDataValue > 0 && maxDataValue < 10) {
                setMaxValuePen(maxDataValue + 1)
            }
            else if (maxDataValue >= 10 && maxDataValue < 50) {
                setMaxValuePen(maxDataValue + 5)
            }
            else if (maxDataValue >= 50 && maxDataValue < 100) {
                setMaxValuePen(maxDataValue + 10)
            }
            else if (maxDataValue >= 100) {
                setMaxValuePen(maxDataValue + 50)
            }
            console.log(tmp);
            const uniqueIdTypes = new Set();

            // Iterate through the array and add idType values to the Set
            tmp.forEach(item => {
                uniqueIdTypes.add(item.idType);
            });

            // Create an array of unique objects by using spread operator
            const uniqueIdTypesArray = [...uniqueIdTypes];
            console.log(uniqueIdTypesArray);
            const filteredData = chartColorsType.filter((item: any) => uniqueIdTypesArray.includes(item.id));
            console.log(filteredData);
            setChartColorsPen(filteredData);
            setDataChartPen(tmp)

        }
    }, [lstPenInFarm])

    const getAllTypeOfFood = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/food?farmId=${currentFarm.id}&offset=0&size=1000`)
            .then(res => {
                console.log(res.data.data);
                const tmp = res.data.data.items.map((item: any) => item.name)
                setLstTypeFood(tmp)
            })
    }

    // const getAllFoodByFarmId = async () => {
    //     await axios.get(`https://sit.api.pigman.com.vn/penFoodConsume/statistics/{farmId}?farmId=${currentFarm.id}`)
    //         .then(res => {
    //             console.log(res.data.data);
    //             setTotalFoodPig(res?.data?.data)
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    useEffect(() => {
        if (totalFoodPig) {
            console.log(totalFoodPig);
            console.log(lstTypeFood);

            const tmpFood: any[] = [];
            let maxValue = 0;
            totalFoodPig.map((item: any) => {
                const tmp = {
                    ...item,
                    name: item.pen,
                }
                delete tmp.pen;
                tmpFood.push(tmp)
                if (maxValue < item.total) {
                    maxValue = item.total
                }
            })
            console.log(tmpFood);
            const baseValue = 10; // The minimum value
            const increment = 20; // The increment value
            // Calculate the new max value
            const roundedMaxValueFood = Math.max(Math.ceil(maxValue / increment) * increment, baseValue);
            setMaxValueFood(roundedMaxValueFood)

            setDataChartFood(tmpFood)
        }
    }, [totalFoodPig])

    const CustomizedAxisTick = (props: any) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`} >
                <text x={0} y={0} dy={0} textAnchor="end" fill="#666" transform="rotate(-90)" style={{ fontSize: '10px' }}>
                    {payload.value}
                </text>
            </g >
        );
    };

    const CustomizedAxisTickPen = (props: any) => {

        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text style={{ zIndex: 1000 }} fontSize={10} x={25} y={0} dy={10} textAnchor="end" fill="#666" >
                    {payload.value}
                </text>
            </g >
        );
    }

    const navigatePenManager = () => {
        history.push(routesName.penManagement)
    }

    const navigatePenEmpty = () => {
        history.push(routesName.penEmpty)
    }

    const navigatePenFood = () => {
        history.push(routesName.foodConsume)
    }

    const navigateTempPig = () => {
        history.push(routesName.reportTempPig)
    }

    const navigateAbnormalPig = () => {
        history.push(routesName.reportUnusualPig)
    }
    const { showNotification } = useSelectorRoot(state => state.farm)

    const notificationShow = () => {
        dispatch(setShowNotification(!showNotification))
    }

    const handleClickCell = (row: any) => {
        console.log(row);
        loadLstPen(row.id);
    }

    const [penSelected, setPenSelected] = useState<any>(null)
    const [openCU, setOpenCU] = useState(false);

    const loadLstPen = async (id: any) => {
        const req = {
            offset: 0,
            size: 100,
            farmId: currentFarm.id,
            additionalProp1: {}
        }
        // Lấy ra chuồng theo id
        axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
            .then(res => {
                const dataItem = res.data.data.items.find((pen: { id: any; }) => pen.id === id);
                setPenSelected(dataItem);
            })

    }

    useEffect(() => {
        if (penSelected) {
            // history.push(`/quan-ly-chuong`)
            setOpenCU(true);
        }

    }, [penSelected])

    const handleCloseCU = () => {
        setOpenCU(false);
    };
    const weekFormat = 'DD/MM/YYYY';
    const customWeekStartEndFormat: DatePickerProps['format'] = value =>
        `${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value).endOf('week').format(weekFormat)}`;

    const [startTimeFilter, setStartTimeFilter] = useState<any>(moment(new Date()).startOf('week').format('YYYY-MM-DD'))
    const [endTimeFilter, setEndTimeFilter] = useState<any>(moment(new Date()).endOf('week').format('YYYY-MM-DD'))
    const [foodIdFilter, setFoodIdFilter] = useState<any>()

    const handleChangeDateFood = (date: any) => {
        console.log(moment(date).format('YYYY-MM-DD'));
        setStartTimeFilter(moment(date).startOf('week').format('YYYY-MM-DD'))
        setEndTimeFilter(moment(date).endOf('week').format('YYYY-MM-DD'))
    }

    const handleFilterFood = async (e: any) => {
        console.log(e);
        setFoodIdFilter(e)
    }

    useEffect(() => {
        console.log(foodIdFilter);
        console.log(startTimeFilter);
        console.log(endTimeFilter);

        let tmp = '';

        if (startTimeFilter && startTimeFilter !== 'Invalid date') {
            tmp = tmp + `?start=${startTimeFilter}`
        }
        if (endTimeFilter && endTimeFilter !== 'Invalid date') {
            tmp = tmp + `&end=${endTimeFilter}`
        }
        if (foodIdFilter) {
            tmp = `&foodId=${foodIdFilter}`
        }
        getDataChartFoodFilter(tmp)

    }, [foodIdFilter, startTimeFilter, endTimeFilter])

    const getDataChartFoodFilter = async (req: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/penFoodConsume/statistics/${currentFarm.id}${req}`)
            .then(res => {
                console.log(res);
                setTotalFoodPig(res?.data?.data)

            })

    }

    return (
        <div style={{ position: 'relative' }}>
            {/* <div id='pig' style={{ zIndex: 1 }}>
                <Pig isOpacityStyle={false} className={''} />
            </div> */}

            {/* <div id="pig-banner">
                <div id='pig-banner__content' className="main-content">
                    <h1> {dataNotUpdate.total} con chưa xác nhận danh tính </h1>

                    <span className='btn' id='identity-btn' onClick={toggleModal(true)}>Xác nhận danh tính</span>
                </div>
            </div> */}
            {data &&
                <div id="dashboard">
                    <div className='warning-sick-pig'>
                        <div className="scroll">
                            {warningSickPig.length > 1 &&
                                <div className="RightToLeft">
                                    {warningSickPig.length > 0 &&
                                        warningSickPig.map((item, index) => (
                                            <>
                                                {index < 2 &&
                                                    <span key={index}>Hiện tại dịch: <b>{item.name}</b> có diễn biến phức tạp, cần lưu ý các triệu chứng sau: {item.symptoms}</span>
                                                }
                                            </>
                                        )
                                        )
                                    }
                                </div>
                            }
                            {warningSickPig.length === 1 &&
                                <div className="RightToLeft one-warning">
                                    {
                                        warningSickPig.map((item, index) => (
                                            <>
                                                <span key={index}>Hiện tại dịch: <b>{item.name}</b> có diễn biến phức tạp, cần lưu ý các triệu chứng sau: {item.symptoms}</span>
                                            </>
                                        )
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className='title-dashboard'>
                        <div className='title-text'>Ngày khởi tạo trang trại: </div>
                        <div className='title-value'>{dateStartFarm ? dateStartFarm : 'Chưa có dữ liệu'}</div>
                    </div>
                    <div className='main-dashboard-content '>
                        <div className='dashboard-content content-1'>
                            <div className='dashboard-content__item'>
                                <div className='dashboard-content__item__title'>Số lượng cá thể được gắn thẻ nhiệt</div>
                                <div className={'dashboard-content__item__value ' + (totalPenPig ? ' ' : ' not')} >{totalPenPig ? totalPenPig.totalTemperatureTag : 'Chưa có dữ liệu đầy đủ'}</div>
                            </div>
                            <div className='dashboard-content__item' onClick={navigateTempPig}>
                                <div className='dashboard-content__item__title' >Số lượng cá thể bất thường nhiệt độ hiện tại</div>
                                <div className={'dashboard-content__item__value' + (totalTempPig ? ' ' : ' not')}>{totalTempPig ? totalTempPig : 'Không phát hiện cá thể bất thường'}</div>
                            </div>
                            <div className='dashboard-content__item' onClick={navigatePenManager}>
                                <div className='dashboard-content__item__title'>Số lượng chuồng nuôi</div>
                                <div className='dashboard-content__item__value'>{numberPen}</div>
                            </div>
                            <div className='dashboard-content__item' onClick={navigatePenEmpty}>
                                <div className='dashboard-content__item__title'>Số lượng chuồng trống</div>
                                <div className='dashboard-content__item__value'>{numberPenEmpty}</div>
                            </div>
                        </div>
                        <div className='dashboard-content content-2'>
                            <div className='dashboard-content__item' onClick={navigateAbnormalPig} >
                                <div className='dashboard-content__item__title'>Số lượng cá thể bất thường hành vi trong 1 giờ qua</div>
                                {(totalAbnormalPig && (totalAbnormalPig.notEat !== 0 || totalAbnormalPig.excited !== 0 || totalAbnormalPig.lieDownLot !== 0)) &&
                                    <div className='chart-dashboard' >
                                        {dataChart && options && plugins &&
                                            <Doughnut className='chart-type-pig pig-abnormal' data={dataChart} options={options} plugins={plugins} />
                                        }
                                        <div className='legend-chart-pig pig-abnormal'>
                                            <div className='legend-chart-pig__item'>
                                                <div className='legend-chart-pig__item__color' style={{ backgroundColor: chartColors[0] }}></div>
                                                <div className='legend-chart-pig__item__name'>Bỏ ăn: {totalAbnormalPig.notEat}</div>
                                            </div>
                                            <div className='legend-chart-pig__item'>
                                                <div className='legend-chart-pig__item__color' style={{ backgroundColor: chartColors[1] }}></div>
                                                <div className='legend-chart-pig__item__name'>Kích động: {totalAbnormalPig.excited}</div>
                                            </div>
                                            <div className='legend-chart-pig__item'>
                                                <div className='legend-chart-pig__item__color' style={{ backgroundColor: chartColors[2] }}></div>
                                                <div className='legend-chart-pig__item__name'>Nằm nhiều: {totalAbnormalPig.lieDownLot}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {totalAbnormalPig && (totalAbnormalPig.notEat === 0 && totalAbnormalPig.excited === 0 && totalAbnormalPig.lieDownLot === 0)
                                    && <div className='not-found'>Không phát hiện cá thể bất thường</div>
                                }
                            </div>
                            <div className='dashboard-content__item' onClick={navigatePenManager}>
                                <div className='dashboard-content__item__title'>Loại lợn</div>
                                {(dataChartType) &&
                                    <div className='chart-dashboard' >
                                        <Doughnut className='chart-type-pig' data={dataChartType} options={optionsType} plugins={pluginsType} />
                                        <div className='legend-chart-pig'>
                                            {totalTypePig && totalTypePig.map((item: any, index: any) => (
                                                <>

                                                    <div className='legend-chart-pig__item'>
                                                        <div className='legend-chart-pig__item__color' style={{ backgroundColor: chartColors[index] }}></div>
                                                        <div className='legend-chart-pig__item__name'>{item.name}: {item.total}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {
                                    !dataChartType &&
                                    <div className='not-found'>Không có dữ liệu</div>
                                }
                            </div>
                        </div>
                        <div className='dashboard-content content-3'>
                            <div className='dashboard-content__item' >
                                <div className='dashboard-content__item__title'>Tổng quan về trang trại</div>
                                {dataChartPen && dataChartPen.length > 0 &&
                                    <div className='chart-dashboard' >
                                        <div className='lenged-chart'>
                                            <div className='label'>
                                                {chartColorsPen && chartColorsPen.map((item: any, index: any) => (
                                                    <>
                                                        <div className='label-item'>
                                                            <div className='label-color' style={{ backgroundColor: item.backgroundColor }}></div>
                                                            <div className='label-name'>{item.name}</div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart width={600} height={400} data={dataChartPen}
                                                margin={{
                                                    top: 50,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0, // Increase bottom padding
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" fontSize={10} />
                                                <YAxis domain={[0, maxValuePen]} />
                                                <TooltipReCharts
                                                    contentStyle={{ backgroundColor: '#fff', border: 'none', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)' }}
                                                    labelStyle={{ color: '#000', fontWeight: 'bold' }}
                                                    itemStyle={{ color: '#000' }}
                                                    // show value 'Số con' and 'Ngày bắt đầu theo dõi'
                                                    formatter={(value: any, name: any, props: any, index: any) => {


                                                        // dataChartPen.map((item: any) => {
                                                        //     if (item.name === props.payload.name) {
                                                        //         value = value + ' con'
                                                        //     }
                                                        // })
                                                        return <>
                                                            {value}
                                                            <div>
                                                                <div >Ngày bắt đầu theo dõi: {props.payload.time}</div>
                                                            </div>
                                                        </>

                                                    }}
                                                />
                                                {/* <Legend verticalAlign="top" height={36} /> */}
                                                <BarReCharts dataKey="Số con" fill="#8884d8" label={{ position: 'top' }} transform='rot'>
                                                    {dataChartPen.map((entry: any, index: any) => (
                                                        <Cell
                                                            key={entry.id}
                                                            fill={chartColorsType && chartColorsType.find((colorEntry: any) => colorEntry.id === entry.idType)?.backgroundColor || '#8884d8'}
                                                            onClick={() => handleClickCell(entry)}
                                                        />
                                                    ))}
                                                </BarReCharts>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                }
                                {!dataChartPen || dataChartPen.length === 0 &&
                                    <div className='not-found'>Không có dữ liệu</div>
                                }
                            </div>
                        </div>
                        <div className='dashboard-content content-3'>
                            <div className='dashboard-content__item' >
                                <div className='dashboard-content__item__title'>
                                    <div className='title-left'>Tiêu thụ thức ăn trong trang trại</div>
                                    <div className='title-right'>
                                        <Select
                                            showSearch
                                            placeholder='Chọn thức ăn'
                                            filterOption={(input, option) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            allowClear
                                            onChange={handleFilterFood}
                                        >
                                            {lstAllFoodByFarm && lstAllFoodByFarm.map((item: any, index: any) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            }
                                            )}
                                        </Select>
                                        <DatePicker
                                            className='chart-food-picker'
                                            format={customWeekStartEndFormat}
                                            picker="week"
                                            onChange={handleChangeDateFood}
                                            placeholder='Chọn tuần'
                                            defaultValue={moment(new Date()).startOf('week')}
                                            allowClear={false}
                                        />
                                    </div>
                                </div>
                                <div className='chart-dashboard chart-food' >
                                    {dataChartFood && dataChartFood.length > 0 &&
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={600}
                                                height={400}
                                                data={dataChartFood}
                                                className='chart-food'
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" tick={<CustomizedAxisTickPen />} />
                                                <YAxis domain={[0, maxValueFood]} />
                                                <TooltipReCharts
                                                    contentStyle={{ backgroundColor: '#fff', border: 'none', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)' }}
                                                    labelStyle={{ color: '#000', fontWeight: 'bold' }}
                                                    itemStyle={{ color: '#000' }}

                                                />
                                                {/* <Legend height={36} /> */}
                                                {/* {lstTypeFood?.map((item: any, index: any) => {
                                                    <BarReCharts dataKey={item} stackId="a" fill={chartColors[index]} />
                                                })} */}
                                                {lstTypeFood && lstTypeFood.map((item: any, index: any) => (
                                                    <BarReCharts
                                                        key={index}
                                                        dataKey={item}
                                                        stackId="a"
                                                        fill={chartColors[index]}
                                                    // label={{ position: 'top' }}
                                                    // transform='rot'
                                                    >
                                                    </BarReCharts>
                                                ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }
                                    {!dataChartFood || dataChartFood.length === 0 &&
                                        <div className='not-found'>Không có dữ liệu</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="dashboard-content" className="main-content">
                    <div id="dashboard-row-1">
                        <div className="dashboard-box dashboard-box-shadow">
                            <div className="chart-header">
                                <h3> Trạng thái </h3>
                            </div>
                            {(farm) && <DashboardDoughnutChart farmId={farm?.id} isChangeLocation={context?.changeLocation?.isChangeLocation} onUpdateList={onUpdateList} dataNotUpdate={dataNotUpdate} />}
                        </div>

                        <div className="dashboard-information">
                            <div className="dashboard-box">
                                <div className='dashboard-info-content'>
                                    <div className="info-icon">
                                        <img src={pigpen} alt="pigevent" />
                                        <span>Chuồng</span>
                                    </div>
                                    <div className='info-content pen-content'>
                                        <div className="info-data" onClick={navigatePen}>
                                            <div className="data">{numberPen}</div>
                                            <div className="label">Chuồng nuôi</div>
                                        </div>
                                        <div className="info-data" onClick={navigateEmptyPen}>
                                            <div className="data">{numberPenEmpty}</div>
                                            <div className="label">Chuồng Trống</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-box">
                                <div className='dashboard-info-content'>
                                    <div className="info-icon event">
                                        <img src={pigevent} alt="pigpen" />
                                        <span>Sự kiện</span>
                                    </div>

                                    <div className='info-content'>
                                        {lstPenEvent.length > 0 ?
                                            <div className="info-data event" onClick={goToPen}>
                                                <div>Các chuồng mới được cập nhật hôm nay</div>
                                                { }
                                                <Tooltip title={lstPenEventName}>
                                                    <b>
                                                        {lstPenEvent.map((item: any, index: number) => (
                                                            item.name + (index === lstPenEvent.length - 1 ? '' : ', ')
                                                        ))}
                                                    </b>
                                                </Tooltip>
                                            </div>
                                            :
                                            <div className="info-data event">
                                                <div>Các chuồng mới được cập nhật hôm nay</div>
                                                <div><b>Hiện chưa có chuồng nào được cập nhật hôm nay</b></div>
                                            </div>
                                        }
                                        {warningSickPig.length > 0 ?
                                            <div className="info-data event" onClick={goToSick}>
                                                <div>Các dịch bệnh được cảnh báo</div>
                                                <Tooltip
                                                    title={warningSickPigName}>
                                                    <b >
                                                        {warningSickPig.map((item: any, index: number) => (
                                                            item.name + (index === warningSickPig.length - 1 ? '' : ', ')
                                                        ))}
                                                    </b>
                                                </Tooltip>
                                            </div>
                                            :
                                            <div className="info-data event">
                                                <div>Các dịch bệnh được cảnh báo</div>
                                                <div title='Hiện chưa có dịch bệnh nào được cảnh báo hôm nay'><b>
                                                    Hiện chưa có dịch bệnh nào được cảnh báo hôm nay
                                                </b></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div >
            }
            <Modal
                open={openCU}
                onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className='makeStyles-modal-10'
            >
                <div className='makeStyles-modalContentCreate-11'>
                    <ModalHeader title={`${penSelected?.name}`} closeButton={handleCloseCU} />
                    <PenDetail penSelected={penSelected} />
                </div>
            </Modal>
        </div >
    );
};

export default Dashboard;
