/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routers from '@routes/.';
import routesName from '@routes/route-names';
import 'antd/dist/antd.css';
import '@public/css/core.scss';
import { Provider } from 'react-redux';
import store from './redux/store';
import { notification } from 'antd';
import userServices from '@services/user';
import axios from 'axios';
import { ILoginUser } from './types/user';
import userService from '@services/user';
import farmService from '@services/farm';
import { useHistory } from 'react-router-dom';
import { IFarmCookiesData } from './types/farm';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { fetchLstEmptyPens, changeSelectedFarm } from './redux/controller/farm.slice';

function App() {
    // const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
    const user = userServices.get();
    const currentFarm = farmService.getCurrentFarm();

    const history = useHistory();
    // useEffect(() => {
    //     console.log('notificationPermission', notificationPermission);
    //     const isEdgeBrowser = /Edg/i.test(window.navigator.userAgent);
    //     console.log(isEdgeBrowser);

    //     if (notificationPermission !== 'granted' && isEdgeBrowser && user?.id) {
    //         notification.warning({
    //             message: 'Bạn đang dùng trình duyệt Edge',
    //             description: 'Để nhận được thông báo, vui lòng chấp nhận thông báo trên trình duyệt',
    //         })
    //     }
    // }, []);

    useEffect(() => {
        console.log(user);
        console.log(currentFarm);

        const tmpUser: any = {
            id: "65aa4865b3fdef380ef39d5c",
            username: "kienn101120@gmail.com",
            email: "kienn101120@gmail.com",
            fullName: "kien nguyen",
            gender: 0,
            phone: "0986741236",
            active: 1,
            createTime: 1703488987446,
            updateTime: 1703488987446,
            role: "OWNER",
            defaultFarm: null,
            isBlock: false,
            accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YWE0ODY1YjNmZGVmMzgwZWYzOWQ1YyIsImlhdCI6MTcwNTY4MDczNSwiZXhwIjoxNzA1OTM5OTM1fQ.Gd2O1-WrxKP6KPyHkOFW5Louet01aOJRQ0a-Wotz3_w"
        }

        if (!user || currentFarm === null) {
            console.log('doSubmit');

            userService.set(tmpUser);
            if (tmpUser.defaultFarm) {
                farmService.setCurrentFarm(tmpUser.defaultFarm);
                window.location.href = '/video';
            }
            else {
                setDefaultFarm();
                // window.location.href = '/select-farm';
            }
            // window.location.reload();
            // Chuyển đến đường dẫn video
        }
    }, [user, currentFarm]);

    const setDefaultFarm = async () => {
        const req = {
            offset: 0,
            size: 100,
            ownerId: '65aa4865b3fdef380ef39d5c',
            additionalProp1: {}
        }
        await axios.post('https://sit.api.pigman.com.vn/farms/onwerId', req)
            .then((res) => {
                console.log(res.data.data.items);
                const farms = res.data.data.items;
                const currentFarmCookies: IFarmCookiesData = {
                    // address: farms[0].address,
                    // id: farms[0].id,
                    // name: farms[0].name,
                    // ownerId: farms[0]?.ownerId
                    address: farms[farms.length - 1].address,
                    id: farms[farms.length - 1].id,
                    name: farms[farms.length - 1].name,
                    ownerId: farms[farms.length - 1].ownerId
                }
                farmService.setCurrentFarm(currentFarmCookies);
                window.location.href = '/video';
                // farms[0].pens && dispatch(fetchLstEmptyPens(farms[0].pens))
                // farms[0] && dispatch(changeSelectedFarm(farms[0]))
                // history.push(routesName.dashboard);
            })
            .catch((err) => {
                notification.error({
                    message: err?.response?.data?.message,
                } as any);
            })
    }

    const doSubmit = async (): Promise<void> => {

        const req = {
            email: 'pigman_01@gmail.com',
            password: '12344321',
            remember: true,
            additionalProp1: {}
        }

        await axios.post('https://sit.api.pigman.com.vn/users/login', req)
            .then((res) => {
                const user = res.data.data as ILoginUser;
                userService.set(user);
                console.log(user)
                if (user.role === 'ADMIN') {
                    farmService.setCurrentFarm(user.defaultFarm);
                    history.push(routesName.profile);
                }
                else {
                    if (user.defaultFarm) {
                        farmService.setCurrentFarm(user.defaultFarm);
                        history.push(routesName.video);
                    } else {
                        history.push(routesName.selectFarm);
                    }
                }

            })
            .catch((err) => {
                notification.error({
                    message: err?.response?.data?.message,
                } as any);
            })

        // catch (err: any) {
        //     console.log(err);

        //     if (typeof err === 'string') {
        //         message.error(err);
        //     } else {
        //         message.error('Đã xảy ra lỗi')
        //     }

        //     if (err.status === 500) {
        //         message.error('Lỗi hệ thống!');
        //     }
        // }
    };

    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    {routers.map(({
                        exact,
                        path,
                        component: Component,
                        layout: Layout,
                        routes: nestedRoutes,
                        isLoginRequired
                    }, i) => {
                        return (
                            <Route
                                key={i}
                                exact={exact}
                                path={path}
                                render={(props) => {
                                    return (
                                        <Layout isLoginRequired={isLoginRequired} routesProps={props}>
                                            <Component {...props} routes={nestedRoutes} />
                                        </Layout>
                                    );
                                }}
                            />
                        );
                    })}
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
