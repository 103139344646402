/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import routesName from '@routes/route-names';
import farmService from '@services/farm';
import clsx from 'clsx';
import { withStyles, createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import { Button as ButtonAntd } from 'antd';

import FarmCreate from './farm-create';
import PageHeader from '../common/page-header/page-header';
import ModalHeader from '../common/modal/modal-header';
import CustomAlert from '../common/alert/custom-alert';

import { CFG_SIZE_PAGINATION } from '@constants/config';
import userServices from '@services/user';
import { apiFarm } from '@api/farm';
import { apiUser } from '@api/user';
import { IFarmCookiesData, IFarmData } from '@type/farm';
import { UtilPage } from '@utils/page';
import { ILoginUser } from '@components/auth/auth.types';

import { ICommonListResponse } from '@type/common';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import { changeSelectedFarm, fetchLstEmptyPens, updateFarmList } from '../../redux/controller/farm.slice';
import openNotification, { NotificationType } from '../common/notification/Notification';
import { useHistory } from 'react-router-dom';
import './style.farm.scss';
import { Context } from '../context/context';
import { Breadcrumb } from 'antd';
import axios from 'axios';
type Order = 'asc' | 'desc';
import { Modal as ModalAntd } from 'antd';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'STT', numeric: false, disablePadding: true, label: 'STT' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Tên trang trại' },
    { id: 'address', numeric: false, disablePadding: false, label: 'Địa chỉ' },
    // { id: 'temperature', numeric: false, disablePadding: false, label: 'Nhiệt độ (°C)' },
    // { id: 'humidity', numeric: false, disablePadding: false, label: 'Độ ẩm (%)' },
    // { id: 'pressure', numeric: false, disablePadding: false, label: 'Áp suất (hPa)' },
    { id: 'farmType', numeric: false, disablePadding: false, label: 'Loại trang trại' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email quản lý' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IFarmData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof IFarmData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className={classes.checkbox}
                    />
                </StyledTableCell> */}
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={true}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    page: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, page } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Đã chọn {numSelected} trang trại
                </Typography>
            ) : (
                <Typography className={classes.title} id="tableTitle" component="div" >
                    Tổng số {page} Trang trại
                </Typography>
            )}
            {numSelected > 0 ? (
                // <Tooltip title="Delete">
                //     <IconButton aria-label="delete">
                //         <DeleteIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            ) : (
                // <Tooltip title="Filter list">
                //     <IconButton aria-label="filter list">
                //         <SearchIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            )}
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '24px',
        },
    }),
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
            width: '100%',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        btnDone: {
            backgroundColor: '#FFECEE',
            color: '#001AEE',
            fontSize: '12px',
            textTransform: 'none',
            width: '150px',
        },
        btnCanel: {
            backgroundColor: '#001AEE',
            color: '#FFFFFF',
            fontSize: '12px',
            textTransform: 'none',
            width: '150px',
        },
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#001cee18',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#001cee18',
            },
        },
    }),
)(TableRow);

const Farm: FC = () => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IFarmData>('name');
    const [selected, setSelected] = useState<string[]>([]);
    const [openCU, setOpenCU] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [farms, setFarms] = useState<ICommonListResponse<IFarmData | any>>({ items: [], total: 0 });
    const [farmSelected, setFarmSelected] = useState<IFarmData | any>();
    const [isUpdate, setIsUpdate] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const context = React.useContext(Context);

    const user = userServices.get();
    const dispatch = useDispatchRoot();
    const { lstFarms } = useSelectorRoot(state => state.farm)

    const history = useHistory();
    const [titleDelete, setTitleDelete] = useState('');
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        getAllFarms();
    }, [page]);

    const getAllFarms = async () => {
        console.log('user', user);

        const params = {
            offset: UtilPage.calculateOffset(page),
            size: CFG_SIZE_PAGINATION,
            ownerId: user.id,
        };

        console.log('params', params);
        await apiFarm
            .getAll(params)
            .then((res: any) => {
                console.log('res', res);

                if (res.items) {
                    setFarms(res);
                    const farmList: any = [];
                    res.items.map((farm: any) => {
                        farmList.push({ value: farm, label: farm.name });
                    });
                    dispatch(updateFarmList(farmList));
                    setTotal(UtilPage.calculateNumberPage(res.total));

                    if (farmList.length == 0) {
                        farmService.removeCurrentFarm();
                        history.push(routesName.selectFarm);
                    }

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const doDelete = () => {
        apiFarm
            .deleteFarm(farmSelected?.id)
            .then((res) => {
                deleteAccFromFarm();
                getAllFarms();
                handleCloseDelete();
                openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Xóa trang trại thành công.`);
                const newArray = lstFarms?.filter((obj: any) => obj.value.id !== farmSelected?.id);
                handleInputChangeFarm(newArray[0]);
                // window.location.reload();

            })
            .catch((err) => {
                console.log('doDelete-err:' + JSON.stringify(err));
            });
    };


    const handleInputChangeFarm = (selectedOption: any) => {
        const { value } = selectedOption;
        dispatch(changeSelectedFarm(value))
        const currentFarmCookies: IFarmCookiesData = {
            address: value.address,
            id: value.id,
            name: value.name,
            ownerId: value?.ownerId
        }
        farmService.setCurrentFarm(currentFarmCookies);
        farmService.setCurrentFarm(value);
        value.pens && dispatch(fetchLstEmptyPens(value.pens))
        console.log(value)
        // location.reload();
        // updateLocation();
        context.changeLocation.onChangeLocation();

        const newArray = lstFarms?.filter((obj: any) => obj.value.id !== value.id);
        console.log('newArray', newArray);
    };
    const setDefaultFarm = async (farmId: string) => {
        const data = {
            defaultFarm: farmId,
        };
        const userData = (await apiUser.updateUser(user.id, data)) as ILoginUser;
        userServices.set(userData);
        getAllFarms();
    };

    const cancelDefaultFarm = async () => {
        const data = {
            defaultFarm: '',
        };
        const userData = (await apiUser.updateUser(user.id, data)) as ILoginUser;
        userServices.set(userData);
        getAllFarms();
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IFarmData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = farms.items.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, penCode: any) => {
        const selectedIndex = selected.indexOf(penCode);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, penCode);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleOpenCU = (isUpdate: boolean, farmSelected?: IFarmData) => {
        setOpenCU(true);
        setFarmSelected(farmSelected);
        setIsUpdate(isUpdate);
    };

    const handleCloseCU = () => {
        setOpenCU(false);
    };

    const handleOpenDelete = async (farmSelected?: IFarmData) => {
        // await axios.get(`https://sit.api.pigman.com.vn/farms/getAllPigs/${farmSelected?.id}`)
        //     .then((response) => {
        //         console.log('response', response.data.data);
        //         if (response.data.data.pigs.length > 0) {
        //             setTitleDelete(`Trang trại ${farmSelected?.name} đang có lợn. Bạn có chắc chắn muốn xóa không?`);
        //         }
        //         else {
        //             setTitleDelete(`Bạn có chắc chắn muốn xóa trang trại ${farmSelected?.name}?`);
        //         }
        //     });
        // setOpenDelete(true);
        setIsShowDeleteModal(true);
        setFarmSelected(farmSelected);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const isSelected = (penCode: any) => selected.indexOf(penCode) !== -1;
    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = farms.items.slice(startIndex, endIndex);
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPage(newPage);
    };

    const deleteAccFromFarm = async () => {
        await axios.delete(`https://sit.api.pigman.com.vn/manager/${farmSelected?.id}`)
            .then((response) => {
                console.log('response', response);
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    const handleFinishDeleteForm = () => {
        doDelete();
        setIsShowDeleteModal(false);
    }
    return (
        <div className={`${classes.root} main-content main-farm`}>
            <Paper className={classes.paper} elevation={0}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div className='main-breadcrumb'>
                        {/* <Breadcrumb>
                            <Breadcrumb.Item>
                                <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Chuồng trống</Breadcrumb.Item>
                        </Breadcrumb> */}
                    </div>
                    {user?.role !== 'MANAGER' &&
                        <PageHeader
                            pageTile=""
                            isButton={true}
                            buttonClicked={() => handleOpenCU(false)}
                            buttonTitle="Tạo trang trại"
                        />
                    }
                </div>
                <EnhancedTableToolbar numSelected={selected.length} page={farms.total} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={farms.items.length}
                        />
                        <TableBody>
                            {currentRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <StyledTableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}>
                                        {/* <StyledTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                className={classes.checkbox}
                                                onClick={(event) => handleClick(event, row.id)}
                                            />
                                        </StyledTableCell> */}
                                        <StyledTableCell align="right">{(currentPage - 1) * rowsPerPage + index + 1}</StyledTableCell>
                                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.address}</StyledTableCell>
                                        {/* {row.lowerTemperature !== 0 ?
                                            <StyledTableCell align="right" style={{ width: 150 }}>{row.lowerTemperature}</StyledTableCell>
                                            :
                                            <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        }
                                        {
                                            row.lowerHumidity !== 0 ?
                                                <StyledTableCell align="right" style={{ width: 150 }}>{row.lowerHumidity}</StyledTableCell>
                                                :
                                                <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        }
                                        {
                                            row.pressure !== 0 ?
                                                <StyledTableCell align="right" style={{ width: 150 }}>{row.pressure}</StyledTableCell>
                                                :
                                                <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        } */}
                                        <StyledTableCell align="right">{row.farmType}</StyledTableCell>
                                        <StyledTableCell align="right">{row.managerEmail}</StyledTableCell>
                                        {/* <StyledTableCell align="right">
                                            {user.defaultFarm?.id == row.id && (
                                                <div>
                                                    <Button
                                                        onClick={cancelDefaultFarm}
                                                        variant="contained"
                                                        className={classes.btnCanel}>
                                                        Huỷ mặc định
                                                    </Button>
                                                </div>
                                            )}
                                            {user.defaultFarm?.id != row.id && (
                                                <div>
                                                    <Button
                                                        onClick={() => setDefaultFarm(row.id)}
                                                        variant="contained"
                                                        className={classes.btnDone}>
                                                        Đặt làm mặc định
                                                    </Button>
                                                </div>
                                            )}
                                        </StyledTableCell> */}
                                        <StyledTableCell align="right" style={{ width: 150 }}>
                                            <Tooltip title="Sửa">
                                                <IconButton aria-label="edit" onClick={() => handleOpenCU(true, row)}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            {user?.role !== 'MANAGER' &&
                                                <Tooltip title="Xoá">
                                                    <IconButton aria-label="delete" onClick={() => handleOpenDelete(row)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    className={classes.tablePagination}
                    count={Math.ceil(farms.items.length / rowsPerPage)}
                    onChange={handleChangePage}
                />
                <Modal
                    open={openCU}
                    onClose={handleCloseCU}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentCreate} style={{ width: '45%' }}>
                        <ModalHeader
                            title={isUpdate ? 'Cập nhật trang trại' : 'Tạo trang trại'}
                            closeButton={handleCloseCU}
                        />
                        <FarmCreate
                            onClose={handleCloseCU}
                            onRefresh={getAllFarms}
                            isUpdate={isUpdate}
                            farmData={farmSelected}
                        />
                    </div>
                </Modal>
                {/* <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentAlert}>
                        <CustomAlert
                            title={titleDelete}
                            okButton={doDelete}
                            cancelButton={handleCloseDelete}
                        />
                    </div>
                </Modal> */}
                <ModalAntd
                    title="Xóa trang trại"
                    visible={isShowDeleteModal}
                    footer={null}
                    onCancel={() => setIsShowDeleteModal(false)}
                >
                    <p>Bạn có chắc chắn muốn xóa trang trại này?</p>
                    <div className="modal-footer" style={{ textAlign: 'end' }}>
                        <ButtonAntd onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </ButtonAntd>
                        <ButtonAntd type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Đồng ý
                        </ButtonAntd>
                    </div>


                </ModalAntd>
            </Paper>
        </div>
    );
};

export default Farm;
