import React, { FC, useEffect } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import * as apiPig from '@api/pig';
import pigImage from '@assets/pig-item.png';

import { ILoginUser } from '@type/user';
import { IDetailPig } from '@type/pig';

import Chart from '@components/pigs/chart-activity';
import DayActivity from '@components/pigs/day-activity';

interface IDetailPigProps {
    dataRenderProps: {
        pigId: string,
        pigAIId?: string,
        user: ILoginUser
    }
    lastUpdate?: string
}

const DetailPig: FC<IDetailPigProps> = ({ dataRenderProps, lastUpdate }: IDetailPigProps) => {
    const { pigId, user } = dataRenderProps;
    const [detail, setDetail] = React.useState<IDetailPig>();
    const [warning, setWarning] = React.useState<boolean>(false);
    React.useEffect(() => {
        (async () => {
            const detailPig = await apiPig.detail(pigId) as IDetailPig;
            setDetail(detailPig);
        })()
    }, []);

    useEffect(() => {
        if (detail?.healthStatus?.name === 'Bình thường') {
            setWarning(false);
        } else {
            setWarning(true);
        }
    }, [detail]);

    return (
        <>
            {
                !detail ? (
                    <div>
                        <ScaleLoader color={"#001AEE"} loading={true} />
                    </div>
                ) : (
                    <div id='detail-main'>
                        <div className='detail-content'>
                            <div id="header-detail">
                                <img src={pigImage} alt="" className='detail-avatar' />
                                <div id="desc">
                                    <p id='pig-id'>pig-{detail.pigAIId}</p>

                                    <div id={'health-status'} >Cảnh báo sức khỏe:<b className={warning ? 'warning' : 'baka'}>{detail?.healthStatus?.name}</b> </div>

                                    {/* <div id='pig-info'>
                                    <div className="desc-content">
                                        <label htmlFor="gender">Giới tính: </label>
                                        <span id='gender'>-- </span>
                                    </div>

                                    <div className="desc-content">
                                        <label htmlFor="lifeTime">Tuổi: </label>
                                        <span id='lifeTime'>-- </span>
                                    </div>

                                    <div className="desc-content">
                                        <label htmlFor="weight">Trọng lượng: </label>
                                        <span id='weight'>--</span>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            <DayActivity
                                penId={detail.pen?.id || user.defaultFarm.id}
                                pigAIId={detail.pigAIId}
                                lastUpdate={lastUpdate}
                            />
                        </div>
                        <div className='chart-detail'>
                            <Chart penId={detail.pen?.id || user.defaultFarm.id} pigAIId={detail.pigAIId} />
                        </div>
                    </div>
                )
            }
        </>
    )
};

export default DetailPig;
