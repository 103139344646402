/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { RenderValidationError } from '@components/errors/validation';
import { Table, DatePicker, message, Button, Input, Modal, notification } from 'antd';

import { IInitialOrderInput } from '@type/food-order';
import { IFoodSupplierData } from '@type/food-supplier';

import { apiFoodSupplier } from '@api/food-supplier';
import * as foodOrderApi from '@api/food-order';

import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';
import { UtilCollections } from '@utils/collections';

import { choosingColumns, choosingColumnsPharmaceutical } from '../../food-order/food-order-columns';

import { IFoodConsumeCreateComponent, EOrderType } from '@type/food-order';
import openNotification, { NotificationType } from '../../common/notification/Notification';
import axios from 'axios';
import { useDispatchRoot } from '../../../redux/store';
import { setCountClickSaveFoodOrder } from '../../../redux/controller/farm.slice';

const PharmaceuticalOrderCreate: FC<IFoodConsumeCreateComponent> = ({
    refreshAfterAction,
    toggleModal,
    order,
    data,
    errors: inputErrors,
}: IFoodConsumeCreateComponent) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const { doOrder, onChangeInputData, choosingItems } = order;
    const { initialData, foods } = data;

    const [suppliers, setSuppliers] = React.useState<IFoodSupplierData[]>([]);
    const [foodLst, setFoodLst] = React.useState<any>(foods);
    const dispatch = useDispatchRoot();

    const [nameModal, setNameModal] = React.useState<any>('');
    const [dateModal, setDateModal] = React.useState<any>(null);
    const [foodSupplier, setFoodSupplier] = React.useState<any>('');
    const [orderType, setOrderType] = React.useState<any>('');
    const [checkValidate, setCheckValidate] = React.useState<any>(false);
    const [disableFoodSupplier, setDisableFoodSupplier] = React.useState<any>(false);
    const [showForm, setShowForm] = React.useState<any>(true);
    const [isShowNotiModal, setIsShowNotiModal] = React.useState<any>(false);
    const [valueSubmit, setValueSubmit] = React.useState<any>({});


    useEffect(() => {
        orderType && setDisableFoodSupplier(true);

        if (nameModal && foodSupplier && orderType) {
            setCheckValidate(true);
        }
        else {
            setCheckValidate(false);
        }
    }, [nameModal, dateModal, foodSupplier, orderType]);

    React.useEffect(() => {
        getSuppliers();

        console.log('initialData', initialData);


    }, []);

    useEffect(() => {
        console.log('foodSupplier', foodSupplier);
        if (foodSupplier) {
            axios.get(`https://sit.api.pigman.com.vn/pharmaceutical/pharmaceuticalSupplier/${foodSupplier}?offset=0&size=1000`).then((res) => {
                console.log('res', res.data.data.items);
                setFoodLst(res.data.data.items);
            });
        }
    }, [foodSupplier]);

    const getSuppliers = async () => {

        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm.id,
        };
        await axios.post(`https://sit.api.pigman.com.vn/pharmaceuticalSuppliers/farm`, params)
            .then((res: any) => {
                console.log(res.data.data.items);
                setSuppliers(res.data.data.items);
            })
    };

    const createOrder = async (values: IInitialOrderInput) => {
        values.pharmaceuticalOrderItems = choosingItems.map((v: any) => ({
            pharmaceuticalId: v.id,
            quantity: v.quantity,
            // price: v.price,
            // subTotal: v.subTotal,
            expirationDate: v.expirationDate || moment(new Date(), 'YYYY-MM-DD'),
        }));
        values.totalPrice = 0;

        dateModal ? values.date = moment(dateModal, 'DD/MM/YYYY').format('YYYY-MM-DD') : values.date = moment(new Date()).format('YYYY-MM-DD');

        console.log('values', values);

        await axios.post(`https://sit.api.pigman.com.vn/pharmaceuticalOrder`, values)
            .then((res) => {
                refreshAfterAction();
            })
        // .then((res) => {
        //     notification.success({
        //         message: 'Thông báo',
        //         description: 'Tạo đơn nhập thành công',
        //     });
        // })
        // .catch((err) => {
        //     notification.error({
        //         message: 'Thông báo',
        //         description: 'Tạo đơn nhập thất bại',
        //     });
        // });


        // foodOrderApi.createOrder(values).then(() => {
        //     message.success('Tạo mới thành công');
        //     refreshAfterAction();
        // });
    };

    const schema = Yup.object().shape({
        // foodSupplierId: Yup.string().required('Vui lòng chọn nhà cung cấp'),
        // date: Yup.string().required('Trường này bắt buộc'),
        // name: Yup.string().required('Trường này bắt buộc'),
    });

    // const handleNotiSubmit = async () => {
    //     delete valueSubmit.foodSupplierName;
    //     console.log('valueSubmit', valueSubmit);
    //     await createOrder(valueSubmit);
    //     openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo đơn nhập thành công.`);
    //     toggleModal(false)();

    //     // dispatch(setCountClickSaveFoodOrder());
    //     // window.location.reload()

    // }

    const handleCancel = () => {
        setIsShowNotiModal(false);
    }

    const doSubmit = async (values: IInitialOrderInput, actions: any) => {

        if (choosingItems.length === 0) {
            notification.error({
                message: 'Thông báo',
                description: 'Chưa chọn thức ăn',
            } as any);
            return;
        }
        delete valueSubmit.foodSupplierName;
        const req = {
            ...values,
            name: nameModal,
            pharmaceuticalSupplierId: foodSupplier,
        }
        await createOrder(req);
        openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo đơn nhập thành công.`);
        toggleModal(false)();
        // setIsShowNotiModal(true);
    };
    // Define the disabledDate function
    const disabledDate = (current: any) => {
        // Disable dates that are after the current date
        return current && current > moment().endOf('day');
    };
    return (
        <>
            <Formik initialValues={initialData} onSubmit={doSubmit} validationSchema={schema} enableReinitialize>
                {({ errors, touched, isSubmitting, setFieldValue }) => (
                    <Form className="log-form" >
                        <div className="log-field">
                            <label htmlFor="name" className="log-label">
                                Tên người nhập kho <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <Input
                                    name='name'
                                    id='name'
                                    className={`log-input`}
                                    placeholder={'Tên người nhập kho'}
                                    onChange={(e) => {
                                        setFieldValue('name', e.target.value)
                                        setNameModal(e.target.value)
                                    }}
                                />
                                {/* <Field
                                name="name"
                                className={`log-input`}
                                id="name"
                                placeholder={'Tên người nhập kho'}
                            // value={initialData.name}
                            /> */}
                                <ErrorMessage component={RenderValidationError} name="name" />
                            </div>
                        </div>

                        <div className="log-field">
                            <label htmlFor="date" className="log-label">
                                Ngày nhập <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <>
                                    <DatePicker
                                        name="date"
                                        className={`log-input`}
                                        id="date"
                                        placeholder={'Ngày'}
                                        onChange={
                                            (date, dateString) => {
                                                setFieldValue('date', dateString)
                                                setDateModal(dateString)
                                            }
                                        }

                                        format={DATE_FORMAT}
                                        defaultValue={
                                            moment(
                                                initialData.date ? initialData.date : new Date(),
                                                DATE_FORMAT,
                                            )}
                                        disabledDate={disabledDate}
                                    />
                                    <ErrorMessage component={RenderValidationError} name="date" />
                                </>
                            </div>
                        </div>

                        <div className="log-field">
                            <label htmlFor="foodSupplierId" className="log-label">
                                Hãng sản xuất <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <Field
                                    as="select"
                                    name="foodSupplierId"
                                    className={'log-input'}
                                    onChange={(e: any) => {
                                        setFieldValue('foodSupplierId', e.target.value);
                                        setFoodSupplier(e.target.value);
                                    }}
                                    placeholder={'Chọn hãng sản xuất'}
                                    disabled={disableFoodSupplier}
                                >
                                    {foodSupplier === '' &&
                                        <option value="" >Chọn hãng sản xuất</option>
                                    }
                                    {suppliers.map((v, i) => (
                                        <option key={i} value={v.id}>
                                            {v.name}
                                        </option>
                                    ))}
                                </Field>

                                <ErrorMessage component={RenderValidationError} name="foodSupplierId" />
                            </div>
                        </div>

                        <div className="log-field">
                            <label htmlFor="orderedItems" className="log-label">
                                Thuốc/vắc xin <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <Field
                                    disabled={foodLst.length === 0}
                                    as="select"
                                    name="orderedItems"
                                    className={'log-input'}
                                    onChange={(e: any) => {
                                        console.log(e.target.value);

                                        doOrder(e.target.value, EOrderType.PUT_ON_ORDERED)();
                                        setOrderType(e.target.value);
                                    }}
                                    defaultChecked={''}
                                    multiple={false}
                                >
                                    {orderType === '' &&
                                        <option value="" >Chọn thuốc/vắc xin</option>
                                    }
                                    {foodLst.map((v: any) => {
                                        return (
                                            <option key={v.id} value={v.id}>
                                                {v.name}
                                            </option>
                                        );
                                    })}
                                    {foodLst.length === 0 && <option value="">Đã hết thuốc/vắc xin chọn</option>
                                    }
                                </Field>

                                <ErrorMessage component={RenderValidationError} name="orderedItems" />
                            </div>
                        </div>

                        <div className="log-field">
                            <label className="log-label" >Thuốc/vắc xin đã chọn</label>
                            <div className="control has-icon-right">
                                <Table
                                    columns={choosingColumnsPharmaceutical(onChangeInputData, doOrder, inputErrors)}
                                    dataSource={choosingItems}
                                    pagination={false}
                                    size="small"
                                    locale={{ emptyText: 'Chưa chọn thuốc/vắc xin' }}
                                />
                            </div>
                        </div>

                        <div className="log-field button-submit">
                            <span />
                            {checkValidate ?
                                <div >
                                    <Button onClick={
                                        () => {
                                            toggleModal(false)();
                                        }
                                    }>
                                        Hủy bỏ
                                    </Button>
                                    <Button className='btn-submit-form' type='primary' htmlType='submit' >
                                        Lưu
                                    </Button>
                                </div>
                                :
                                <div >
                                    <Button onClick={
                                        () => {
                                            toggleModal(false)();
                                        }
                                    }>
                                        Hủy bỏ
                                    </Button>
                                    <Button className='btn-submit-form' disabled type='primary' htmlType='submit' >
                                        Lưu
                                    </Button>
                                </div>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
            {/* <Modal
                visible={isShowNotiModal}
                title='Thông báo'
                onCancel={handleCancel}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                <div>Bạn có chắc chắn muốn lưu lại thông tin của đơn nhập này? <br /> Khi bạn đã lưu sẽ không thể thay đổi hay xóa được đơn nhập</div>
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancel}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleNotiSubmit}>
                        Xác nhận
                    </Button>
                </div>
            </Modal> */}
        </>
    );
};

export default PharmaceuticalOrderCreate;
