/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState } from 'react';
import './food-consume.scss';
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import PageHeader from '@components/common/page-header/page-header';
import { useToggleModal } from '@elements/modal/modal';

import StepModal from '@components/food-consume/step/modal';
import DetailModal from '@components/food-consume/detail';

import * as farmFoodConsumeApi from '@api/farm-food-consume';
import farmServices from '@services/farm';
import { Breadcrumb, Button, message, notification } from 'antd';

import { ICommonListResponse, IPagination } from '@type/common';
import { UtilPage } from '@utils/page';

import { Context } from '@components/context/context';

import { UtilCollections } from '@utils/collections';
import axios from 'axios';
import { MdEdit } from 'react-icons/md';
import { Modal as ModalAntd } from 'antd';
import EditFoodConsume from './edit-food-consume';
import { IoIosWarning } from 'react-icons/io';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'id', numeric: false, disablePadding: true, label: 'STT' },
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Ngày xuất' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Tên người xuất' },
    { id: 'lstPenFoodConsume', numeric: false, disablePadding: false, label: 'Số lượng chuồng được phát thức ăn' },
    // { id: 'createdBy', numeric: false, disablePadding: false, label: 'Tên người nhập' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#001cee18',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#001cee18',
            },
        },
    }),
)(TableRow);

const FoodConsume: FC = () => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const [isShowModal, toggleModal] = useToggleModal();
    const [isShowDetailModal, toggleDetailModal] = useToggleModal();

    const [foodConsumeList, setFoodConsumeList] = React.useState<ICommonListResponse>({ items: [], total: 0 });
    const [searchInput, setSearchInput] = React.useState<IPagination>({ offset: 0, size: 10 });
    const [foodConsumeId, setFoodConsumeId] = React.useState<string>('');
    const [addSuccessAt, setAddSuccessAt] = React.useState(new Date());
    const [isShowEditModal, setIsShowEditModal] = useState<boolean>(false);
    const context = React.useContext(Context);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [warning, setWarning] = React.useState(false);

    React.useEffect(() => {
        fetchData();
    }, [addSuccessAt, context.changeLocation.isChangeLocation]);

    const fetchData = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/foodConsume?farmId=${currentFarm?.id}&size=1000`)
            .then((res: any) => {
                console.log(res.data.data[0]);
                setFoodConsumeList(res.data.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const refreshData = () => {
        fetchData();
    }

    const fetchListAfterAdd = () => {
        setAddSuccessAt(new Date());
    };

    const removeFoodConsume = (item: any) => () => {
        setIdDelete(item.id);

        setIsShowDeleteModal(true);
        // farmFoodConsumeApi
        //     .removeFoodConsume(foodConsumeId)
        //     .then((res) => {
        //         const dataItems = foodConsumeList.items.filter((v: any) => v.id !== foodConsumeId);

        //         setFoodConsumeList({ ...foodConsumeList, items: dataItems });

        //         notification.success({
        //             message: 'Xóa thành công',
        //         } as any);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         notification.error({
        //             message: 'Xóa thất bại',
        //         } as any);
        //     });
    };

    // const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    //     const { size } = searchInput;

    //     const offset = value === 1 ? 0 : size * value - size + 1;

    //     setSearchInput({ ...searchInput, offset });
    // };

    const classes = useStyles();

    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = foodConsumeList.items.slice(startIndex, endIndex);


    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPage(newPage);
    };

    const handleFinishDeleteForm = () => {
        farmFoodConsumeApi
            .removeFoodConsume(idDelete)
            .then((res) => {
                const dataItems = foodConsumeList.items.filter((v: any) => v.id !== idDelete);

                setFoodConsumeList({ ...foodConsumeList, items: dataItems });
                setIsShowDeleteModal(false);
                notification.success({
                    message: 'Xóa thành công',
                } as any);
            })
            .catch((err) => {
                console.log(err);
                notification.error({
                    message: 'Xóa thất bại',
                } as any);
            });
    }

    return (
        <div className={`${classes.root} main-content`}>
            <Paper className={classes.paper} elevation={0}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div className='main-breadcrumb'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <span>Thức ăn</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Danh sách thức ăn xuất kho</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <PageHeader
                        pageTile="Quản lý xuất kho"
                        isButton={true}
                        buttonTitle="Thêm mới"
                        buttonClicked={toggleModal(true)}
                    />
                </div>

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <StyledTableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'right' : 'center'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}>
                                        {headCell.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {foodConsumeList ? (
                                <>
                                    {currentRows.map((row: any, index: number) => {
                                        return (
                                            <StyledTableRow hover tabIndex={-1} key={row.id}>
                                                <StyledTableCell align="right">{(currentPage - 1) * rowsPerPage + index + 1}</StyledTableCell>

                                                <StyledTableCell>
                                                    {UtilCollections.convertDateToDMY(
                                                        UtilCollections.formatClientDate(row.date.split('T')[0]),
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.name}</StyledTableCell>
                                                <StyledTableCell align="right">{row.quantityOfPens ? row.quantityOfPens : ''}</StyledTableCell>
                                                {/* <StyledTableCell align="right">{row.}</StyledTableCell> */}
                                                {/* <StyledTableCell align="right">{row.user?.fullName}</StyledTableCell> */}
                                                <StyledTableCell align="right">
                                                    <Tooltip title="Chi tiết">
                                                        <IconButton aria-label="rollback">
                                                            <EyeIcon
                                                                onClick={() => {
                                                                    setFoodConsumeId(row.id);
                                                                    toggleDetailModal(true)();
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Sửa">
                                                        <IconButton aria-label="rollback">
                                                            <MdEdit onClick={
                                                                () => {
                                                                    setFoodConsumeId(row.id);
                                                                    setIsShowEditModal(true);
                                                                }
                                                            }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Xóa">
                                                        <IconButton aria-label="rollback">
                                                            <DeleteIcon onClick={removeFoodConsume(row)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {row.warning &&
                                                        <Tooltip title="Đơn này có khả năng sai số khi sửa hoặc xóa">
                                                            <IconButton >
                                                                <IoIosWarning style={{ fontSize: 20, color: 'red' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    {/* {emptyRows > 0 && (
                                                <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                    <StyledTableCell colSpan={6} />
                                                </StyledTableRow>
                                            )} */}
                                </>
                            ) : (
                                ''
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    className={classes.tablePagination}
                    count={Math.ceil(foodConsumeList.items.length / rowsPerPage)}
                    onChange={handleChangePage}
                />

                {isShowModal && (
                    <StepModal
                        toggleModal={toggleModal}
                        isShowModal={isShowModal}
                        fetchListAfterAdd={fetchListAfterAdd}
                    />
                )}

                {isShowDetailModal && (
                    <DetailModal
                        toggleModal={toggleDetailModal}
                        isShowModal={isShowDetailModal}
                        foodConsumeId={foodConsumeId}
                    />
                )}

                {isShowEditModal && (
                    <EditFoodConsume
                        toggleModal={() => setIsShowEditModal(false)}
                        isShowModal={isShowEditModal}
                        foodConsumeId={foodConsumeId}
                        refreshData={refreshData}
                    />
                )}
                <ModalAntd
                    title="Xóa xuất kho"
                    visible={isShowDeleteModal}
                    footer={null}
                    onCancel={() => setIsShowDeleteModal(false)}
                >
                    <p>Bạn có chắc chắn muốn xóa xuất kho này?</p>
                    {warning && <i >Việc này có thể làm số lượng trong kho bị lỗi!</i>}
                    <div className="modal-footer" style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Đồng ý
                        </Button>
                    </div>


                </ModalAntd>
            </Paper>
        </div>
    );
};

export default FoodConsume;
