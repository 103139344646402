/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { DatePicker } from 'antd';
import ScaleLoader from "react-spinners/ScaleLoader";
import { DATE_FORMAT } from '@constants/config';

import { UtilCollections } from '@utils/collections';

import { searchBeHaviour } from '@api/behaviour';
import { IBeHaviour } from '@type/behaviour';
import { UtilDate } from '../../utils/date';

const { RangePicker } = DatePicker;
import { Pie } from 'react-chartjs-2';

const dataLineChart: any = {
    labels: [],
    datasets: [
        {
            label: 'Đi',
            data: [],
            fill: false,
            borderColor: '#DFA82C',
            tension: 0.1
        },
        {
            label: 'Đứng',
            data: [],
            fill: false,
            borderColor: '#2CD952',
            tension: 0.1
        },
        {
            label: 'Nằm',
            data: [],
            fill: false,
            borderColor: '#D92CA8',
            tension: 0.1
        },
        {
            label: 'Ăn',
            data: [],
            fill: false,
            borderColor: '#4fa0dd',
            tension: 0.1
        },
        {
            label: 'Không xác định',
            data: [],
            fill: false,
            borderColor: '#ff1f00',
            tension: 0.1
        }
    ]
};

// const optionsLineChart = {
//     // plugins: {
//     //     legend: {
//     //         display: false
//     //     }
//     // },
//     // elements: {
//     //     point: {
//     //         radius: 0
//     //     }
//     // }
//     scales: {
//         yAxes: [{
//             scaleLabel: {
//                 display: true,
//                 labelString: 'Y text'
//             }
//         }],
//         xAxes: [{
//             scaleLabel: {
//                 display: true,
//                 labelString: 'X text'
//             }
//         }]
//     }
// };

// const tmp = {
//     scales: {
//         yAxes: [
//             {
//                 scaleLabel: {
//                     display: true,
//                     labelString: "Y text"
//                 }
//             }
//         ],
//         xAxes: [
//             {
//                 scaleLabel: {
//                     display: true,
//                     labelString: "Y text"
//                 }
//             }
//         ]
//     }
// };

const tmp = {
    scales: {
        y: {
            max: 100,
            min: 0,
            ticks: {
                stepSize: 25,
                callback: function (value: number) {
                    return `${value}%`
                }
            }
        }
    }
};

interface IChartActivityProps {
    penId: string,
    pigAIId: string
}

const ChartActivity: FC<IChartActivityProps> = ({ penId, pigAIId }: IChartActivityProps) => {
    const [chartData, setChartData] = React.useState<any>(dataLineChart);
    const [inputSearch, setInputSearch] = React.useState<any>({ startDate: String, endDate: String });

    React.useEffect(() => {
        try {
            getChartData();
        } catch (err) {

        }
    }, [inputSearch]);

    const calculateDays = UtilCollections.calculateBetweenTwoDate(inputSearch.startDate, inputSearch.endDate);

    const calculatePercent = (data: number, object: any): number => {
        const sum = object.eatingTimes + object.lieDownTimes + object.sleepingTimes + object.standingTimes + object.runningTimes + object.unspecified;
        const res = parseFloat((data / sum * 1.0 * 100).toFixed(2))
        return res ? res : 0;
    }

    const getChartData = async () => {
        if (+calculateDays <= 0) return;

        const { startDate, endDate } = inputSearch;

        const chart = await searchBeHaviour({
            startDate: UtilCollections.formatDateSearchBeHaviour(startDate),
            endDate: UtilDate.getEndOfDate(endDate).toISOString(),
            penId,
            pigAIId
        }) as IBeHaviour[];
        console.log("Chart")
        console.log(chart)

        const initialChartData: any = {
            labels: [],
            datasets: [
                {
                    label: 'Đi',
                    data: [],
                    fill: false,
                    borderColor: '#DFA82C',
                    tension: 0.1
                },
                {
                    label: 'Đứng',
                    data: [],
                    fill: false,
                    borderColor: '#2CD952',
                    tension: 0.1
                },
                {
                    label: 'Nằm',
                    data: [],
                    fill: false,
                    borderColor: '#D92CA8',
                    tension: 0.1
                },
                {
                    label: 'Ăn',
                    data: [],
                    fill: false,
                    borderColor: '#4fa0dd',
                    tension: 0.1
                },
                {
                    label: 'Không xác định',
                    data: [],
                    fill: false,
                    borderColor: '#ff1f00',
                    tension: 0.1
                }
            ]
        };

        for (let i = 0; i < chart.length; i++) {
            const currentChartData = chart[i];
            initialChartData.labels.push(currentChartData.date);
            initialChartData.datasets[0].data.push(calculatePercent(currentChartData.runningTimes, currentChartData));
            initialChartData.datasets[1].data.push(calculatePercent(currentChartData.standingTimes, currentChartData));
            initialChartData.datasets[2].data.push(calculatePercent(currentChartData.lieDownTimes, currentChartData));
            initialChartData.datasets[3].data.push(calculatePercent(currentChartData.eatingTimes, currentChartData));
            initialChartData.datasets[4].data.push(calculatePercent(currentChartData.unspecified, currentChartData));
        }

        setChartData(initialChartData);
    }

    // const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
    const chooseRangeDate: any = (date: string, dateString: string) => setInputSearch({ startDate: dateString[0], endDate: dateString[1] });


    const [activityData, setActivityData] = React.useState<IBeHaviour[] | any>([{ eatingTimes: 0, lieDownTimes: 0, sleepingTimes: 0, standingTimes: 0, runningTimes: 0, unspecified: 0 }]);


    React.useEffect(() => {
        try {
            getBeHaviours();
            const intervalGetBehaviour = setInterval(getBeHaviours, 10000);
            return () => {
                clearInterval(intervalGetBehaviour);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getBeHaviours = async () => {
        const start_ = new Date()
        start_.setDate(start_.getDate());
        start_.setHours(0);
        start_.setMinutes(0);
        start_.setSeconds(0);
        const end_ = new Date()
        end_.setDate(end_.getUTCDate());
        const dayActivity = await searchBeHaviour({
            startDate: start_.toISOString(),
            endDate: end_.toISOString(),
            penId,
            pigAIId,
        }) as IBeHaviour[];

        setActivityData(dayActivity);
    }


    const [rawData, setRawData] = React.useState<any>([]);
    const [data, setData] = React.useState<any>([]);
    useEffect(() => {
        console.log(activityData);
        if (activityData) {
            const tmp = [
                activityData[0].runningTimes,
                activityData[0].standingTimes,
                activityData[0].lieDownTimes,
                activityData[0].eatingTimes,
                activityData[0].unspecified,
            ]
            setRawData(tmp);
        }
    }, [activityData])

    useEffect(() => {
        const total = rawData.reduce((sum: any, value: any) => sum + value, 0);
        const percentages = rawData.map((value: number) => ((value / total) * 100).toFixed(2));

        const tmp = {
            labels: ['Đi', 'Đứng', 'Nằm', 'Ăn', 'Không xác định'],
            datasets: [
                {
                    data: percentages,
                    backgroundColor: ['#af48ff', '#4b9a7b', '#2cabd4', '#efb585', '#cccccc'],
                    hoverBackgroundColor: ['#af48ff', '#4b9a7b', '#2cabd4', '#efb585', '#cccccc'],
                },
            ],
        };
        setData(tmp);
    }, [rawData])
    return (
        <div id='wrapper-chart'>
            {/* {chartData ? (
                <>
                    <div id="time-report">
                        Thời gian báo cáo:
                        <div id='time-report__input'>
                            {`${+calculateDays || 0} ngày`}

                            <RangePicker
                                bordered={false}
                                onChange={chooseRangeDate}
                                format={DATE_FORMAT}
                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                style={{
                                    position: 'relative',
                                    zIndex: 1000
                                }}
                            />
                        </div>
                    </div>
                    <Line
                        data={chartData}
                        options={tmp}
                    />
                </>
            ) : (<ScaleLoader color={"#001AEE"} loading={true} />)} */}

            {/* <div style={{ width: 400 }}> */}
            <Pie
                data={data}
                options={{
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem: any, data: any) => {
                                const dataset = data.datasets[tooltipItem.datasetIndex];
                                const percent = dataset.data[tooltipItem.index];
                                return `${data.labels[tooltipItem.index]}: ${percent}%`;
                            },
                        },
                    },
                }}
            />

            {/* </div> */}
        </div>
    )
}

export default ChartActivity;