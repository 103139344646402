/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { apiFarm } from '@api/farm';
import { IFarmError } from '@type/error';
import userService from '@services/user';
import { IFarmData } from '@type/farm';
import openNotification, { NotificationType } from '../common/notification/Notification';
import { Input, Slider, Switch, notification } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './style.farm.scss'
import axios from 'axios';
import { CFG_BASE_URL } from '../../constants/config';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet's CSS
type FarmCreateProps = {
    onClose: any;
    onRefresh: any;
    isUpdate: boolean;
    farmData?: IFarmData;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        farm: {
            marginTop: '10%',
            marginBottom: '10%'
        },
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "#001cee18 !important"
        },
        inputText: {
            fontWeight: 600,
            fontSize: '16px',
            color: '#18191F'
        },
        errMsg: {
            color: 'red'
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white'
        },
        btnDone: {
            backgroundColor: '#001AEE',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        btnCanel: {
            backgroundColor: '#001cee18',
            color: '#001AEE',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginRight: '10px'
        }
    }),
);

const FarmType = [
    {
        value: 'Chưa xác định',
        label: 'Chưa xác định',
    },
    {
        value: 'Quy mô nông hộ (dưới 10 con)',
        label: 'Quy mô nông hộ (dưới 10 con)',
    },
    {
        value: 'Quy mô nhỏ (10 - 30 con)',
        label: 'Quy mô nhỏ (10 - 30 con)',
    },
    {
        value: 'quy mô vừa (30 - 300 con)',
        label: 'quy mô vừa (30 - 300 con)',
    },
    {
        value: 'Quy mô lớn (300 con trở lên)',
        label: 'Quy mô lớn (300 con trở lên)',
    }
]

// Slider marks
const marksTemp: SliderMarks = {
    30: '30°C',
    50: {
        style: {
            color: '#f50',
        },
        label: <strong>50°C</strong>,
    },
};
// Slider marks
const marksHum: SliderMarks = {
    20: '20%',
    80: {
        style: {
            color: '#f50',
        },
        label: <strong>80%</strong>,
    },
};
const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: '#001cee18 !important',
    }),
};
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const apiKey = 'AIzaSyD8iDAU0vXZ22nNi0m2riSqrSEftg4UV6k';

const FarmCreate: FC<FarmCreateProps> = ({ onClose, onRefresh, isUpdate, farmData }: FarmCreateProps) => {
    const classes = useStyles();
    const [name, setName] = useState(farmData?.name);
    const [address, setAddress] = useState(farmData?.address);
    const [temperature, setTemperature] = useState<any>(farmData?.lowerTemperature); // Nhiệt độ
    const [humidity, setHumidity] = useState<any>(farmData?.lowerHumidity); // Độ ẩm
    const [farmType, setFarmType] = useState<any>({ label: farmData?.farmType, value: farmData?.farmType }); // Loại trang trại
    const [ownerId, setOwnerId] = useState("");
    const [emailManager, setEmailManager] = useState(farmData?.managerEmail ? farmData.managerEmail : ''); // Email người quản lý
    const [passwordManager, setPasswordManager] = useState(""); // Mật khẩu người quản lý
    const [err, setErr] = useState<IFarmError | any>();
    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [lstGateWay, setLstGateWay] = useState<any[]>([]); // Loại trang trại
    const [farmGateWay, setFarmGateWay] = useState<any>({
        label: farmData?.gatewayCode,
        value: farmData?.id,
    }); // Loại trang trại
    const history = useHistory();
    const user = userService.get();



    useEffect(() => {
        console.log('farmData', farmData);
        getAllGateWay();
        setOwnerId(user.id);
    }, []);

    const getAllGateWay = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/getGateway/all`)
            .then(res => {
                console.log(res.data.data);
                const tmp = res.data.data.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.code
                    }
                });
                setLstGateWay(tmp);
            })
            .catch(err => {
                console.log(err.response.data.message);
            })
    }

    const handleInputChangeEventName = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        if (value.trim() === '' || value === undefined || value === null) {
            setErr({ ...err, [name]: 'Tên trang trại không được để trống' });
        }
        else {
            setErr({ ...err, [name]: '' })
        }
        setName(value);
    };

    const handleInputChangeAddress = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        // if (value.trim() === '' || value === undefined || value === null) {
        //     setErr({ ...err, [name]: 'Tên trang trại không được để trống' });
        // }
        // else {
        //     setErr({ ...err, [name]: '' })
        // }
        setAddress(value);
    };

    const handleInputChangeEventTemperature = (evt: ChangeEvent<HTMLInputElement>) => {
        setTemperature(evt.target.value);
    }

    const handleInputChangeEventHumidity = (evt: ChangeEvent<HTMLInputElement>) => {
        setHumidity(evt.target.value);
    }

    const handleInputChangeEventEmailManager = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        if (value.trim() !== '' && value !== undefined && value !== null) {
            if (emailRegex.test(value) === false) {
                setErr({ ...err, [name]: 'Email không hợp lệ' });
            }
            else {
                setErr({ ...err, [name]: '' });
            }
        }
        else {
            setErr({ ...err, [name]: '' });
        }

        setEmailManager(evt.target.value);
    }

    const handleInputChangeEventPasswordManager = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        if (value.length < 6) {
            setErr({ ...err, [name]: 'Mật khẩu phải chứa 6 ký tự' });
        }
        else {
            setErr({ ...err, [name]: '' });
        }
        setPasswordManager(evt.target.value);
    }

    const editManager = async (id: string | undefined, req: any, tmpData: any) => {
        changeShare(id ? id : '', 'edit');
        if (isDiablePassword) {
            const tmp = {
                email: emailManager,
                additionalProp1: {}
            }
            await axios.put(`https://sit.api.pigman.com.vn/manager/edit/${id}`, tmp)
                .then(async (res) => {
                    await axios.put(`https://sit.api.pigman.com.vn/farms/${id}`, tmpData)
                    setErr(null);
                    setErrMsg("");
                    openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Cập nhật thông tin trang trại thành công.`);
                    onClose();
                    onRefresh();
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    setErr({ ...err, ['emailManager']: err.response.data.message });
                })
        }
        else {
            if (emailManager !== farmData?.managerEmail && emailManager && passwordManager) {
                console.log(emailManager, passwordManager);

                await axios.put(`https://sit.api.pigman.com.vn/manager/edit/${id}`, req)
                    .then(async (res) => {
                        console.log(res);
                        await axios.put(`https://sit.api.pigman.com.vn/farms/${id}`, tmpData)
                        setErr(null);
                        setErrMsg("");
                        openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Cập nhật thông tin trang trại thành công.`);
                        onClose();
                        onRefresh();
                    })
                    .catch((err) => {
                        console.log(err.response.data.message);
                        setErr({ ...err, ['emailManager']: err.response.data.message });
                    })
            }
            else {
                if (!emailManager && !passwordManager) {
                    await axios.delete(`https://sit.api.pigman.com.vn/manager/${id}`)
                }
                await axios.put(`https://sit.api.pigman.com.vn/farms/${id}`, tmpData)
                setErr(null);
                setErrMsg("");
                openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Cập nhật thông tin trang trại thành công.`);
                onClose();
                onRefresh();
            }
        }
    }

    const doSave = async (): Promise<void> => {
        if (!name || !address || !farmType) {
            notification.error({
                message: 'PEHAVIOR',
                description: 'Vui lòng nhập đầy đủ thông tin trang trại.',

            })
            return;
        }
        const data = {
            name,
            address,
            ownerId,
            phone: '',
            website: '',
            lowerTemperature: temperature ? temperature : 0,
            lowerHumidity: humidity ? humidity : 0,
            upperTemperature: temperature ? temperature : 0,
            upperHumidity: humidity ? humidity : 0,
            gatewayCode: farmGateWay.label ? farmGateWay.label : '',
            additionalProp1: {},
        }

        setIsLoading(true);
        console.log('data', data);

        if (isUpdate) {
            setIsLoading(false);
            if (!emailManager && !passwordManager) {
                setErr({ ...err, ['emailManager']: '' });
                setErr({ ...err, ['passwordManager']: '' });
            }
            if (emailManager && !passwordManager && emailManager !== farmData?.managerEmail && !isDiablePassword) {
                console.log('baka');
                setErr({ ...err, ['passwordManager']: 'Mật khẩu không được để trống' });
                return;
            }
            if (!emailManager && passwordManager) {
                setErr({ ...err, ['emailManager']: 'Email không được để trống' });
                return;
            }
            const req = {
                email: emailManager,
                password: passwordManager,
                additionalProp1: {}
            }
            const tmpData = {
                ...data,
                farmType: farmType.value,
            }

            try {
                editManager(farmData?.id, req, tmpData);
                // window.location.reload();
            } catch (err) {
                if (typeof err === 'string') {
                    setErrMsg(err);
                } else {
                    setErr(err);
                }
                console.log(err);
                setIsLoading(false);
            }
        } else {
            if (!emailManager && !passwordManager) {
                setErr({ ...err, ['emailManager']: '' });
                setErr({ ...err, ['passwordManager']: '' });
            }
            if (emailManager && !passwordManager && !isDiablePassword) {
                setErr({ ...err, ['passwordManager']: 'Mật khẩu không được để trống' });
                return;
            }
            if (!emailManager && passwordManager) {
                setErr({ ...err, ['emailManager']: 'Email không được để trống' });
                return;
            }
            const tmpData = {
                ...data,
                farmType: farmType.value,
                managerEmail: emailManager,
            }
            try {
                createManager(tmpData);
            } catch (err) {
                if (typeof err === 'string') {
                    setErrMsg(err);
                } else {
                    setErr(err);
                }
                setIsLoading(false);
            }
        }
    };

    const handleSelectChange = (selectedOptions: any) => {
        setFarmType(selectedOptions);
    }

    const handleSelectChangeGateWay = (selectedOptions: any) => {
        setFarmGateWay(selectedOptions);
    }

    const createManager = async (tmpData: any) => {
        if (emailManager && passwordManager && emailRegex.test(emailManager) === true) {
            const data = {
                email: emailManager,
                password: passwordManager,
                additionalProp1: {}
            }
            await axios.post(`https://sit.api.pigman.com.vn/manager/register`, data)
                .then(async res => {
                    await axios.post(`${CFG_BASE_URL}/farms/`, tmpData).then(res => {
                        console.log(res);
                        changeShare(res.data.data.id, 'create');
                    })
                    setErr(null);
                    setErrMsg("");
                    onRefresh();
                    openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo trang trại thành công.`);
                    onClose();
                })
                .catch(err => {
                    console.log(err.response.data.message);
                    setErr({ ...err, ['emailManager']: err.response.data.message });
                })
        }
        else {
            await axios.post(`${CFG_BASE_URL}/farms/`, tmpData).then(res => {
                changeShare(res.data.data.id, 'create');
            })
            setErr(null);
            setErrMsg("");
            onRefresh();
            openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo trang trại thành công.`);
            onClose();
            // window.location.reload();
        }
    }

    const [isDiablePassword, setIsDiablePassword] = useState(false);
    const [messageEmail, setMessageEmail] = useState('');
    const handleBlurEmail = async (e: any) => {
        console.log(e.target.value);
        await axios.get(`https://sit.api.pigman.com.vn/manager/checkEmail?email=${e.target.value}`)
            .then(res => {
                console.log(res.data.data);
                const tmp = res.data.data;
                tmp ? setIsDiablePassword(true) : setIsDiablePassword(false);
                tmp ? setMessageEmail('Email này đã đăng ký làm người quản ký. Không cần phải nhập mật khẩu') : setMessageEmail('');
            })
            .catch(err => {
                console.log(err.response.data.message);
            })
    }
    const [isSwitchLocation, setIsSwitchLocation] = useState<boolean>(farmData?.shared ? true : false);
    const onChangeSwitch = (checked: boolean) => {
        setIsSwitchLocation(checked);
    }

    useEffect(() => {
        console.log(farmData?.shared);

    }, [farmData]);


    const changeShare = async (farmId: string, type: string) => {
        console.log(isSwitchLocation);
        console.log(farmData?.shared);

        if (type === 'create' && isSwitchLocation) {
            await axios.put(`https://sit.api.pigman.com.vn/farms/changeShared/${farmId}`)
                .then(res => {
                    console.log(res);
                })
        }
        else if (type === 'edit') {
            if (farmId && (farmData?.shared !== isSwitchLocation)) {
                await axios.put(`https://sit.api.pigman.com.vn/farms/changeShared/${farmId}`)
                    .then(res => {
                        console.log(res);
                    })
            }
        }
    }

    return (
        <div className={classes.farm} style={{ margin: '2em 0' }}>
            <Grid container style={{ height: 450, overflowY: 'scroll' }}>
                <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Tên trang trại <span style={{ color: 'red' }}>*</span></span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <TextField
                            fullWidth={true}
                            id="name"
                            name="name"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập tên trang trại"
                            value={name}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            onChange={handleInputChangeEventName}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-start" ml={1}>
                        <span className={classes.errMsg}>{err?.name}</span>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Địa chỉ <span style={{ color: 'red' }}>*</span></span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <TextField
                            fullWidth={true}
                            id="address"
                            name="address"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập địa chỉ"
                            value={address}

                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            onChange={handleInputChangeAddress}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">

                    </Box>
                    <Box display="flex" justifyContent="flex-start" ml={1}>
                        <span className={classes.errMsg}>{err?.address}</span>
                    </Box>
                    <Box display="flex" justifyContent="flex-start" ml={1}>
                        <span className={classes.errMsg}>{errMsg}</span>
                    </Box>
                </Grid>
                {/* <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Nhiệt độ</span>
                    </Box>
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <TextField
                            fullWidth={true}
                            id="temperature"
                            name="temperature"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập nhiệt độ môi trường nuôi"
                            defaultValue={temperature === 0 ? '' : temperature}
                            inputProps={{
                                min: 30,
                                max: 50
                            }}
                            type='number'
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            onChange={handleInputChangeEventTemperature}
                        />
                    </Box>

                </Grid>
                <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Độ ẩm</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <TextField
                            fullWidth={true}
                            id="humidity"
                            name="humidity"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập độ ẩm môi trường nuôi"
                            defaultValue={humidity === 0 ? '' : humidity}
                            type='number'
                            inputProps={{ min: 50, max: 80, }}
                            onChange={handleInputChangeEventHumidity}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                    </Box>

                </Grid> */}
                <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Quy mô trang trại <span style={{ color: 'red' }}>*</span>
                        </span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} width="100%">
                        <Select
                            name='lstTempPen'
                            options={FarmType}
                            components={makeAnimated()}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Lựa chọn quy mô trang trại"
                            onChange={handleSelectChange}
                            defaultValue={farmType.value ? farmType : { label: 'Lựa chọn quy mô trang trại', value: '' }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                    </Box>

                </Grid>
                {/* <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Mã thiết bị cảm biến môi trường
                        </span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} width="100%">
                        <Select
                            name='lstGateWay'
                            options={lstGateWay}
                            components={makeAnimated()}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Lựa chọn mã gateway"
                            onChange={handleSelectChangeGateWay}
                            defaultValue={farmGateWay.value ? farmGateWay : { label: 'Lựa chọn mã gateway của trang trại', value: '' }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            // menuPortalTarget={document.body}
                            maxMenuHeight={200}
                        />
                    </Box>

                </Grid> */}
                <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Cho phép chia sẻ vị trí</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <Switch className='switch-pig' onChange={onChangeSwitch} defaultChecked={isSwitchLocation} />
                    </Box>
                </Grid>
                {/* <Grid item xs={10} sm={6}>
                    <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                        <span className={classes.inputText}>Bản đồ</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                        <Switch className='switch-pig' onChange={onChangeSwitch} />
                    </Box>
                </Grid> */}
                {user.role !== "MANAGER" &&
                    <>
                        <Grid item xs={10} sm={6}>
                            <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                                <span className={classes.inputText}>Email người quản lý</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                                <TextField
                                    fullWidth={true}
                                    id="emailManager"
                                    name="emailManager"
                                    label=""
                                    variant="outlined"
                                    size="small"
                                    placeholder="Nhập email người quản lý"
                                    value={emailManager}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    onChange={handleInputChangeEventEmailManager}
                                    onBlur={handleBlurEmail}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-start" ml={1}>
                                <span className={classes.errMsg}>{err?.emailManager}</span>
                            </Box>

                        </Grid>
                    </>
                }
                {user.role !== "MANAGER" &&
                    <>
                        <Grid item xs={10} sm={6}>
                            <Box className='lable' display="flex" justifyContent="flex-start" m={1} p={1}>
                                <span className={classes.inputText}>Mật khẩu người quản lý
                                    {(emailManager && err?.emailManager === '' && !isDiablePassword) && <span style={{ color: 'red' }}>*</span>}
                                </span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                                <TextField
                                    disabled={(emailManager === farmData?.managerEmail || isDiablePassword || !emailManager || err?.emailManager) ? true : false}
                                    fullWidth={true}
                                    id="passwordManager"
                                    name="passwordManager"
                                    label=""
                                    variant="outlined"
                                    size="small"
                                    placeholder="Nhập mật khẩu người quản lý"
                                    type=''
                                    value={passwordManager}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    onChange={handleInputChangeEventPasswordManager}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-start" ml={1}>
                                <span className={classes.errMsg}>{err?.passwordManager}</span>
                            </Box>
                            <Box display="flex" justifyContent="flex-start" ml={1}>
                                <span className={classes.errMsg}>{messageEmail}</span>
                            </Box>
                        </Grid>
                    </>
                }
                <Grid item xs={10} sm={6}>
                    <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1}>
                        <Button variant="contained" onClick={onClose} className={classes.btnCanel}>
                            Hủy bỏ
                        </Button>
                        <Button
                            onClick={doSave}
                            variant="contained"
                            className={(name && address) ? classes.btnDone : classes.btnCanel}
                            disabled={(name && address && farmType.value) ? false : true}
                        >

                            {isUpdate ? "Cập nhật" : "Lưu"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FarmCreate;
