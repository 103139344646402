/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Cookies from 'universal-cookie';
import { IFarmData } from '@type/farm';


const keyStorageCurrentFarm = 'currentFarm';
const cookies = new Cookies();

export default {
    getCurrentFarm(): any {
        return cookies.get(keyStorageCurrentFarm);
    },
    setCurrentFarm(currentFarm: any) {
        console.log(currentFarm)
        cookies.set(keyStorageCurrentFarm, currentFarm, { path: '/' })
    },
    removeCurrentFarm(): any {
        cookies.remove(keyStorageCurrentFarm)
    }
}