/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EyeIcon from '@material-ui/icons/RemoveRedEye';

import Modal, { useToggleModal } from '@elements/modal/modal';

import PageHeader from '@components/common/page-header/page-header';
import FoodOrderCreate from '@components/food-order/food-order-modal';

import { apiFoodSupplier } from '@api/food-supplier';
import farmServices from '@services/farm';

import * as foodOrderApi from '@api/food-order';
import { IPagination, ICommonListResponse } from '@type/common';
import { UtilPage } from '@utils/page';

import { EModalType } from '@type/food-order';
import { IFoodSupplierData } from '@type/food-supplier';
import { Breadcrumb, Button, Select, message, notification } from 'antd';

import { Context } from '@components/context/context';
import './food-order.scss';
import axios from 'axios';
import { useSelectorRoot } from '../../redux/store';
import moment from 'moment';
import { Modal as ModalAntd } from 'antd';
import { IoIosWarning } from 'react-icons/io';
interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'id', numeric: false, disablePadding: true, label: 'STT' },
    // { id: 'date', numeric: false, disablePadding: false, label: 'Ngày nhập' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Ngày nhập' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Tên người nhập' },
    { id: 'foodSupplier', numeric: false, disablePadding: false, label: 'Nhà cung cấp' },
    // { id: 'foodSupplierAddress', numeric: false, disablePadding: false, label: 'Địa chỉ nhà cung cấp' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '70%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#001cee18',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#001cee18',
            },
        },
    }),
)(TableRow);

const FoodOrder: FC = () => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const classes = useStyles();
    const [isShowModal, toggleModal] = useToggleModal();

    const [suppliers, setSuppliers] = React.useState<IFoodSupplierData[]>([]);
    const [supplierId, setSupplierId] = React.useState('');
    const [data, setData] = React.useState<ICommonListResponse>({ items: [], total: 0 });
    const [foodOrderId, setFoodOrderId] = React.useState('');
    const [modalType, setModalType] = React.useState<EModalType>(EModalType.CREATE);
    const { countClickSaveFoodOrder } = useSelectorRoot(state => state.farm)

    const [searchInput, setSearchInput] = React.useState<IPagination>({ offset: 0, size: 10 });
    const [addSuccessAt, setAddSuccessAt] = React.useState(new Date());
    const [titleModal, setTitleModal] = React.useState('Thêm mới nhập kho');
    const context = React.useContext(Context);
    const [isShowDeleteModal, setIsShowDeleteModal] = React.useState(false);
    const [idDelete, setIdDelete] = React.useState('');

    const [warning, setWarning] = React.useState(false);


    useEffect(() => {
        fetchData();
    }, [countClickSaveFoodOrder, currentFarm.id]);

    const fetchData = async () => {
        console.log(currentFarm.id);

        await axios.get(`https://sit.api.pigman.com.vn/foodOrder/farm?farmId=${currentFarm.id}&offset=0&size=1000`).then((res) => {
            console.log(res);
            setData(res?.data?.data);
        });
    }

    React.useEffect(() => {
        getSuppliers();
    }, [context.changeLocation.isChangeLocation]);

    React.useEffect(() => {
        if (supplierId !== '') {
            getFoodOrderList();
        }
    }, [supplierId, searchInput, addSuccessAt]);

    React.useEffect(() => {
        if (!isShowModal) {
            setFoodOrderId('');
        }
    }, [isShowModal]);

    const refreshAfterAction = () => {
        setAddSuccessAt(new Date());
        fetchData();
    };

    const getSuppliers = async () => {
        const data = (await apiFoodSupplier.getAll({ size: 200, farmId: currentFarm.id })) as any;
        console.log(data);
        if (data.items) {
            setSuppliers(data.items);
            // setData({ items: [], total: 0 });
        }
    };

    const getFoodOrderList = async () => {
        const fetch = (await foodOrderApi.getFoodOrder(supplierId, searchInput)) as any;
        if (fetch) setData(fetch);
    };

    // const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    //     const { size } = searchInput;
    //     const offset = value === 1 ? 0 : size * value - size + 1;
    //     setSearchInput({ ...searchInput, offset });
    // };

    const toggleOrderModal = (id: string, modalType: EModalType) => () => {
        let title = '';
        switch (modalType) {
            case EModalType.CREATE:
                title = 'Thêm thông tin nhập kho thức ăn';
                break;
            case EModalType.UPDATE:
                title = 'Cập nhật thông tin nhập kho thức ăn';
                break;
            case EModalType.DETAIL:
                title = 'Chi tiết thông tin nhập kho thức ăn';
                break;
            default:
                break;
        }
        setTitleModal(title);
        setFoodOrderId(id);
        setModalType(modalType);
        toggleModal(true)();
    };

    const removeOrder = (item: any) => async () => {
        setIdDelete(item.id);
        setWarning(item.warning);
        setIsShowDeleteModal(true);


        // await axios.delete(`https://sit.api.pigman.com.vn/foodOrder/${orderId}`)
        //     .then((res) => {
        //         console.log(res);
        //         notification.success({
        //             message: 'Xóa thành công',
        //             description: 'Xóa đơn xuất thành công'
        //         })
        //         fetchData();
        //     })
        //     .catch((err) => {
        //         console.log(err.response.data.message);
        //         notification.error({
        //             message: 'Xóa thất bại',
        //             description: err.response.data.message,
        //         } as any);
        //     });

    };

    const handleFinishDeleteForm = async () => {
        await axios.delete(`https://sit.api.pigman.com.vn/foodOrder/${idDelete}`)
            .then((res) => {
                console.log(res);
                notification.success({
                    message: 'Xóa thành công',
                    description: 'Xóa đơn xuất thành công'
                })
                fetchData();
            })
            .catch((err) => {
                console.log(err.response.data.message);
                notification.error({
                    message: 'Xóa thất bại',
                    description: err.response.data.message,
                } as any);
            });
        setIsShowDeleteModal(false);
    }
    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = data.items.slice(startIndex, endIndex);
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPage(newPage);
    };
    return (
        <div className={`${classes.root} main-content`}>
            <Paper className={classes.paper} elevation={0}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div className='main-breadcrumb'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <span>Thức ăn</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Danh sách thức ăn nhập kho</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <PageHeader
                        pageTile="Quản lý nhập thức ăn"
                        isButton={true}
                        buttonTitle="Thêm mới"
                        buttonClicked={toggleOrderModal('', EModalType.CREATE)}
                    />
                </div>


                <Select placeholder='Chọn nhà cung cấp' className="mb-24" id="supplier-select" onChange={(e: any) => setSupplierId(e)}
                    options={
                        suppliers.map((v) => (
                            { value: v.id, label: v.name }
                        ))
                    }
                >

                </Select>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <StyledTableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'right' : 'center'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}>
                                        {headCell.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {currentRows
                                ? currentRows.map((row: any, index: any) => {
                                    return (
                                        <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <StyledTableCell align="right">{(currentPage - 1) * rowsPerPage + index + 1}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                {row.date ? moment(row.date).format('DD/MM/YYYY') : ''}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" padding="none">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{row.foodSupplier.name}</StyledTableCell>
                                            {/* <StyledTableCell align="right">
                                                {row.foodSupplier.address}
                                            </StyledTableCell> */}
                                            <StyledTableCell align="right">
                                                <Tooltip title="Xem">
                                                    <IconButton
                                                        aria-label="Xem"
                                                        onClick={toggleOrderModal(row.id, EModalType.DETAIL)}>
                                                        <EyeIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Sửa">
                                                    <IconButton
                                                        aria-label="Sửa"
                                                        onClick={toggleOrderModal(row.id, EModalType.UPDATE)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Xóa">
                                                    <IconButton aria-label="Xóa" onClick={removeOrder(row)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {row.warning &&
                                                    <Tooltip title="Đơn này có khả năng sai số khi sửa hoặc xóa">
                                                        <IconButton >
                                                            <IoIosWarning style={{ fontSize: 20, color: 'red' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    );
                                })
                                : ''}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    className={classes.tablePagination}
                    count={Math.ceil(data.items.length / rowsPerPage)}
                    onChange={handleChangePage}
                />

                {isShowModal && (
                    <div className='food-order-modal'>
                        <Modal title={titleModal} toggleModal={toggleModal} isShowModal={isShowModal}>
                            <FoodOrderCreate
                                toggleModal={toggleModal}
                                foodOrderId={foodOrderId}
                                modalType={modalType}
                                refreshAfterAction={refreshAfterAction}
                                supplierId={supplierId}
                            />
                        </Modal>
                    </div>
                )}
                <ModalAntd
                    title="Xóa đơn nhập kho"
                    visible={isShowDeleteModal}
                    onOk={() => setIsShowDeleteModal(false)}
                    onCancel={() => setIsShowDeleteModal(false)}
                    footer={null}
                >
                    <p>Bạn có chắc chắn muốn xóa đơn nhập kho này?</p>
                    {warning && <i >Việc này có thể làm số lượng trong kho bị lỗi!</i>}
                    <div className="modal-footer" style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Đồng ý
                        </Button>
                    </div>

                </ModalAntd>

            </Paper>
        </div>
    );
};

export default FoodOrder;
