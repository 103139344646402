/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import Modal, { IToggleModal } from "@elements/modal/modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { RenderValidationError } from "@components/errors/validation";
import { DatePicker, Checkbox, message, notification } from "antd";
import { ILoginUser } from '@type/user';
import ScaleLoader from "react-spinners/ScaleLoader";
import { DATE_FORMAT } from '@constants/config';
import * as Yup from 'yup';

import * as pigApi from '@api/pig';
import { apiPen } from '@api/pen';

interface IModalUpdateIdentity extends IToggleModal {
    user: ILoginUser
    onUpdateList: () => void
    unUpdateIdentityList: any
}

const CheckboxGroup = Checkbox.Group;
const initialData = {
    penId: '',
    origin: '',
    dateOfBirth: '',
    dateIn: '',
    pigOutPrediction: '',
    changePenPrediction: '',
    weight: '',
    size: '',
    weightTypeId: ''
}

// const pigAIIds = ['260321.L01', '260321.L02', '260321.L03', '260321.L04', '260321.L05', '260321.L06', '260321.L07', '260321.L08', '260321.L09', '260321.L10', '260321.L11', '260321.L12', '260321.L13', '260321.L14', '260321.L15'];

const ConfirmIdentity: FC<IModalUpdateIdentity> = ({ isShowModal, toggleModal, user, onUpdateList, unUpdateIdentityList }: IModalUpdateIdentity) => {
    const [checkedList, setCheckedList] = React.useState<string[]>([]);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);
    const [pens, setPens] = React.useState([]);

    const pigAIIds = unUpdateIdentityList.map((v: any) => v.pigAIId || '');

    const schema = Yup.object().shape({
        penId: Yup.string().required('Vui lòng chọn chuồng'),
        dateOfBirth: Yup.string().required('Trường này bắt buộc'),
        dateIn: Yup.string().required('Trường này bắt buộc'),
        pigOutPrediction: Yup.string().required('Trường này bắt buộc'),
        changePenPrediction: Yup.string().required('Trường này bắt buộc'),
    });

    React.useEffect(() => {
        (async () => {
            try {
                const allPens: any = await apiPen.getAllPensByFarmId({ farmId: user.defaultFarm.id, offset: 0, size: 50 });

                setPens(allPens.items);
            } catch (err) {
                console.log(err);

                notification.error({
                    message: 'Xảy ra lỗi khi gọi danh sách chuồng',
                } as any);
            }
        })()
    }, []);

    const doSubmit = async (values: any, actions: any): Promise<void> => {
        delete values['pen'];
        pigApi.updateIdentity({
            pigAIIds: checkedList,
            data: { ...values, size: +values.size, weight: +values.weight },
            userId: user.id,
            farmId: user.defaultFarm.id
        }).then(res => {
            notification.success({
                message: 'Tạo mới thành công',
            } as any);
            actions.resetForm();
            onUpdateList();
            toggleModal(false)();
        }).catch(err => {
            console.log(err);
            notification.error({
                message: 'Đã xảy ra lỗi',
            } as any);
        });
    };

    const onChange = (list: any) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < pigAIIds.length);
        setCheckAll(list.length === pigAIIds.length);
    };

    const onCheckAllChange = (e: any) => {
        setCheckedList(e.target.checked ? pigAIIds : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    return (
        <Modal title={'Xác nhận danh tính'} toggleModal={toggleModal} isShowModal={isShowModal}>
            {
                pens ? (
                    <div id={'wrapper-confirm-identity'}>
                        <div id="selection">
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} className={'selection-box'}>
                                ID
                            </Checkbox>

                            <CheckboxGroup options={pigAIIds} value={checkedList} onChange={onChange} className={'test'} />
                        </div>

                        <Formik
                            initialValues={initialData}
                            onSubmit={doSubmit}
                            validationSchema={schema}
                        >
                            {
                                ({
                                    errors,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form className='log-form'>
                                        <div className="log-field">
                                            <label htmlFor="pen" className="log-label">
                                                Chuồng nuôi
                                            </label>
                                            <div className="control has-icon-right">
                                                <Field
                                                    as="select"
                                                    name="pen"
                                                    className={'log-input'}
                                                    onChange={(e: any) => {
                                                        const pen = JSON.parse(e.target.value);
                                                        setFieldValue('pen', e.target.value);
                                                        setFieldValue('penId', pen.id);
                                                        setFieldValue('weightTypeId', pen.weightType.id);
                                                    }}
                                                >
                                                    <option value="">Chọn chuồng nuôi</option>
                                                    {pens.map((v: any, i) => (<option value={JSON.stringify(v)} key={i}>{v.name}</option>))}
                                                </Field>

                                                <ErrorMessage component={RenderValidationError} name="penId" />
                                            </div>
                                        </div>

                                        {/* <div className="log-field">
                                    <label htmlFor="species" className="log-label">
                                        Giống lợn
                                    </label>
                                    <div className="control has-icon-right">
                                        <Field
                                            name="species"
                                            className={`log-input`}
                                            type={'text'}
                                            id="species"
                                            placeholder={'Giống lợn'}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="species" />
                                    </div>
                                </div> */}

                                        <div className="log-field">
                                            <label htmlFor="dateOfBirth" className="log-label">
                                                Ngày sinh
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="dateOfBirth"
                                                    className={`log-input`}
                                                    id="dateOfBirth"
                                                    placeholder={'Ngày sinh'}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("dateOfBirth", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="dateOfBirth" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="dateIn" className="log-label">
                                                Ngày nhập chuồng
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="dateIn"
                                                    className={`log-input`}
                                                    id="dateIn"
                                                    placeholder={'Ngày nhập chuồng'}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("dateIn", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="dateIn" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="size" className="log-label">
                                                Kích thước (cm)
                                            </label>
                                            <div className="control has-icon-right">
                                                <Field
                                                    name="size"
                                                    className={`log-input`}
                                                    type={'text'}
                                                    id="size"
                                                    placeholder={'Kích thước (cm)'}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="size" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="weight" className="log-label">
                                                Khối lượng (Kg)
                                            </label>
                                            <div className="control has-icon-right">
                                                <Field
                                                    name="weight"
                                                    className={`log-input`}
                                                    type={'text'}
                                                    id="weight"
                                                    placeholder={'Khối lượng (Kg)'}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="weight" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="origin" className="log-label">
                                                Nguồn gốc
                                            </label>
                                            <div className="control has-icon-right">
                                                <Field
                                                    name="origin"
                                                    className={`log-input`}
                                                    type={'text'}
                                                    id="origin"
                                                    placeholder={'Nguồn gốc'}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="origin" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="changePenPrediction" className="log-label">
                                                Ngày chuyển chuồng dự kiến
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="changePenPrediction"
                                                    className={`log-input`}
                                                    id="changePenPrediction"
                                                    placeholder={'Ngày chuyển chuồng dự kiến'}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("changePenPrediction", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="changePenPrediction" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <label htmlFor="pigOutPrediction" className="log-label">
                                                Ngày xuất chuồng dự kiến
                                            </label>
                                            <div className="control has-icon-right">
                                                <DatePicker
                                                    name="pigOutPrediction"
                                                    className={`log-input`}
                                                    id="pigOutPrediction"
                                                    placeholder={'Ngày xuất chuồng dự kiến'}
                                                    onChange={(date, dateString) =>
                                                        setFieldValue("pigOutPrediction", dateString)
                                                    }
                                                    format={DATE_FORMAT}
                                                />
                                                <ErrorMessage component={RenderValidationError} name="pigOutPrediction" />
                                            </div>
                                        </div>

                                        <div className="log-field">
                                            <span />
                                            <button type="submit" className={`btn log-btn is-primary ${isSubmitting ? 'is-loading' : ''}`} disabled={isSubmitting}>
                                                Lưu
                                            </button>
                                        </div>

                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                ) : (<ScaleLoader color={"#001AEE"} loading={true} />)
            }
        </Modal>
    )
}

export default ConfirmIdentity;
