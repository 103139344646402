/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { apiWeightType } from '@api/weight-type';
import farmService from '@services/farm';
import { apiPen } from '@src/src/app/api/pen';
import { importPigFromAI } from '@src/src/app/api/pig';
import { IPenData } from '@type/pen';
import { IPenError } from '@type/error';

import { ICam } from '@type/cam';
import * as camApi from '@api/cam';
import { getAllPigsByPenId } from '@api/pig';

import CamList from '@components/farm/pen-empty/cam-list';
import AddPig from '@components/farm/pen-empty/add-pig';

import { DatePicker, Tabs, notification } from 'antd';
import { IImportPig, IPigData } from '@src/src/app/types/pig';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { IStep } from '../../common/guidelines/customStep';
import { stepProcessData } from '../../common/guidelines/guideline.types';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import CustomAlert from '../../common/alert/custom-alert';
import openNotification, { NotificationType } from '../../common/notification/Notification';

import Utils from '@src/src/common/utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCam from '../pen-empty/add-cam';
import axios from 'axios';
import { Slider } from 'antd'
import { SliderMarks } from 'antd/lib/slider';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';
type PenEmptyCreateProps = {
    onClose: any;
    onRefresh: any;
    penData: IPenData;
    isUpdate: boolean;
    onCloseWithoutCancelStream: any;
    keyProp?: TabPaneKey,
    isAddPigPar?: boolean,
    setIsAddPigPar?: any
    tempPen: any[]
    dataTagPigsByPen: any[]
    eatingPeriodLst: any[]
    lyingPeriodLst: any[]
    playingPeriodLst: any[]
};

enum TabPaneKey {
    PenCreate = 'penCreate',
    CamSettings = 'camSettings',
    AddPig = 'addPig'
}
interface DateType {
    start: string;
    end: string;
    id: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notchedOutline: {
            borderWidth: '1px',
            borderColor: '#001cee18 !important',
        },
        btnSave: {
            backgroundColor: '#001AEE',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        btnCanel: {
            backgroundColor: '#001cee18',
            color: '#001AEE',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginRight: '40px',
        },
        errMsg: {
            color: 'red',
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white',
        },
        footer: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginRight: '40px',
            marginBottom: '20px',
            marginTop: '20px',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '70%',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentAlert: {
            width: '30%',
        },
        highZindex: {
            zIndex: 900
        },
        table: {
            minWidth: 750,
        },
        tab: {
            display: 'flex',
            // display: 'grid',
            // gridTemplateColumns: '1fr 1fr 1fr',

        },
        leftTab: {

        },
        rightTab: {

        },
        tabItem: {
            width: '33%',
            marginTop: '16px',
        },
        lstPicker: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        buttonDate: {
            border: '1px solid #001cee18',
            width: '225px',
        },
        datepicker: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }
    }),
);

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: '#001cee18 !important',
    }),
};

interface ICreatePen {
    farmId: string;
    name: string;
    capacity: number | string;
    area: number | string;
    weightTypeId: string;
    note: string;
    camera?: ICam[];
    origin?: string;
    lowerTemperatureThreshold?: number;
    upperTemperatureThreshold?: number;
    pigTypeId?: string;
    gatewayCode?: string;
    start_date?: string;
    isHaveCamera?: boolean;
    temperature?: number;
    humidity?: number;
    pressure?: number;
}
// Slider marks
const marks: SliderMarks = {
    30: '30°C',
    50: {
        style: {
            color: '#f50',
        },
        label: <strong>50°C</strong>,
    },
};
const { TabPane } = Tabs;

const PenCreate: FC<PenEmptyCreateProps> = ({ setIsAddPigPar, isAddPigPar, onClose, onRefresh, penData, isUpdate, onCloseWithoutCancelStream, dataTagPigsByPen, keyProp = TabPaneKey.PenCreate, tempPen, eatingPeriodLst, lyingPeriodLst, playingPeriodLst }: PenEmptyCreateProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [weightTypes, setWeightTypes] = useState<Array<any>>([]);
    const [err, setErr] = useState<IPenError | any>();
    const [errMsg, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const currentFarm = farmService.getCurrentFarm();
    const [isAddPig, setIsAddPig] = useState(false);
    const [newPenCreatedId, setNewPenCreatedId] = useState<string>('');
    const [key, setKey] = useState<any>(keyProp);
    const [camSelected, setCamSelected] = useState<ICam | any>();
    const [pigs, setPigs] = React.useState<Array<IPigData | any>>([]);

    const [weightTypeId, setWeightTypeId] = useState<string>(penData?.weightType.id ? penData?.weightType.id : '');
    const [weightTypeName, setWeightTypeName] = useState<string>(penData?.weightType.name ? penData?.weightType.name : '');
    const [pigTypeId, setPigTypeId] = useState<string>(penData?.pigType?.id);
    const [pigTypeName, setPigTypeName] = useState<string>(penData?.pigType?.name);
    const [intervalIdGetPig, setIntervalIdGetPig] = useState<any>();
    const [showConfirmSavePen, setShowConfirmSavePen] = useState<boolean>(false);
    const [savedCams, setSavedCams] = useState<ICam[]>([]);
    const [penIsCreated, setPenIsCreated] = useState<boolean>(false);
    const [selectTempPen, setSelectTempPen] = useState<any[]>(dataTagPigsByPen);
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [tempLower, setTempLower] = useState<number>(37); // Nhiệt độ thấp nhất
    const [tempUpper, setTempUpper] = useState<number>(39); // Nhiệt độ cao nhất

    const [error, setError] = React.useState<any>();

    const [isClickAddCam, setIsClickAddCam] = useState<boolean>(false);

    const { lstPens } = useSelectorRoot(state => state.farm);

    const [input, setInput] = penData?.start_date ? React.useState<ICreatePen>({
        farmId: currentFarm.id,
        name: penData?.name ?? '',
        capacity: penData?.capacity ?? '10',
        weightTypeId: penData?.weightType?.id ?? '',
        note: penData?.note ?? '',
        area: penData?.area ?? '',
        origin: penData?.origin ?? '',
        lowerTemperatureThreshold: penData?.lowerTemperatureThreshold ?? 37,
        upperTemperatureThreshold: penData?.upperTemperatureThreshold ?? 39,
        pigTypeId: penData?.pigType?.id ?? '',
        gatewayCode: penData?.gatewayCode ?? '',
        start_date: penData?.start_date,
        isHaveCamera: penData?.isHaveCamera ?? false,
    }) : React.useState<ICreatePen>({
        farmId: currentFarm.id,
        name: penData?.name ?? '',
        capacity: penData?.capacity ?? '10',
        weightTypeId: penData?.weightType?.id ?? '',
        note: penData?.note ?? '',
        area: penData?.area ?? '',
        origin: penData?.origin ?? '',
        lowerTemperatureThreshold: penData?.lowerTemperatureThreshold ?? 37,
        upperTemperatureThreshold: penData?.upperTemperatureThreshold ?? 39,
        pigTypeId: penData?.pigType?.id ?? '',
        gatewayCode: penData?.gatewayCode ?? '',
        isHaveCamera: penData?.isHaveCamera ?? false,
    });

    const [camsData, setCamsData] = React.useState<ICam[]>([]);
    const [lstTypePig, setLstTypePig] = React.useState<any[]>([]);

    // // Hàm thực hiện lấy dữ liệu chuồng
    // const loadLstPen = async () => {
    //     axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${currentFarm.id}?size=1000}`)
    //         .then(res => {
    //             const tmp: { id: any; name: any; }[] = []
    //             console.log(res);
    //             res.data.data.items.forEach((item: any) => {
    //                 item.analysisWarning !== null &&
    //                     tmp.push({
    //                         id: item.id,
    //                         name: item.name
    //                     })
    //             })
    //             setLstPen(tmp);
    //         })
    // }

    useEffect(() => {
        console.log(tempPen);

    }, [tempPen]);

    const addCamData = (camData: ICam) => {
        console.log(camsData);
        console.log(camData)
        setCamsData([...camsData, camData]);
        onRefresh();
    };

    const removeCamData = (camRemoved: ICam) => {
        setCamsData(camsData.filter((cam) => cam.camName != camRemoved.camName));
    };


    useEffect(() => {
        getAllGateWay();
        getAllPenEmptys();
        getAllPens();
        if (penData?.cameras.length > 0) {
            setSavedCams(penData.cameras)
        }
    }, [])

    useEffect(() => {
        getAllWeightTypes();

        if (penData?.cameras?.length > 0) {
            handelAddPig(true);
        }

        if (pigs.length > 0) {
            setIsLoading(false);
            clearInterval(intervalIdGetPig);
        }
    }, [pigs]);

    const getAllWeightTypes = () => {
        apiWeightType
            .getAll()
            .then((res: any) => {
                const weightTypeList: any = [];
                res.map((weightType: any) => {
                    weightTypeList.push({ value: weightType.id, label: weightType.name });
                });
                setWeightTypes(weightTypeList);
            })
            .catch((err) => {
                console.log('err:' + JSON.stringify(err));
            });
    };
    const getAllTypePig = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pigType`)
            .then((res: any) => {
                const tmp: any = [];
                res.data.map((item: any) => {
                    tmp.push({ value: item.id, label: item.name })
                });
                setLstTypePig(tmp);
            })
    }
    const getAllPigs = () => {
        const offset = 0
        const size = 50
        getAllPigsByPenId(newPenCreatedId, offset, size)
            .then((res: any) => {
                console.log('res.items:' + JSON.stringify(res.items));
                setPigs(res.items);
                console.log('pigs:' + pigs);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onCloseConfirmSavePen = () => {
        doSave();
        setShowConfirmSavePen(false);
    }

    const onOkConfirmSavePen = () => {
        createPen();
        setKey(TabPaneKey.CamSettings);
        setShowConfirmSavePen(false);
    }
    const handleSavePen = () => {
        if ((!camsData || camsData.length === 0) && !isUpdate) {
            setShowConfirmSavePen(true);
        }
        else {
            doSave();
        }
        setShowSavePen(false);
    }
    const confirmSavePen = () => {
        console.log('Confirm Save Pen')
        handleAddItem('eat')
        handleAddItem('lie')
        handleAddItem('play')
        setShowSavePen(true)
    }

    const createPen = () => {
        apiPen
            .createPen(input)
            .then(async (res: any) => {
                openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo chuồng trống thành công.`);
                setNewPenCreatedId(res.id);
            })
            .catch((err) => {
                if (typeof err === 'string') {
                    setErrMsg(err);
                } else {
                    setErr(err);
                }
                setIsLoading(false);
            });
    }

    const updateTempPen = async () => {
        const idArray = selectedItems.map(label => {
            const item = tempPen.find((obj: any) => obj.label === label);
            return item ? item.value : null;
        });

        // const arrayOfValues = selectTempPen?.map((obj) => obj.value);
        const req = {
            penId: penData.id,
            temperatureTagId: idArray
        }
        console.log(req);

        await axios.post(`https://sit.api.pigman.com.vn/temperatureTag/importTagToPen`, req)
            .then(res => {
                console.log(res)
            })

    }

    const doSave = () => {
        setIsLoading(true);

        input.capacity = +input.capacity;
        input.area = +input.area;

        const successAction = () => {
            setIsLoading(false);
            setErr(null);
            setErrMsg('');
            onRefresh();
        };

        const createCam = (penId: string) => {
            if (camsData.length > 0) {
                camsData.map((v) => {
                    v.penId = penId;
                    camApi
                        .createCam(v)
                        .then((res) => {
                            setIsAddPig(true);
                            setKey(TabPaneKey.AddPig);
                        })
                        .catch((err) => console.log(err));
                });
            } else {
                onCloseWithoutCancelStream();
            }
        };

        if (isUpdate) {
            console.log(input);
            if (!input.gatewayCode) {
                input.temperature = 0;
                input.humidity = 0;
                input.pressure = 0;
            }

            apiPen
                .updatePen(penData.id, input)
                .then(async (res) => {

                    onRefresh();
                    handlePutPeriod(penData.id);
                    updateTempPen();
                    successAction();
                    openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Cập nhật chuồng nuôi thành công.`);
                    onCloseWithoutCancelStream();

                })
                .catch((err) => {
                    if (typeof err === 'string') {
                        setErrMsg(err);
                    } else {
                        setErr(err);
                    }
                    setIsLoading(false);
                });
        } else {
            apiPen
                .createPen(input)
                .then(async (res: any) => {
                    createCam(res.id);
                    openNotification(NotificationType.Info, 'topRight', `PEHAVIOR`, `Tạo chuồng thành công.`);
                    setNewPenCreatedId(res.id);
                    successAction();
                })
                .catch((err) => {
                    if (typeof err === 'string') {
                        setErrMsg(err);
                    } else {
                        setErr(err);
                    }
                    setIsLoading(false);
                });
        }

        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const doAddPig = () => {
        setIsLoading(true);
        // createPigFromAI(camSelected.id)
        //     .then((res: any) => {
        //         startGetPig();
        //     })
        //     .catch((err) => {
        //         console.log('err:' + JSON.stringify(err));
        //         setIsLoading(false);
        //     });
        const data: IImportPig = {
            importPig: true,
            farmId: currentFarm.id,
            penId: camSelected.penId
        }
        importPigFromAI(camSelected.id, data)
            .then((res: any) => {
                startGetPig();
                setIsAddPigPar(true);
            })
            .catch((err) => {
                console.log('err:' + JSON.stringify(err));
                setIsLoading(false);
            });
    };

    const startGetPig = () => {
        const intervalID = setInterval(function () {
            getAllPigs();
        }, 5000);
        setIntervalIdGetPig(intervalID);
    };

    const checkValidPenName = (name: string) => {
        const len = lstPens.length ?? 0;
        for (let i = 0; i < len; i++) {
            if (lstPens && lstPens[i].name === name) {
                return false;
            }
        }
        return true;
    }

    const handleInput = (e: any) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if ((name === 'capacity' || name === 'area') && value != +value) {
            setErr({ ...err, [name]: 'Trường này chỉ chấp nhận số' });
            setInput({ ...input, [name]: '' });
            return;
        }
        switch (name) {
            case 'area':
                const areaValue = parseInt(value)
                areaValue < 10 ? setErr({ ...err, [name]: 'Diện tích chuồng ≥ 10' }) : setErr({ ...err, [name]: '' });
                break;
            case 'capacity':
                const intValue = parseInt(value)
                intValue < 0 ? setErr({ ...err, [name]: 'Số con lợn ≥ 0' }) : setErr({ ...err, [name]: '' });
                intValue < value ? setErr({ ...err, [name]: 'Số con lợn phải là số nguyên' }) : setErr({ ...err, [name]: '' });
                break;
            case 'name':
                const isValid = checkValidPenName(value);
                isValid ? setErr({ ...err, [name]: '' }) : setErr({ ...err, [name]: 'Tên chuồng đã tồn tại' });
                break;
            default:
                setErr({ ...err, [name]: '' });
                break;
        }
        setInput({ ...input, [name]: value });
    };

    console.log(err);
    const handleInputChangeTypePigId = (selectedOption: any) => {
        setPigTypeId(selectedOption.value);

        setInput({ ...input, pigTypeId: selectedOption.value });
    }
    const handleInputChangeWeightTypeId = (selectedOption: any) => {
        setWeightTypeId(selectedOption.value);
        setInput({ ...input, weightTypeId: selectedOption.value });
    };

    const camSelectedHandle = (camSelected: ICam) => {
        setCamSelected(camSelected);
        // dispatch(setCurrentProcess(AllStepsProcess.ImportPigs));
    };

    const handelAddPig = (isAddPig: boolean) => {
        if (newPenCreatedId?.length == 0) {
            setNewPenCreatedId(penData.id);
        }
        setIsAddPig(isAddPig);
    };

    const { currentProcess, isShowGuideline } = useSelectorRoot(state => state.guideline)
    const dispatch = useDispatchRoot();
    const [lstSteps, setLstSteps] = React.useState<IStep[]>(stepProcessData);
    // const [fillInNewPenFormGuidelineStyle, setFillInNewPenFormGuidelineStyle] = React.useState<any>({
    //     top: 200,
    //     right: 10,
    //     zIndex: 800
    // });
    // const [addNewCameraGuidelineStyle, setAddNewCameraGuidelineStyle] = React.useState<any>({
    //     top: 200,
    //     right: 10,
    //     zIndex: 800
    // });

    // const [importPigGuidelineStyle, setImportPigGuidelineStyle] = React.useState<any>({
    //     top: 200,
    //     right: 10,
    //     zIndex: 800
    // });

    // const [completeUpdatePigsToPenGuidelineStyle, setCompleteUpdatePigsToPenGuidelineStyle] = React.useState<any>({
    //     top: 200,
    //     right: 10,
    //     zIndex: 800
    // });

    const fillInNewPenFormMainContentRef = React.useRef<HTMLDivElement>(null);
    const importPigMainContentRef = React.useRef<HTMLDivElement>(null);
    const completeUpdatePigsToPenContentRef = React.useRef<HTMLDivElement>(null);
    const location = useLocation();

    // const showAuthModal = () => (e: any) => {
    //     dispatch(setIsShowGuideline(true));
    // }

    // React.useEffect(() => {
    //     const offsetLeft = window.innerWidth - (fillInNewPenFormMainContentRef.current?.offsetLeft ?? 1);
    //     const offsetWidth = fillInNewPenFormMainContentRef.current?.offsetWidth ?? 1;
    //     const move = offsetLeft - 130;
    //     const offsetTop = fillInNewPenFormMainContentRef.current?.offsetTop ? fillInNewPenFormMainContentRef.current?.offsetTop : 1;
    //     setFillInNewPenFormGuidelineStyle({
    //         top: offsetTop,
    //         left: move
    //     })
    //     setAddNewCameraGuidelineStyle({
    //         top: offsetTop,
    //         left: move
    //     })
    //     setCompleteUpdatePigsToPenGuidelineStyle({
    //         top: offsetTop,
    //         left: move
    //     })
    // }, [])

    // const handleOkInCreateANewPen = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.None));
    // }

    const onChangeTab = (key: any) => {
        setKey(key);
        // if (key === TabPaneKey.CamSettings) {
        //     dispatch(setCurrentProcess(AllStepsProcess.AddNewCamera));
        // }
    }

    // const handleOkInAddNewCamera = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.FillInNewCameraForm))
    // }

    // const handleOkInImportPigs = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.CompleteUpdatePigsToPen))
    // }

    // const handleOkInCompleteUpdatePigsToPen = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.GoToActivePens));
    //     history.push(routeNames.dashboard);
    // }

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: '#001cee18',
                color: '#18191F',
                fontWeight: 'bold',
                fontSize: '14px',
                textAlign: 'center'
            },
            body: {
                fontSize: '14px',
                border: 'none',
                textAlign: 'center'
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(even)': {
                    backgroundColor: '#001cee18',
                },
            },
        }),
    )(TableRow);

    const [isShowAddCamComponent, setIsShowAddCamComponent] = React.useState(false);
    const [camData, setCamData] = React.useState<ICam | any>();
    const fillInNewCameraFormMainContentRef = React.useRef<HTMLDivElement>(null);
    const [isUpdateTmp, setIsUpdateTmp] = React.useState(false);

    const toggleAddCam = () => {
        setIsShowAddCamComponent(!isShowAddCamComponent);
    }

    useEffect(() => {
        if (key === TabPaneKey.CamSettings && isShowAddCamComponent)
            setIsClickAddCam(true)
        else
            setIsClickAddCam(false)
    }, [key, isShowAddCamComponent])

    useEffect(() => {
        getAllCams();
        getAllTypePig();

    }, []);

    const getAllCams = async () => {
        await camApi.getAll(newPenCreatedId ? newPenCreatedId : (penData ? penData.id : ''))
            .then((res: any) => {
                setCamsData(res);
                if (res.length > 0) {
                    handelAddPig(true);
                } else {
                    handelAddPig(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const addCamToList = (camData: ICam, isAddMoreCam?: boolean) => {
        setCamsData([...camsData, camData]);
        if (!isAddMoreCam) addCamData(camData);
    }

    const updateCam = (camUpdateData: ICam) => (e: any) => {
        console.log('click update cam');
        console.log('isClick', isClickAddCam);
        setCamData(camUpdateData);
        setIsUpdateTmp(true);
        console.log('isUpdatetmp', isUpdateTmp)
        toggleAddCam();
    }

    const removeCam = (camRemoved: ICam) => async (e: any) => {
        if (camRemoved.id) {
            await camApi.removeCam(camRemoved.id);
            getAllCams();
        } else {
            setCamsData(camsData.filter(cam => cam.camName != camRemoved.camName))
            removeCamData(camRemoved);
        }
    }

    const openAdd = () => {
        toggleAddCam();
        setIsClickAddCam(true);
        setIsUpdateTmp(false);
        setCamData(undefined);
    }

    const [existedErr, setExistedErr] = React.useState<boolean>(true);
    const [camsOfPen, setCamsOfPen] = React.useState<ICam[]>([]);

    const checkValidCamName = (camName: string) => {
        const len = camsOfPen.length ?? 0;
        for (let i = 0; i < len; i++) {
            if (camsOfPen && camsOfPen[i].camName === camName) {
                return false;
            }
        }
        const len2 = camsData.length;
        for (let i = 0; i < len2; i++) {
            if (camsData && camsData[i].camName === camName) {
                return false;
            }
        }
        return true;
    }

    const handleCamInput = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'camName':
                const isValid = checkValidCamName(value);
                isValid ? setError({ ...error, [name]: '' }) : setError({ ...error, [name]: 'Tên camera này đã tồn tại' });
                break;
            // case 'account':
            //     Utils.isNullOrEmpty(value) ? setError({ ...error, [name]: 'Tài khoản là trường bắt buộc' }) : setError({ ...error, [name]: '' })
            //     break;
            case 'linkStreaming':
                Utils.isNullOrEmpty(value) ? setError({ ...error, [name]: 'Stream URL là thông tin bắt buộc' }) : setError({ ...error, [name]: '' })
                break;
            default:
                break;
        }
        if (name === 'account') {
            setCamData({ ...camData, [name]: 'value', });
        }
        else if (name === 'passwordCam') {
            setCamData({ ...camData, [name]: 'value', });
        }
        else {
            setCamData({ ...camData, [name]: value })
        }
    }
    useEffect(() => {
        const existError = checkExistError();
        console.log('error', error);
        console.log('exits', existError);
        setExistedErr(existError);
    }, [error, isClickAddCam, isUpdateTmp])

    const checkExistError = () => {
        if (isUpdateTmp) return false;
        console.log('isUpdate', isUpdate);
        if (!error) return true;
        const len = Object.keys(error).length;
        if (len < 3) return true;
        for (let i = 0; i < len; i++) {
            if (error[Object.keys(error)[i]] !== '') {
                return true;
            }
        }
        return false;
    }

    const saveCam = async (e: any) => {
        // if (err) return;
        e.preventDefault();
        const req = {
            ...camData,
            account: 'string',
            passwordCam: 'string',
        }
        if (!isUpdateTmp) {
            if (newPenCreatedId ? newPenCreatedId : (penData ? penData.id : '')) {
                // camData.penId = newPenCreatedId ? newPenCreatedId : (penData ? penData.id : '');
                req.penId = newPenCreatedId ? newPenCreatedId : (penData ? penData.id : '');

                await camApi.createCam(req);
                onRefresh();
            } else {
                addCamToList(req);
            }
        } else {
            if (!camData) return;
            await camApi.updateCam(camData.id, req);
            camSelectedHandle(camData);
            onRefresh();
        }
        setIsAddPig(true);
        toggleAddCam();
        getAllCams();
    }

    useEffect(() => {
        console.log(input);
    }, [input])

    // Hàm thực hiện gán giá trị nhiệt độ cho bệnh lợn
    const handleTemperatureChange = (value: any) => {
        setTemperature(value);
        setInput({ ...input, lowerTemperatureThreshold: value[0], upperTemperatureThreshold: value[1] });
    };




    const { RangePicker } = DatePicker;

    const [eatStartDate, setEatStartDate] = React.useState<any>();
    const [eatEndDate, setEatEndDate] = React.useState<any>();
    const [idEat, setIdEat] = React.useState<any>(0);
    const [idLie, setIdLie] = React.useState<any>(0);
    const [idPlay, setIdPlay] = React.useState<any>(0);
    const [lieStartDate, setLieStartDate] = React.useState<any>();
    const [lieEndDate, setLieEndDate] = React.useState<any>();
    const [playStartDate, setPlayStartDate] = React.useState<any>();
    const [playEndDate, setPlayEndDate] = React.useState<any>();
    const [eatingPeriod, setEatingPeriod] = React.useState<any[]>(eatingPeriodLst);
    const [lyingPeriod, setLyingPeriod] = React.useState<any[]>(lyingPeriodLst);
    const [playingPeriod, setPlayingPeriod] = React.useState<any[]>(playingPeriodLst);
    const [lstEatingPeriod, setLstEatingPeriod] = React.useState<any[]>([]);
    const [lstLyingPeriod, setLstLyingPeriod] = React.useState<any[]>([]);
    const [lstPlayingPeriod, setLstPlayingPeriod] = React.useState<any[]>([]);
    const [showSavePen, setShowSavePen] = React.useState<boolean>(false);
    const [isClickChangeDate, setIsClickChangeDate] = React.useState<boolean>(false);
    const [eatingPeriodTmp, setEatingPeriodTmp] = React.useState<any[]>([
        // { start: '0:00', end: '1:00', id: 0 },
        // { start: '1:00', end: '2:00', id: 1 },
        // { start: '2:00', end: '3:00', id: 2 },
    ]);
    const [lyingPeriodTmp, setLyingPeriodTmp] = React.useState<any[]>([
        // { start: '4:00', end: '5:00', id: 0 },
        // { start: '5:00', end: '6:00', id: 1 },
        // { start: '6:00', end: '7:00', id: 2 },
    ]);

    const [playingPeriodTmp, setPlayingPeriodTmp] = React.useState<any[]>([
        // { start: '8:00', end: '9:00', id: 0 },
        // { start: '9:00', end: '10:00', id: 1 },
        // { start: '10:00', end: '11:00', id: 2 },
    ]);

    useEffect(() => {
        console.log(eatingPeriod);

    }, [eatingPeriod]);

    // useEffect(() => {
    //     let indexEat = 0;
    //     let indexLie = 0;
    //     let indexPlay = 0;
    //     const lstEating: React.SetStateAction<any[]> = [];
    //     const lstLying: React.SetStateAction<any[]> = [];
    //     const lstPlaying: React.SetStateAction<any[]> = [];
    //     behaviorPen?.map((item: any) => {
    //         const start = decimalToTime(item.start);
    //         const end = decimalToTime(item.end);
    //         if (item.behaviourType === 'Eat') {
    //             lstEating.push({ start: start, end: end, id: indexEat++ });
    //         }
    //         if (item.behaviourType === 'Lie') {
    //             lstLying.push({ start: start, end: end, id: indexLie++ });
    //         }
    //         if (item.behaviourType === 'Play') {
    //             lstPlaying.push({ start: start, end: end, id: indexPlay++ });
    //         }
    //     })
    //     console.log('lstEating', lstEating);

    //     setEatingPeriod(lstEating);
    //     setLyingPeriod(lstLying);
    //     setPlayingPeriod(lstPlaying);
    // }, [behaviorPen]);

    useEffect(() => {
        console.log('eatingPeriod', lyingPeriod);

    }, [lyingPeriod])
    const decimalToTime = (decimalValue: any) => {
        const hours = Math.floor(decimalValue);
        const minutes = (decimalValue - hours) * 60;
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime;
    };
    const handleAddItem = (type: string) => {
        if (type === 'eat') {
            let flat = 0
            const parentDiv = document.querySelectorAll('.eating-picker');
            parentDiv.forEach((item: any) => {
                const child = item.children[0].children[0] as HTMLInputElement;
                if (child.value === '')
                    flat = 1;

            });
            if (flat === 1) {
                // notification.error({
                //     message: 'Thông báo',
                //     description: 'Vui lòng nhập thông tin khung giờ ăn để tiếp tục thêm khung giờ',
                // });
            }
            else {
                let flat = 0;
                eatingPeriod.map((item: any, index) => {
                    if (item.start === '' || item.end === '') {
                        flat = 1;
                    }
                })
                flat === 0 && setEatingPeriod([...eatingPeriod, { start: '', end: '', id: eatingPeriod.length }]);
            }
        }
        if (type === 'lie') {
            let flat = 0
            const parentDiv = document.querySelectorAll('.lying-picker');
            parentDiv.forEach((item: any) => {
                const child = item.children[0].children[0] as HTMLInputElement;
                if (child.value === '')
                    flat = 1;

            });
            if (flat === 1) {
                // notification.error({
                //     message: 'Thông báo',
                //     description: 'Vui lòng nhập thông tin khung giờ nằm để tiếp tục thêm khung giờ',
                // });
            }
            else {
                let flat = 0;
                lyingPeriod.map((item: any, index) => {
                    if (item.start === '' || item.end === '') {
                        flat = 1;
                    }
                })
                flat === 0 && setLyingPeriod([...lyingPeriod, { start: '', end: '', id: lyingPeriod.length }]);
            }

        }
        if (type === 'play') {
            let flat = 0
            const parentDiv = document.querySelectorAll('.playing-picker');
            parentDiv.forEach((item: any) => {
                const child = item.children[0].children[0] as HTMLInputElement;
                if (child.value === '')
                    flat = 1;
            });
            if (flat === 1) {
                // notification.error({
                //     message: 'Thông báo',
                //     description: 'Vui lòng nhập thông tin khung giờ chơi để tiếp tục thêm khung giờ',
                // });
            }
            else {
                let flat = 0;
                playingPeriod.map((item: any, index) => {
                    if (item.start === '' || item.end === '') {
                        flat = 1;
                    }
                })
                flat === 0 && setPlayingPeriod([...playingPeriod, { start: '', end: '', id: playingPeriod.length }]);
            }
        }
    };

    const handleRemoveItem = (itemId: any, type: string) => {
        if (type === 'eat')
            setEatingPeriod(eatingPeriod.filter((item: any) => item.id !== itemId));
        if (type === 'lie')
            setLyingPeriod(lyingPeriod.filter((item: any) => item.id !== itemId));
        if (type === 'play')
            setPlayingPeriod(playingPeriod.filter((item: any) => item.id !== itemId));
    };

    const handleChangePicker = (value: any, item: DateType, type: string) => {
        if (value) {
            const start = moment(value[0]._d);
            const end = moment(value[1]._d);
            const duration = moment.duration(end.diff(start));
            const minutesDifference = duration.asMinutes();
            // if (minutesDifference < 30) {
            //     notification.error({
            //         message: 'Thông báo',
            //         description: 'Vui lòng chọn khung giờ cách nhau lớn hơn 30 phút',
            //     });
            //     return;
            // }
            // else {
            if (type === 'eat') {

                setEatStartDate(moment(value[0]._d).format('HH:mm'));
                setEatEndDate(moment(value[1]._d).format('HH:mm'));
                setIdEat(item.id);
            }

            if (type === 'lie') {
                setLieStartDate(moment(value[0]._d).format('HH:mm'));
                setLieEndDate(moment(value[1]._d).format('HH:mm'));
                setIdLie(item.id);
            }

            if (type === 'play') {
                setPlayStartDate(moment(value[0]._d).format('HH:mm'));
                setPlayEndDate(moment(value[1]._d).format('HH:mm'));
                setIdPlay(item.id);
            }
            // }
        }
        else {
            if (type === 'eat') {
                handleRemoveItem(item.id, 'eat');
            }
            if (type === 'lie') {
                handleRemoveItem(item.id, 'lie');
            }
            if (type === 'play') {
                handleRemoveItem(item.id, 'play');
            }
        }
    }

    useEffect(() => {
        if (!eatStartDate || !eatEndDate) return;
        const tmp = {
            start: eatStartDate,
            end: eatEndDate,
            id: idEat
        }
        const existingObj = eatingPeriod.find(item => item.id === idEat);
        const tmpLst = eatingPeriod;

        if (existingObj) {
            const index = eatingPeriod.findIndex(item => item.id === idEat);
            tmpLst[index] = tmp;
            setEatingPeriod(tmpLst);
        }
        handleAddItem('eat');

    }, [eatStartDate, eatEndDate])

    useEffect(() => {
        if (!lieStartDate || !lieEndDate) return;
        const tmp = {
            start: lieStartDate,
            end: lieEndDate,
            id: idLie
        }
        const existingObj = lyingPeriod.find(item => item.id === idLie);
        const tmpLst = lyingPeriod;

        if (existingObj) {
            const index = lyingPeriod.findIndex(item => item.id === idLie);
            tmpLst[index] = tmp;
            setLyingPeriod(tmpLst);
        }
        handleAddItem('lie');
    }, [lieStartDate, lieEndDate])

    useEffect(() => {
        if (!playStartDate || !playEndDate) return;
        const tmp = {
            start: playStartDate,
            end: playEndDate,
            id: idPlay
        }
        const existingObj = playingPeriod.find(item => item.id === idPlay);
        const tmpLst = playingPeriod;

        if (existingObj) {
            const index = playingPeriod.findIndex(item => item.id === idPlay);
            tmpLst[index] = tmp;
            setPlayingPeriod(tmpLst);
        }
        handleAddItem('play');
    }, [playStartDate, playEndDate])

    useEffect(() => {
        const tmp = eatingPeriod.map(item => {
            if (item.start && item.end)
                return {
                    start: timeToDecimal(item.start),
                    end: timeToDecimal(item.end),
                    additionalProp1: {},
                }
        })
        const lstEating = tmp.filter(item => item !== undefined);

        const tmp1 = lyingPeriod.map(item => {
            if (item.start && item.end)
                return {
                    start: timeToDecimal(item.start),
                    end: timeToDecimal(item.end),
                    additionalProp1: {},
                }
        })

        const lstLying = tmp1.filter(item => item !== undefined);

        const tmp2 = playingPeriod.map(item => {
            if (item.start && item.end)
                return {
                    start: timeToDecimal(item.start),
                    end: timeToDecimal(item.end),
                    additionalProp1: {},
                }
        })

        const lstPlaying = tmp2.filter(item => item !== undefined);

        setLstEatingPeriod(lstEating);
        setLstLyingPeriod(lstLying);
        setLstPlayingPeriod(lstPlaying);
        console.log(lstEating, lstLying, lstPlaying);

    }, [eatingPeriod, lyingPeriod, playingPeriod, isClickChangeDate])


    const timeToDecimal = (time: any) => {
        const [hours, minutes] = time.split(':').map(Number);
        const decimalValue = hours + minutes / 60;
        const roundedValue = decimalValue.toFixed(2);
        return Number(roundedValue);
    };

    const handleAddPeriod = (id: string) => {
        const req = {
            penId: id,
            eatingPeriod: lstEatingPeriod,
            lyingPeriod: lstLyingPeriod,
            playingPeriod: lstPlayingPeriod,
        }
        axios.post(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}`, req)
            .then(res => {
                console.log(res);
            })
    };

    const handlePutPeriod = (id: string) => {
        const req = {
            penId: id,
            eatingPeriod: lstEatingPeriod,
            lyingPeriod: lstLyingPeriod,
            playingPeriod: lstPlayingPeriod,
        }
        console.log(req);

        axios.put(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}`, req)
            .then(res => {
                console.log(res);
            })
    };

    // Hàm thực hiện gán giá trị nhiệt độ cho bệnh lợn
    const handleTemperatureLowerChange = (value: any) => {
        console.log('value', value.target.value);
        value = parseFloat(value.target.value);

        if (value < 0) value = 0;
        if (value > 50) value = 50;
        setInput({ ...input, lowerTemperatureThreshold: value });
        setTempLower(value);
        if (value > tempUpper) {
            setErr({ ...err, ['lowerTemperatureThreshold']: 'Nhiệt độ không hợp lệ' });
        }
        else {
            setErr({ ...err, ['lowerTemperatureThreshold']: '' })
        }

    };

    // Hàm thực hiện gán giá trị nhiệt độ cho bệnh lợn
    const handleTemperatureUpperChange = (value: any) => {
        console.log('value', value.target.value);
        value = parseFloat(value.target.value);
        if (value < 0) value = 0;
        if (value > 50) value = 50;
        setInput({ ...input, upperTemperatureThreshold: value });
        setTempUpper(value);
        if (value < tempLower) {
            setErr({ ...err, ['lowerTemperatureThreshold']: 'Nhiệt độ không hợp lệ' });
        }
        else {
            setErr({ ...err, ['lowerTemperatureThreshold']: '' })
        }
    };

    const handleKeyPress = (e: any) => {
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode === 44 || charCode === 45 || charCode === 101) {
            e.preventDefault();
        }
    };

    const handleClickChangeDateToAIDate = () => {
        if (!isClickChangeDate) {
            setEatingPeriod([]);
            setLyingPeriod([]);
            setPlayingPeriod([]);
            setIsClickChangeDate(true);
        }
    }

    useEffect(() => {
        if (!isClickChangeDate) return;
        setEatingPeriod(eatingPeriodTmp);
        setLyingPeriod(lyingPeriodTmp);
        setPlayingPeriod(playingPeriodTmp);
        loadBehaviourForPenByAI(penData.id);
        setIsClickChangeDate(false);
    }, [isClickChangeDate])

    // const resetData = () => {
    //     setEatingPeriodTmp([
    //         // { start: '0:00', end: '1:00', id: 0 },
    //         // { start: '1:00', end: '2:00', id: 1 },
    //         // { start: '2:00', end: '3:00', id: 2 },
    //     ]);
    //     setLyingPeriodTmp([
    //         // { start: '4:00', end: '5:00', id: 0 },
    //         // { start: '5:00', end: '6:00', id: 1 },
    //         // { start: '6:00', end: '7:00', id: 2 },
    //     ]);
    //     setPlayingPeriodTmp([
    //         // { start: '8:00', end: '9:00', id: 0 },
    //         // { start: '9:00', end: '10:00', id: 1 },
    //         // { start: '10:00', end: '11:00', id: 2 },
    //     ]);
    // }
    const [behaviourForPenByAI, setBehaviourForPenByAI] = React.useState<any[]>([]);

    const loadBehaviourForPenByAI = async (id: string) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPenByAI/suggestForPen/{penId}?penId=${id}`)
            .then(res => {
                console.log(res.data.data);
                const tmp = res.data.data;
                if (tmp.length > 0 && Array.isArray(tmp)) {
                    const sortedBehaviorList = tmp.sort((a, b) => {
                        if (a.behaviourType === b.behaviourType) {
                            return a.start - b.start;
                        } else {
                            return a.behaviourType.localeCompare(b.behaviourType);
                        }
                    });
                    setBehaviourForPenByAI(sortedBehaviorList);
                    const lstEating: React.SetStateAction<any[]> = [];
                    const lstLying: React.SetStateAction<any[]> = [];
                    const lstPlaying: React.SetStateAction<any[]> = [];
                    sortedBehaviorList.map((item: any) => {
                        const start = decimalToTime(item.start);
                        const end = decimalToTime(item.end);
                        if (item.behaviourType === 'Eat') {
                            lstEating.push({ start: start, end: end, id: item.id });
                        }
                        if (item.behaviourType === 'Lie') {
                            lstLying.push({ start: start, end: end, id: item.id });
                        }
                        if (item.behaviourType === 'Play') {
                            lstPlaying.push({ start: start, end: end, id: item.id });
                        }
                    })
                    setEatingPeriodTmp(lstEating);
                    setLyingPeriodTmp(lstLying);
                    setPlayingPeriodTmp(lstPlaying);
                }
                else {
                    setBehaviourForPenByAI([]);
                }

            })
    }

    useEffect(() => {
        if (penData) {
            loadBehaviourForPenByAI(penData.id);
        }
    }, []);


    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    useEffect(() => {
        if (dataTagPigsByPen && dataTagPigsByPen.length > 0)
            console.log(dataTagPigsByPen);

        setSelectedItems(dataTagPigsByPen.map((item: any) => item.label));
    }, [dataTagPigsByPen])

    useEffect(() => {
        console.log(selectedItems);

    }, [selectedItems])

    const handleItemClick = (index: any, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        if (event.shiftKey && selectedItems.length > 0) {
            const firstSelectedIndex = selectedItems[selectedItems.length - 1];
            const minIndex = Math.min(firstSelectedIndex, index.label);
            const maxIndex = Math.max(firstSelectedIndex, index.label);
            console.log(minIndex, maxIndex);

            // const newSelectedItems = Array.from({ length: maxIndex - minIndex + 1 }, (_, i) => minIndex + i);
            const newSelectedItems = tempPen.map((item: any, index: number) => {
                if (item.label >= minIndex && item.label <= maxIndex) {
                    return item.label;
                }
            });
            console.log(newSelectedItems);
            const filteredArray = newSelectedItems.filter((item: any) => item !== undefined);
            setSelectedItems(filteredArray);
        } else {
            if (selectedItems.includes(index.label)) {
                setSelectedItems(selectedItems.filter(item => item !== index.label));
            } else {
                setSelectedItems([...selectedItems, index.label]);
            }
        }
    };

    const handleStartDateChange = (date: any) => {
        console.log(date);

        setInput({ ...input, start_date: date });
    }

    const handleSelectChangeGateWay = (value: any) => {
        value === null ? setInput({ ...input, gatewayCode: '' }) : setInput({ ...input, gatewayCode: value.label });
    }

    const disabledDate = (current: any) => {
        // Kiểm tra nếu ngày hiện tại lớn hơn ngày hiện tại thì disable
        return current && current > moment().endOf('day');
    }

    const [lstGateWay, setLstGateWay] = useState<any[]>([]); // Loại trang trại
    const [newLstGateWay, setNewLstGateWay] = useState<any[]>([]); // Loại trang trại
    const getAllGateWay = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/getGateway/all`)
            .then(res => {
                console.log(res.data.data);
                const tmp = res.data.data.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.code
                    }
                });
                setLstGateWay(tmp);
            })
            .catch(err => {
                console.log(err.response.data.message);
            })
    }

    const [emptyPens, setEmptyPens] = useState<any[]>([]); // Loại trang trại
    const [pens, setPens] = useState<any[]>([]); // Loại trang trại

    const getAllPenEmptys = () => {
        const params = {
            offset: 0,
            size: 100,
            farmId: currentFarm.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };

        apiPen
            .getAllPenEmpty(params)
            .then((res: any) => {
                console.log(res);
                setEmptyPens(res.items);

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAllPens = async () => {
        const params = {
            offset: 0,
            size: 100,
            farmId: currentFarm?.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };
        console.log(params);

        await apiPen
            .getAllPen(params)
            .then((res: any) => {
                setPens(res.items);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (lstGateWay && lstGateWay.length > 0) {
            console.log(pens);
            console.log(emptyPens);
            // Lấy ra danh sách gateway của chuồng trống
            const lstGateWayPenEmpty = emptyPens.map((item: any) => {
                if (item && item.gatewayCode) {
                    return {
                        value: item.gatewayCode,
                        label: item.gatewayCode
                    }
                }
            });
            // Xóa các phần tử trùng nhau
            const lstGateWayPenEmptyTmp: any[] = lstGateWayPenEmpty.filter((item: any, index: number) => lstGateWayPenEmpty.indexOf(item) === index && item !== undefined);

            // Lấy ra danh sách gateway của chuồng
            const lstGateWayPen = pens.map((item: any) => {
                if (item && item.gatewayCode) {
                    return {
                        value: item.gatewayCode,
                        label: item.gatewayCode
                    }
                }
            });
            // Xóa các phần tử trùng nhau và các phần tử không có giá trị
            const lstGateWayPenTmp: any[] = lstGateWayPen.filter((item: any, index: number) => lstGateWayPen.indexOf(item) === index && item !== undefined);
            console.log(lstGateWayPenEmptyTmp);
            console.log(lstGateWayPenTmp);

            const tmp: any[] = [];
            // Lấy ra danh sách gateway mà không có trong chuồng trống và chuồng nuôi
            for (let i = 0; i < lstGateWay.length; i++) {
                let flat = 0
                for (let j = 0; j < lstGateWayPenTmp.length; j++) {

                    if (lstGateWay[i].label === lstGateWayPenTmp[j].value) {
                        flat = 1;
                        break;
                    }
                }
                if (flat === 0) {
                    tmp.push(lstGateWay[i]);
                }
            }
            const tmp1: any[] = [];
            // Lấy ra danh sách gateway mà không có trong chuồng trống và chuồng nuôi
            for (let i = 0; i < tmp.length; i++) {
                let flat = 0
                for (let j = 0; j < lstGateWayPenEmptyTmp.length; j++) {

                    if (tmp[i].label === lstGateWayPenEmptyTmp[j].value) {
                        flat = 1;
                        break;
                    }
                }
                if (flat === 0) {
                    tmp1.push(tmp[i]);
                }
            }

            console.log(tmp1);

            setNewLstGateWay(tmp1);
        }
    }, [pens, emptyPens, lstGateWay]);

    const handleMultiSelectChange = (selectedOptions: any) => {
        console.log('====================================');
        console.log('selectedOptions', selectedOptions);
        console.log('====================================');
        setSelectedItems(selectedOptions.map((item: any) => item.label));
        // setSelectTempPen(selectedOptions);
        // console.log('selectedOptions', selectedOptions);
    };
    return (
        <div className='tabs' ref={fillInNewPenFormMainContentRef}>
            <Tabs tabPosition="left" activeKey={key} onChange={onChangeTab}>
                <TabPane
                    disabled={isAddPig && !isUpdate ? true : false}
                    tab="Cấu hình chuồng"
                    key={TabPaneKey.PenCreate}
                    style={{ height: '400px' }}>
                    <div className={classes.tab}>
                        <div className={classes.tabItem}>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }} fontWeight={700}>
                                    Thông tin chuồng
                                </Box>
                            </div>
                            <div>
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8, paddingTop: 10 }}>
                                    Tên chuồng <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div>
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <TextField
                                        fullWidth={true}
                                        id="name"
                                        name="name"
                                        label=""
                                        variant="outlined"
                                        size="small"
                                        placeholder="Nhập tên chuồng"
                                        disabled={isUpdate}
                                        onChange={handleInput}
                                        value={input.name}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.name}</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Diện tích chuồng (m²) <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <TextField
                                        fullWidth={true}
                                        id="area"
                                        name="area"
                                        label=""
                                        variant="outlined"
                                        size="small"
                                        placeholder="Nhập diện tích chuồng"
                                        onChange={handleInput}
                                        value={input.area}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.area}</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Nguồn gốc
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <TextField
                                        fullWidth={true}
                                        id="origin"
                                        name="origin"
                                        label=""
                                        variant="outlined"
                                        size="small"
                                        placeholder="Nhập nguồn gốc của con lợn (nếu có)"
                                        onChange={handleInput}
                                        value={input.origin}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Box>

                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Thời gian bắt đầu nuôi
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <DatePicker
                                        allowClear={false}
                                        disabledDate={disabledDate}
                                        // disabled={isUpdate}
                                        onChange={handleStartDateChange}
                                        format="DD/MM/YYYY"
                                        placeholder="Chọn ngày bắt đầu nuôi"
                                        value={input.start_date ? moment(input.start_date) : null}
                                        style={{ width: '100%', zIndex: 1000 }}

                                    />
                                </Box>

                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Mã thiết bị cảm biến môi trường
                                </Box>
                            </div>
                            {newLstGateWay && newLstGateWay.length > 0 &&
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" justifyContent="flex-start" p={1} width={'100%'} >
                                        <Select
                                            name='lstGateWay'
                                            options={newLstGateWay}
                                            components={makeAnimated()}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            styles={customStyles}
                                            placeholder="Lựa chọn mã gateway"
                                            onChange={handleSelectChangeGateWay}
                                            defaultValue={input.gatewayCode ? { value: input.gatewayCode, label: input.gatewayCode } : { value: '', label: 'Lựa chọn mã gateway của trang trại' }}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            // menuPortalTarget={document.body}
                                            maxMenuHeight={200}
                                            isClearable={true}
                                        />
                                    </Box>

                                </div>
                            }
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Ngưỡng nhiệt độ
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    {/* <Slider
                                        style={{ width: 400 }}
                                        defaultValue={[input.lowerTemperatureThreshold ?
                                            input.lowerTemperatureThreshold :
                                            37, input.upperTemperatureThreshold ?
                                            input.upperTemperatureThreshold : 39
                                        ]}
                                        min={30}
                                        max={50}
                                        range
                                        marks={marks}
                                        onChange={handleTemperatureChange} /> */}
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div style={{ position: 'relative' }}>
                                            <TextField
                                                // id="origin"
                                                name="lowerTemperatureThreshold"
                                                label=""
                                                type='number'
                                                variant="outlined"
                                                size="small"
                                                inputProps={{ min: 30, max: 50 }}
                                                onChange={handleTemperatureLowerChange}
                                                defaultValue={input.lowerTemperatureThreshold ? input.lowerTemperatureThreshold : 37}
                                                // value={input.lowerTemperatureThreshold}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                }}
                                                onBlur={handleTemperatureLowerChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                            <span style={{ position: 'absolute', right: '15px', top: '20%' }}>°C</span>

                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <TextField
                                                // id="origin"
                                                name="upperTemperatureThreshold"
                                                label=""
                                                type='number'
                                                variant="outlined"
                                                inputProps={{ min: 30, max: 50 }}
                                                size="small"
                                                onChange={handleTemperatureUpperChange}
                                                // value={input.origin}
                                                defaultValue={input.upperTemperatureThreshold ? input.upperTemperatureThreshold : 39}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                }}
                                                onBlur={handleTemperatureUpperChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                            <span style={{ position: 'absolute', right: '15px', top: '20%' }}>°C</span>
                                            <Box display="flex" justifyContent="flex-start" ml={1}>
                                                <span className={classes.errMsg}>{err?.upperTemperatureThreshold}</span>
                                            </Box>
                                        </div>
                                    </div>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.lowerTemperatureThreshold}</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.upperTemperatureThreshold}</span>
                                </Box>
                            </div>
                            <div>
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Loại lợn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box p={1} >
                                    <Select
                                        name="typePigId"
                                        options={lstTypePig}
                                        components={makeAnimated()}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        styles={customStyles}
                                        placeholder="Chọn loại lợn"
                                        onChange={handleInputChangeTypePigId}
                                        defaultValue={(pigTypeId && pigTypeName) ? { value: pigTypeId, label: pigTypeName } : { value: '', label: 'Vui lòng chọn loại lợn' }}
                                    // style={{ width: 280 }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.typePigId}</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Cân nặng lợn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box p={1} >
                                    <Select
                                        name="weightTypeId"
                                        options={weightTypes}
                                        components={makeAnimated()}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        styles={customStyles}
                                        placeholder="Chọn cân nặng lợn"
                                        onChange={handleInputChangeWeightTypeId}
                                        defaultValue={(weightTypeId && weightTypeName) ? { value: weightTypeId, label: weightTypeName } : { value: '', label: 'Vui lòng chọn cân nặng lợn' }}
                                    // style={{ width: 280 }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.weightTypeId}</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Danh sách thẻ nhiệt
                                </Box>
                            </div>
                            {/* <div >
                                <Box p={1} >
                                    {(dataTagPigsByPen) &&
                                        <Select
                                            name='lstTempPen'
                                            options={tempPen}
                                            components={makeAnimated()}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            styles={customStyles}
                                            placeholder="Chọn mã thẻ"
                                            onChange={handleMultiSelectChange}
                                            defaultValue={dataTagPigsByPen}
                                        />
                                    }
                                    {(!dataTagPigsByPen) &&
                                        <Select
                                            name='lstTempPen'
                                            options={tempPen}
                                            components={makeAnimated()}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            styles={customStyles}
                                            placeholder="Chọn mã thẻ"
                                            onChange={handleMultiSelectChange}
                                            defaultValue={[]}
                                        />
                                    }

                                </Box>
                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                    <span className={classes.errMsg}>{err?.weightTypeId}</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Ghi chú
                                </Box>
                            </div> */}
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <TextField
                                        id="note"
                                        name="note"
                                        label=""
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        variant="outlined"
                                        fullWidth={true}
                                        placeholder="Nhập ghi chú"
                                        onChange={handleInput}
                                        value={input.note}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Box>
                            </div>
                        </div>
                        <div className={classes.tabItem}>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }} fontWeight={700}>
                                    Khung thời gian do người dùng nhập
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8, paddingTop: 10 }}>
                                    Khung giờ ăn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <div className={classes.datepicker}>
                                        <div className={classes.lstPicker}>
                                            {eatingPeriod.map((item: DateType, index) => (
                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <RangePicker
                                                        className='eating-picker'
                                                        showTime={{ format: 'HH:mm' }}
                                                        allowClear={false}
                                                        format="HH:mm"
                                                        hourStep={1}
                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                        onChange={(e: any) => handleChangePicker(e, item, 'eat')}
                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                    />
                                                    <FaTrash
                                                        style={{ cursor: 'pointer' }}
                                                        className="remove-icon"
                                                        onClick={() => handleRemoveItem(item.id, 'eat')}
                                                    />
                                                </li>
                                            ))}
                                        </div>
                                        <Button className={classes.buttonDate} onClick={() => handleAddItem('eat')}>Thêm khung giờ</Button>
                                    </div>
                                </Box>

                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Khung giờ nằm <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <div className={classes.datepicker}>
                                        <div className={classes.lstPicker}>
                                            {lyingPeriod.map((item: DateType, index) => (
                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <RangePicker
                                                        className='lying-picker'
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="HH:mm"
                                                        allowClear={false}
                                                        hourStep={1}
                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                        onChange={(e: any) => handleChangePicker(e, item, 'lie')}
                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                    />
                                                    <FaTrash
                                                        style={{ cursor: 'pointer' }}
                                                        className="remove-icon"
                                                        onClick={() => handleRemoveItem(item.id, 'lie')}
                                                    />
                                                </li>
                                            ))}
                                        </div>
                                        <Button className={classes.buttonDate} onClick={() => handleAddItem('lie')}>Thêm khung giờ</Button>
                                    </div>
                                </Box>

                            </div>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                    Khung giờ chơi <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                </Box>
                            </div>
                            <div >
                                <Box display="flex" justifyContent="flex-start" p={1} >
                                    <div className={classes.datepicker}>
                                        <div className={classes.lstPicker}>
                                            {playingPeriod.map((item: DateType, index) => (
                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <RangePicker
                                                        className='playing-picker'
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="HH:mm"
                                                        allowClear={false}
                                                        hourStep={1}
                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                        onChange={(e: any) => handleChangePicker(e, item, 'play')}
                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                    />
                                                    <FaTrash
                                                        style={{ cursor: 'pointer' }}
                                                        className="remove-icon"
                                                        onClick={() => handleRemoveItem(item.id, 'play')}
                                                    />
                                                </li>
                                            ))}
                                        </div>
                                        <Button className={classes.buttonDate} onClick={() => handleAddItem('play')}>Thêm khung giờ</Button>
                                    </div>
                                </Box>

                            </div>
                        </div>
                        <div className={classes.tabItem} id='tabItemLast'>
                            <div >
                                <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }} fontWeight={700} >
                                    Khung thời gian do AI gợi ý
                                </Box>
                            </div>
                            {behaviourForPenByAI && behaviourForPenByAI.length > 0 ?
                                <>
                                    <div >
                                        <Box display="flex" justifyContent="" style={{ paddingLeft: 8, paddingTop: 10 }}>
                                            Khung giờ ăn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                        </Box>
                                    </div>
                                    {eatingPeriodTmp && eatingPeriodTmp.length > 0 ?
                                        <>

                                            <div >
                                                <Box display="flex" justifyContent="flex-start" p={1} >
                                                    <div className={classes.datepicker}>
                                                        <div className={classes.lstPicker} >
                                                            {eatingPeriodTmp.map((item: DateType, index) => (
                                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                    <RangePicker
                                                                        className='eating-picker'
                                                                        showTime={{ format: 'HH:mm' }}
                                                                        format="HH:mm"
                                                                        allowClear={false}
                                                                        hourStep={1}
                                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                                        // onChange={(e: any) => handleChangePicker(e, item, 'eat')}
                                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                                        disabled={true}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Box>

                                            </div>
                                        </> :
                                        <div>
                                            <Box display="flex" justifyContent="" style={{ paddingLeft: 8, fontStyle: 'italic' }}>
                                                Chưa đủ dữ liệu để gợi ý
                                            </Box>
                                        </div>
                                    }

                                    <div >
                                        <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                            Khung giờ nằm <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                        </Box>
                                    </div>
                                    {lyingPeriodTmp && lyingPeriodTmp.length > 0 ?
                                        <>
                                            <div >
                                                <Box display="flex" justifyContent="flex-start" p={1} >
                                                    <div className={classes.datepicker}>
                                                        <div className={classes.lstPicker} >
                                                            {lyingPeriodTmp.map((item: DateType, index) => (
                                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                    <RangePicker
                                                                        className='lying-picker'
                                                                        showTime={{ format: 'HH:mm' }}
                                                                        format="HH:mm"
                                                                        hourStep={1}
                                                                        allowClear={false}
                                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                                        // onChange={(e: any) => handleChangePicker(e, item, 'lie')}
                                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                                        disabled={true}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </Box>

                                            </div>
                                        </> :
                                        <div>
                                            <Box display="flex" justifyContent="" style={{ paddingLeft: 8, fontStyle: 'italic' }}>
                                                Chưa đủ dữ liệu để gợi ý
                                            </Box>
                                        </div>
                                    }

                                    <div >
                                        <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                            Khung giờ chơi <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                        </Box>
                                    </div>
                                    {playingPeriodTmp && playingPeriodTmp.length > 0 ?
                                        <>
                                            <div >
                                                <Box display="flex" justifyContent="flex-start" p={1} >
                                                    <div className={classes.datepicker}>
                                                        <div className={classes.lstPicker} >
                                                            {playingPeriodTmp.map((item: DateType, index) => (
                                                                <li className='lst-picker' key={item.id} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                    <RangePicker
                                                                        className='playing-picker'
                                                                        showTime={{ format: 'HH:mm' }}
                                                                        format="HH:mm"
                                                                        hourStep={1}
                                                                        allowClear={false}
                                                                        placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                                                        // onChange={(e: any) => handleChangePicker(e, item, 'play')}
                                                                        defaultValue={(item.start && item.end) ? [moment(item.start, 'HH:mm'), moment(item.end, 'HH:mm')] : [null, null]}
                                                                        disabled={true}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Box>
                                            </div>
                                        </> :
                                        <div>
                                            <Box display="flex" justifyContent="" style={{ paddingLeft: 8, fontStyle: 'italic' }}>
                                                Chưa đủ dữ liệu để gợi ý
                                            </Box>
                                        </div>
                                    }
                                    <div >
                                        <Box display="flex" justifyContent="" m={1} >
                                            <Button className={classes.buttonDate} onClick={handleClickChangeDateToAIDate}>Lấy khung giờ AI</Button>
                                        </Box>
                                    </div>
                                </>
                                :
                                <div>
                                    <Box display="flex" justifyContent="" style={{ paddingLeft: 8 }}>
                                        Khung giờ chưa được cập nhật
                                    </Box>
                                </div>
                            }
                        </div>
                    </div>
                </TabPane>

                <TabPane
                    disabled={isAddPig && !isUpdate ? true : false}
                    tab="Cấu hình camera"
                    key={TabPaneKey.CamSettings}
                    style={{ height: '400px', overflow: 'scroll' }}>
                    <div style={{ margin: '8px 0' }} className={`${classes.highZindex}`}>
                        {!isShowAddCamComponent ?
                            (
                                <TableContainer>
                                    <div
                                        className={`btn MuiButtonBase-root MuiButton-root MuiButton-contained makeStyles-btnComponent-17 mb-24 ${classes.highZindex}`}
                                        onClick={openAdd}
                                        style={{ display: 'none' }}

                                    >
                                        Thêm camera
                                    </div>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={'small'}
                                        aria-label="enhanced table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>
                                                    Tên
                                                </StyledTableCell>

                                                {/* <StyledTableCell>
                                                    Tài khoản
                                                </StyledTableCell> */}

                                                <StyledTableCell>
                                                    Nhà sản xuất
                                                </StyledTableCell>

                                                <StyledTableCell>
                                                    Stream URL
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Thao tác
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>


                                        <TableBody>
                                            {camsData?.map((v, i) => (
                                                <StyledTableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={i}
                                                >
                                                    <StyledTableCell component="th" scope="row" padding="none">
                                                        {v.camName}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="right">{v.account}</StyledTableCell> */}
                                                    <StyledTableCell align="right">{v.manufacturer}</StyledTableCell>
                                                    <StyledTableCell align="right">{v.linkStreaming}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {/* {
                                                            v.id && (
                                                                <Tooltip title="Sửa">
                                                                    <IconButton aria-label="edit">
                                                                        <EditIcon fontSize="small" onClick={updateCam(v)} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                        } */}
                                                        <Tooltip title="Xoá">
                                                            <IconButton aria-label="delete">
                                                                <DeleteIcon fontSize="small" onClick={removeCam(v)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) :
                            <div ref={fillInNewCameraFormMainContentRef}>
                                <div style={{ marginBottom: '16px' }}>
                                    <form onSubmit={saveCam}>
                                        <Grid container>
                                            <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Tên camera <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="camName"
                                                        name="camName"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập tên camera"
                                                        onChange={handleCamInput}
                                                        value={camData?.camName}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.camName}</span>
                                                </Box>
                                            </Grid>

                                            {/* <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Tài khoản
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="account"
                                                        name="account"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập tài khoản"
                                                        onChange={handleCamInput}
                                                        value={camData?.account}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.account}</span>
                                                </Box>
                                            </Grid>

                                            <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Mật khẩu
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="passwordCam"
                                                        name="passwordCam"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập mật khẩu"
                                                        onChange={handleCamInput}
                                                        value={camData?.passwordCam}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                        type='password'
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.weightTypeId}</span>
                                                </Box>
                                            </Grid> */}

                                            <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Nhà sản xuất
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="manufacturer"
                                                        name="manufacturer"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập nhà sản xuất"
                                                        onChange={handleCamInput}
                                                        value={camData?.manufacturer}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.weightTypeId}</span>
                                                </Box>
                                            </Grid>

                                            <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Stream URL <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="linkStreaming"
                                                        name="linkStreaming"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập link stream URL"
                                                        onChange={handleCamInput}
                                                        value={camData?.linkStreaming}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.linkStreaming}</span>
                                                </Box>
                                            </Grid>


                                            <Grid item sm={3}>
                                                <Box display="flex" justifyContent="flex-start" p={1} m={1}>
                                                    Thông tin camera
                                                </Box>
                                            </Grid>
                                            <Grid item sm={9}>
                                                <Box width="70%" p={1}>
                                                    <TextField
                                                        fullWidth={true}
                                                        id="metaData"
                                                        name="metaData"
                                                        label=""
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Nhập thêm thông tin camera"
                                                        onChange={handleCamInput}
                                                        value={camData?.metaData}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: classes.notchedOutline
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="flex-start" ml={1}>
                                                    <span className={classes.errMsg}>{error?.weightTypeId}</span>
                                                </Box>
                                            </Grid>

                                            {/* <Grid container className='mt-12'>
                                            <Button variant="contained" onClick={toggleAddCam} style={{ marginRight: '24px' }}>
                                                Hủy
                                            </Button>

                                            <Button variant="contained" disabled={existedErr} className={classes.btnCanel} onClick={saveCam}>
                                                {camData ? 'Cập nhật' : 'Thêm'}
                                            </Button>
                                        </Grid> */}
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </TabPane>

                {/* {(isAddPig || penData?.cameras?.length > 0) && (
                    <TabPane tab="Đánh số" key={TabPaneKey.AddPig} style={{ height: '400px', overflow: 'scroll' }}>
                        <div ref={importPigMainContentRef}>
                           
                            <AddPig
                                penId={newPenCreatedId ? newPenCreatedId : ''}
                                camSelected={camSelectedHandle}
                                camSelectedData={camSelected}
                                pigs={pigs}
                                savedCams={savedCams}
                            />
                        </div>

                    </TabPane>
                )} */}
            </Tabs>
            {!isUpdate && !isAddPig && key !== TabPaneKey.AddPig && key !== TabPaneKey.CamSettings && (
                <div className={classes.footer}>
                    <Button onClick={onClose} variant="contained" className={classes.btnCanel}>
                        Hủy bỏ
                    </Button>
                    <Button
                        onClick={confirmSavePen}
                        variant="contained"
                        className={classes.btnSave}
                        disabled={(input.name && input.area && input.capacity && input.weightTypeId && input.pigTypeId && lstEatingPeriod.length > 0 && lstLyingPeriod.length > 0 && lstPlayingPeriod.length > 0 && !err?.lowerTemperatureThreshold) ? false : true}>                        {isLoading && <CircularProgress className={classes.circularProgress} size={20} />}
                        {isUpdate ? 'Cập nhật' : 'Lưu'}
                    </Button>
                </div>
            )}
            {(isUpdate && key !== TabPaneKey.AddPig && key !== TabPaneKey.CamSettings) && (
                <>
                    <div className={classes.footer}>

                        <Button onClick={onClose} variant="contained" className={classes.btnCanel + ' baka'}>
                            Hủy bỏ
                        </Button>

                        <Button
                            onClick={confirmSavePen}
                            variant="contained"
                            className={classes.btnSave}
                            disabled={(input.name && input.area && input.weightTypeId && input.pigTypeId && lstEatingPeriod.length > 0 && lstLyingPeriod.length > 0 && lstPlayingPeriod.length > 0 && !err?.lowerTemperatureThreshold) ? false : true}>
                            {isLoading && <CircularProgress className={classes.circularProgress} size={20} />}
                            {isUpdate ? 'Cập nhật' : 'Lưu'}
                        </Button>
                    </div>
                </>
            )}

            {key == TabPaneKey.AddPig && (
                <div className={classes.footer} ref={completeUpdatePigsToPenContentRef}>
                    <Button disabled={isLoading} onClick={onCloseWithoutCancelStream} variant="contained" className={classes.btnCanel}>
                        {isAddPig && pigs.length > 0 ? 'Xong' : 'Để sau'}
                    </Button>
                    {pigs.length == 0 && (
                        <Button
                            onClick={doAddPig}
                            variant="contained"
                            className={classes.btnSave}
                            disabled={(camSelected && !isLoading && pigs.length == 0) ? false : true}>
                            {isLoading && <CircularProgress className={classes.circularProgress} size={20} />}
                            {isLoading ? 'Đang xử lý...' : 'Đánh số'}
                        </Button>
                    )}
                </div>
            )}


            {key === TabPaneKey.CamSettings && (isClickAddCam || isUpdateTmp) && (
                <div className={classes.footer}>
                    <Button onClick={onClose} variant="contained" className={classes.btnCanel}>
                        Hủy bỏ
                    </Button>
                    <Button
                        onClick={saveCam}
                        variant="contained"
                        className={classes.btnSave}
                        disabled={error?.camName || error?.linkStreaming}>
                        {isLoading && <CircularProgress className={classes.circularProgress} size={20} />}
                        {isUpdateTmp ? 'Cập nhật' : 'Lưu'}
                    </Button>
                </div>
            )}

            {!isUpdate && !isAddPig && <Modal
                open={showConfirmSavePen}
                onClose={onCloseConfirmSavePen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentAlert}>
                    <CustomAlert
                        title={`Bạn có muốn thêm cấu hình camera không?`}
                        okButton={onOkConfirmSavePen}
                        cancelButton={onCloseConfirmSavePen}
                    />
                </div>
            </Modal>}


            <Modal
                open={showSavePen}
                onClose={() => setShowSavePen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentAlert}>
                    <CustomAlert
                        title={`Bạn có muốn cập nhật chuồng này?`}
                        okButton={handleSavePen}
                        cancelButton={() => setShowSavePen(false)}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default PenCreate;
