/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { searchBeHaviour } from '@api/behaviour';
import { UtilCollections } from '@utils/collections';
import { IBeHaviour } from '@type/behaviour';

import runnIcon from '@assets/pig-walk.png';
import standIcon from '@assets/pig-stand.png';
import eatIcon from '@assets/pig-eat.png';
import lieIcon from '@assets/pig-lie.png';
import unspecifiedIcon from '@assets/unspecifiedIcon.png';
import { BiWalk } from 'react-icons/bi'
import { LuPersonStanding } from 'react-icons/lu'
import { GiNightSleep } from 'react-icons/gi'
import { TbMeat } from 'react-icons/tb'
import { RxValueNone } from 'react-icons/rx'
import moment from 'moment';
interface IDayActivityProps {
    penId: string,
    pigAIId: string
    lastUpdate?: string
}

const DayActivity: FC<IDayActivityProps> = ({ pigAIId, penId, lastUpdate }: IDayActivityProps) => {
    const [activityData, setActivityData] = React.useState<IBeHaviour[] | any>([{ eatingTimes: 0, lieDownTimes: 0, sleepingTimes: 0, standingTimes: 0, runningTimes: 0, unspecified: 0 }]);
    const currentDate = UtilCollections.getCurrentDate();

    React.useEffect(() => {
        try {
            getBeHaviours();
            const intervalGetBehaviour = setInterval(getBeHaviours, 10000);
            return () => {
                clearInterval(intervalGetBehaviour);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getBeHaviours = async () => {
        const start_ = new Date()
        start_.setDate(start_.getDate());
        start_.setHours(0);
        start_.setMinutes(0);
        start_.setSeconds(0);
        const end_ = new Date()
        end_.setDate(end_.getUTCDate());
        const dayActivity = await searchBeHaviour({
            startDate: start_.toISOString(),
            endDate: end_.toISOString(),
            penId,
            pigAIId,
        }) as IBeHaviour[];

        setActivityData(dayActivity);
    }

    const calculatePercent = (data: number): number => {
        // console.log("Activity");
        // console.log(activityData)
        // const end_ = new Date()
        // end_.setDate(end_.getDate());
        // console.log(end_)
        // const sum = end_.getUTCHours() * 3600 + end_.getMinutes() * 60 + end_.getSeconds();
        // return parseFloat((data / sum * 1.0 * 100).toFixed(2))

        const sum = activityData[0]?.eatingTimes + activityData[0]?.lieDownTimes + activityData[0]?.sleepingTimes + activityData[0]?.standingTimes + activityData[0]?.runningTimes + activityData[0]?.unspecified;
        const res = parseFloat((data / sum * 1.0 * 100).toFixed(2))
        return res ? res : 0;
    }

    // const calculateSpecifiedTimes = (data: number[]): number => {
    //     const end_ = new Date()
    //     end_.setDate(end_.getDate());
    //     let sum = end_.getHours() * 3600 + end_.getMinutes() * 60 + end_.getSeconds();
    //     data.forEach(item => {
    //         sum -= item;
    //     });
    //     return parseFloat((sum / 60).toFixed(2))
    // }

    return (
        <div id="activity-day__report">
            {activityData ? (
                <>
                    <div id="activity-title">Chỉ số quan trọng của lợn  khi bắt đầu theo dõi <br /> lúc: {moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')}</div>

                    <div id="wrapper-activity">
                        <div className="activity">
                            <div className="activity-name">
                                <img src={runnIcon} className='activity-icon' />
                                <p className="activity-title">Đi</p>
                            </div>
                            <span>{calculatePercent(activityData[0].runningTimes)}%</span>
                            <span>{(activityData[0].runningTimes / 60).toFixed(2)} phút</span>
                        </div>

                        <div className="activity">
                            <div className="activity-name">
                                <img src={standIcon} className='activity-icon' />
                                <p className="activity-title">Đứng</p>
                            </div>
                            <span>{calculatePercent(activityData[0].standingTimes)}%</span>
                            <span>{(activityData[0].standingTimes / 60).toFixed(2)} phút</span>
                        </div>

                        <div className="activity">
                            <div className="activity-name">
                                <img src={lieIcon} className='activity-icon' />
                                <p className="activity-title">Nằm</p>
                            </div>
                            <span>{calculatePercent(activityData[0].lieDownTimes)}%</span>
                            <span>{(activityData[0].lieDownTimes / 60).toFixed(2)} phút</span>
                        </div>

                        <div className="activity">
                            <div className="activity-name">
                                <img src={eatIcon} className='activity-icon' />
                                <p className="activity-title">Ăn</p>
                            </div>
                            <span>{calculatePercent(activityData[0].eatingTimes)}%</span>
                            <span>{(activityData[0].eatingTimes / 60).toFixed(2)} phút</span>
                        </div>

                        <div className="activity">
                            <div className="activity-name">
                                <RxValueNone className='activity-icon' />
                                <p className="activity-title">Không xác định</p>
                            </div>
                            <span>{parseFloat(calculatePercent(activityData[0].unspecified).toFixed(2))}%</span>
                            {/* <span>{calculateSpecifiedTimes([activityData[0].standingTimes, activityData[0].lieDownTimes, activityData[0].runningTimes])} phút</span> */}
                            <span>{(activityData[0].unspecified / 60).toFixed(2)} phút</span>
                        </div>
                    </div>
                </>
            ) : (<ScaleLoader color={"#001AEE"} loading={true} />)}
        </div>
    );
}

export default DayActivity;