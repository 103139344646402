/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from 'react';
import routerNames from './route-names';
import PublicLayout from '@components/.';

import HomePage from '@components/home/home';
import Pen from '@src/src/app/components/farm/pen/pen';
import PenEmpty from '@src/src/app/components/farm/pen-empty/pen-empty';
import Event from '@src/src/app/components/farm/event/event';
import Dashboard from '@components/dashboard/dashboard';
import NotFound from '@components/common/not-found/not-found';
import Pig from '@components/pigs/list';
import PigIdentity from '@components/pigs/identity';
import SelectFarm from '@components/farm/select-farm/select-farm';
import Farm from '@components/farm/farm';
import Video from '@components/video/video';
import FoodSupplier from '@components/foods/food-supplier';
import Food from '@components/foods/food';
import FoodOrder from '@components/food-order/food-order';
import FoodConsume from '@components/food-consume/.';
import SickPigs from '../components/sickpigs/SickPigs';
import TagPigs from '../components/tag-pigs/TagPigs';
import TagPigsPen from '../components/tag-pigs/TagPigPen/TagPigsPen';
import Profile from '../components/profile/profile/Profile';
import UserList from '../components/profile/userList/UserList';
import PigList from '../components/profile/pigList/PigList';
import FarmList from '../components/profile/farmList/FarmList';
import AllFarmList from '../components/profile/allFarmList/AllFarmList';
import SickpigsProfile from '../components/profile/sickpigsProfile/SickpigsProfile';
import ReportSickPig from '../components/report/ReportSickPig';
import ReportUnusualPig from '../components/report/ReportUnusualPig';
import ReportFoodConsume from '../components/report/ReportFoodConsume';
import ReportFoodInventory from '../components/report/ReportFoodInventory';
import ReportPharmaceuticalConsume from '../components/report/ReportPharmaceuticalConsume';
import ReportPharmaceuticalOrder from '../components/report/ReportPharmaceuticalOrder';
import ReportPharmaceuticalInventory from '../components/report/ReportPharmaceuticalInventory';
import SickPigsDiagnosis from '../components/sickpigs/SickPigsDiagnosis';
import ReportFoodOrder from '../components/report/ReportFoodOrder';
import ModalProfile from '../components/profile/modal/ModalProfile';
import PharmaceuticalPlan from '../components/pharmaceutical/pharmaceuticalPlan/PharmaceuticalPlan';
import PharmaceuticalType from '../components/pharmaceutical/pharmaceuticalType/PharmaceuticalType';
import PharmaceuticalOrder from '../components/pharmaceutical/pharmaceuticalOrder/PharmaceuticalOrder';
import PharmaceuticalConsume from '../components/pharmaceutical/pharmaceuticalConsume/PharmaceuticalConsume';
import PharmaceuticalSupplier from '../components/pharmaceutical/pharmaceuticalSupplier/PharmaceuticalSupplier';
import Policy from '../components/policy/Policy';
import TemperatureStatusList from '../components/profile/temperature/temperature';
import BehaviourStatus from '../components/profile/behaviour/behaviour';
import GatewayFarm from '../components/farm/gateway-farm/GatewayFarm';
import ReportTemPig from '../components/report/ReportTemPig';
import GatewayPen from '../components/farm/gateway-farm/GatewayPen';

interface IRoute {
    exact: boolean;
    path: string;
    component: FC<any>;
    isLoginRequired: boolean;
}

interface IArrayRoutes extends IRoute {
    layout: FC<any>;
    routes?: Array<IRoute>;
}

const routes: Array<IArrayRoutes> = [
    {
        exact: true,
        path: routerNames.homePage,
        component: Farm,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.introduction,
        component: HomePage,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.products,
        component: HomePage,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.support,
        component: HomePage,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.farm,
        component: Farm,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.selectFarm,
        component: SelectFarm,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.video,
        component: Video,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.dashboard,
        component: Dashboard,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportSickPig,
        component: ReportSickPig,
        layout: PublicLayout,
        isLoginRequired: true,
    },

    {
        exact: true,
        path: routerNames.reportUnusualPig,
        component: ReportUnusualPig,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportTempPig,
        component: ReportTemPig,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportFoodConsume,
        component: ReportFoodConsume,
        layout: PublicLayout,
        isLoginRequired: true,
    },

    {
        exact: true,
        path: routerNames.penManagement,
        component: Pen,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.penEmpty,
        component: PenEmpty,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.event,
        component: Event,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.pig,
        component: Pig,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.pigIdentity,
        component: PigIdentity,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.blog,
        component: HomePage,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.foodSupplier,
        component: FoodSupplier,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.food,
        component: Food,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.foodOrder,
        component: FoodOrder,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.sickPig,
        component: SickPigs,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.sickPigDiagnosis,
        component: SickPigsDiagnosis,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.tagPig,
        component: TagPigs,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.foodConsume,
        component: FoodConsume,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportFoodInventory,
        component: ReportFoodInventory,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportFoodOrder,
        component: ReportFoodOrder,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportPharmacyConsume,
        component: ReportPharmaceuticalConsume,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportPharmacyOrder,
        component: ReportPharmaceuticalOrder,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.reportPharmacyInventory,
        component: ReportPharmaceuticalInventory,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.profile,
        component: Profile,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.profile_userList,
        component: UserList,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: '/profile/the-nhiet',
        component: TemperatureStatusList,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: '/profile/hanh-vi',
        component: BehaviourStatus,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: '/profile/ca-the-lon',
        component: PigList,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.profile_farmList,
        component: FarmList,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.profile_allFarmList,
        component: AllFarmList,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.profile_sickPigs,
        component: SickpigsProfile,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.profile_modal,
        component: ModalProfile,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.vaccine_plan,
        component: PharmaceuticalPlan,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.vaccine_supplier,
        component: PharmaceuticalSupplier,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.vaccine_list,
        component: PharmaceuticalType,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.vaccine_order,
        component: PharmaceuticalOrder,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.vaccine_consume,
        component: PharmaceuticalConsume,
        layout: PublicLayout,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: routerNames.policy,
        component: Policy,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.gateway_farms,
        component: GatewayFarm,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: routerNames.gateway_pen,
        component: GatewayPen,
        layout: PublicLayout,
        isLoginRequired: false,
    },
    {
        exact: true,
        path: '*',
        component: NotFound,
        layout: PublicLayout,
        isLoginRequired: false,
    },

];

export default routes;
